import React from "react";
import styled from "styled-components";
import { rem, transitions } from "polished";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  DialogContent,
  DialogContentProps,
  DialogActions,
  DialogActionsProps,
} from "@mui/material";

export interface ModalProps extends DialogProps {
  children: React.ReactNode;
  loading?: boolean;
  LoadingComponent?: React.ReactNode;
  Title?: React.FC<DialogTitleProps>;
  Content?: React.FC<DialogContentProps>;
  Actions?: React.FC<DialogActionsProps>;
  Width?: string;
  Height?: string;
  Percentage?: boolean;
  ref?: any;
}

const StyledDialog = styled(
  React.forwardRef(({ children, ...props }: DialogProps, ref) => (
    <Dialog
      //@ts-ignore
      ref={ref}
      {...props}
      classes={{
        paper: "paper",
        paperWidthSm: "paperWidthSm",
        paperWidthMd: "paperWidthMd",
        paperWidthLg: "paperWidthLg",
      }}
    >
      {children}
    </Dialog>
  ))
)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .MuiTypography-h6 {
    font-weight: 600;
  }

  .MuiDialogContent-root {
    padding: ${(props: any) => (props?.Percentage ? "0px 24px" : "")};
    padding-top: ${(props: any) => (props?.Percentage ? "24px" : "4px")};
  }

  .paper {
    box-shadow: ${(props) => props.theme.shadows[3]};
    overflow-y: hidden;
    ${transitions("all 1s ease")};
    width: 552px;
  }

  .paperWidthSm {
    max-width: ${(props: any) => (props?.Width ? props.Width : rem("280px"))};
    width: ${(props: any) => (props?.Width ? props.Width : "")};
    ${(props: any) => (props?.Height ? `height: ${rem(props?.Height)};` : ``)}
  }

  .paperWidthMd {
    max-width: ${rem("552px")};
    min-height: ${rem("280px")};
  }

  .paperWidthLg {
    max-width: ${rem("652px")};
    min-height: ${rem("520px")};
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-family: Source Sans Pro;
  .MuiPaper-root {
    padding-top: ${rem("24px")} !important;
    padding-bottom: ${rem("0px")} !important;
    font-family: Source Sans Pro;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  .MuiDialogContent-root {
    padding-bottom: ${rem("24px")};
    color: ${(props) => props.theme.colors.S["800"]};
    font-size: ${rem("16px")};
    overflow-y: scroll;
    overflow-x: hidden;
    ${(props: any) => (props?.Width ? `width: ${rem(props?.Width)};` : ``)}
    ${(props: any) => (props?.Height ? `height: ${rem(props?.Height)};` : ``)}
  }
`;

const StyledDialogActions = styled(DialogActions)`
  position: relative;
  height: ${rem("52px")};
  padding: ${rem("8px")};
  margin-bottom: 8px;
`;

const LoadingOverlayContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  z-index: 1000;

  > div {
    height: 100%;
  }
`;

export function Modal({
  children,
  loading,
  LoadingComponent,
  ...props
}: ModalProps) {
  return (
    <StyledDialog {...props}>
      {loading && (
        <LoadingOverlayContainer>{LoadingComponent}</LoadingOverlayContainer>
      )}
      {children}
    </StyledDialog>
  );
}

const Title = ({ children, ...props }: DialogTitleProps) => {
  return <StyledDialogTitle {...props}>{children}</StyledDialogTitle>;
};

interface DialogContentCustom extends DialogContentProps {
  Height?: string;
  Width?:string
}

const Content = ({ children, ...props }: DialogContentCustom) => {
  return <StyledDialogContent {...props}>{children}</StyledDialogContent>;
};

const Actions = ({ children, ...props }: DialogActionsProps) => {
  return <StyledDialogActions {...props}>{children}</StyledDialogActions>;
};

Modal.Title = Title;
Modal.Content = Content;
Modal.Actions = Actions;
