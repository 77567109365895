import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { zIndex, shadows } from "styles/mixins";

const useStyles = makeStyles<Theme, { active: boolean }>(({ palette }) => ({
  toolbarSeparator: {
    minHeight: "48px",
  },
  drawer: {
    width: "56px",
    flexShrink: 0,
    boxShadow: shadows[1],
  },
  drawerPaper: {
    position: "relative",
    top:10,
    width: "56px",
    marginTop:40,
    overflowX: "hidden",
    whiteSpace: "nowrap",
    borderRight: "none",
    backgroundColor: palette.B["900"],
  },
  listItem: {
    padding:16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    "&:a":{
      backgroundColor: "transparent",
  
    },
    "&:hover":{
      backgroundColor: "#1E334A",
      transition: "0.7s"


    },
    "&:active":{
      backgroundColor: "#2C4560",

    },
    "&:focus":{
      backgroundColor: "transparent",
    }
  },
  freshChatListItem: {
    position: "absolute",
    overflow: "visible",
    bottom: "72px",
    width: ({ active }) => (active ? "82px" : "62px"),
    backgroundColor: palette.neutral[400],
    boxShadow: shadows[1],
    height: "72px",
    transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "0px 200px 200px 0px",
    justifyContent: "flex-end",
    paddingRight: "16px",
    zIndex: 99999,
    "&:hover": {
      width: "82px",
      backgroundColor: palette.neutral[400],
    },
  },
  hubspotContainer: {
    zIndex: 9,
    position: "absolute",
    top: "75%",
    paddingLeft: 65,
  },
  hubspotIframe: {
    width: 250,
  },
}));

export default useStyles;
