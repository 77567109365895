import { createContext, useCallback, useContext, useMemo, useState } from 'react';

type ContextProps = {
    showModalMinMaxQuantity: boolean;
    handlerModalMinMaxQuantity: () => void;
};

const defaultValues: ContextProps = {
    showModalMinMaxQuantity: false,
    handlerModalMinMaxQuantity: () => {},
};

type Props = {
    children: React.ReactNode;
};

const ModalMinimunQuantityContext = createContext<ContextProps>(defaultValues);

export const ModalMinimunQuantityProvider = ({ children }: Props) => {
    const [showModalMinMaxQuantity, setShowModalMinMaxQuantity] = useState<boolean>(
        defaultValues.showModalMinMaxQuantity,
    );

    const handlerModalMinMaxQuantity = useCallback(() => {
        setShowModalMinMaxQuantity(!showModalMinMaxQuantity);
    }, [showModalMinMaxQuantity]);

    const value: ContextProps = useMemo(
        () => ({
            showModalMinMaxQuantity,
            handlerModalMinMaxQuantity,
        }),
        [showModalMinMaxQuantity, handlerModalMinMaxQuantity],
    );

    return <ModalMinimunQuantityContext.Provider value={value}>{children}</ModalMinimunQuantityContext.Provider>;
};

export const useModalMinimunQuantityContext = () => useContext(ModalMinimunQuantityContext);
