import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

type ContextProps = {
    insufficientCreditIDs: InsufficientCreditWarning[];
    setInsufficientCreditIDs: Dispatch<SetStateAction<InsufficientCreditWarning[]>>;
    openedInsufficientCreditIDs: number[];
    setOpenedInsufficientCreditIDs: Dispatch<SetStateAction<number[]>>;
    manuallyOpenedCreditTip: number;
    setManuallyOpenedCreditTip: Dispatch<SetStateAction<number>>;
};

const defaultValues: ContextProps = {
    insufficientCreditIDs: [],
    setInsufficientCreditIDs: (obj: any) => {},
    openedInsufficientCreditIDs: [],
    setOpenedInsufficientCreditIDs: (obj: any) => {},
    manuallyOpenedCreditTip: 0,
    setManuallyOpenedCreditTip: (v: number) => {},
};

type Props = {
    children: React.ReactNode;
};

type InsufficientCreditWarning = {
    id: number;
    isWarningOpen: boolean;
};

const InsuficientCreditContext = createContext<ContextProps>(defaultValues);

export const InsuficientCreditProvider = ({ children }: Props) => {
    const [insufficientCreditIDs, setInsufficientCreditIDs] = useState<InsufficientCreditWarning[]>(
        defaultValues.insufficientCreditIDs,
    );
    const [manuallyOpenedCreditTip, setManuallyOpenedCreditTip] = useState<number>(
        defaultValues.manuallyOpenedCreditTip,
    );
    const [openedInsufficientCreditIDs, setOpenedInsufficientCreditIDs] = useState<number[]>(
        defaultValues.openedInsufficientCreditIDs,
    );

    const value: ContextProps = useMemo(
        () => ({
            insufficientCreditIDs,
            setInsufficientCreditIDs,
            manuallyOpenedCreditTip,
            setManuallyOpenedCreditTip,
            openedInsufficientCreditIDs,
            setOpenedInsufficientCreditIDs,
        }),
        [
            insufficientCreditIDs,
            setInsufficientCreditIDs,
            manuallyOpenedCreditTip,
            setManuallyOpenedCreditTip,
            openedInsufficientCreditIDs,
            setOpenedInsufficientCreditIDs,
        ],
    );

    return <InsuficientCreditContext.Provider value={value}>{children}</InsuficientCreditContext.Provider>;
};

export const useInsuficientCreditContext = () => useContext(InsuficientCreditContext);
