// modules
import { useState } from 'react';
import Link from 'next/link';
// intern component
import { AdminTableBodyCell } from '.';
// mui v5
import { TableBody, TableRow } from '@mui/material';
import { useUser } from 'utils/hooks';

const AdminTableBody = ({ recordset, tag, showSkeleton, onClickRow, setSelectedClonePos, modal}) => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const { user } = useUser({});
// Ff
const EX5796 = user?.EX5796;
  const handleRowHover = (rowId) => {
    setHoveredRow(rowId);
  };
  return (
    <TableBody>
      {recordset?.data?.length > 0 && recordset?.data?.map((item) => {
        // desestructured
        const { ...body } = item;

        const keyItem = Object.keys(body);

        const route = !!EX5796 && tag === 'clients' ? `/clients/${item.id}/${item.client_type_id}` : `/clients/${item.id}`

        return (
          <TableRow
            key={item.id}
            aria-label={`row_${item.id}`}
            component={tag === 'clients' ? Link : 'p'}
            href={route}
            hover={true}
            onMouseEnter={() => handleRowHover(item.id)}
            onMouseLeave={() => handleRowHover(null)}
            style={{ cursor: "pointer", textDecoration: tag === 'clients' ? 'none' : '' }}
            onClick={(event) => onClickRow && onClickRow(item,event)}
          >
            <AdminTableBodyCell
              items={keyItem}
              rest={body}
              tag={tag}
              visibilityWithHover={hoveredRow}
              showSkeleton={showSkeleton}
              setSelectedClonePos = { setSelectedClonePos }
              modal = { modal }
            />
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default AdminTableBody;
