import styles from './Stepper.module.css';
const ExStepper = ({ steps, currentStep, setCurrentStep, nextStep }: ExStepperProps) => {
    const lastStep = steps?.length - 1;
    const prevStep = (index) => {
        return index < currentStep;
    };

    return (
        <div className={styles['stepper-container']}>
            {steps?.map((step, index) => (
                <>
                    <div
                        key={`${step}-${index}`}
                        className={`${styles['step-item']} ${index === currentStep ? styles['active'] : ''} ${
                            index < currentStep ? styles['completed'] : ''
                        }`}
                        onClick={() => (prevStep(index) ? setCurrentStep(index) : nextStep())}
                    >
                        <div className={styles['step-icon']}>
                            {index < currentStep ? (
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.36664 10.1L12.0166 4.45C12.15 4.31667 12.3083 4.25 12.4916 4.25C12.675 4.25 12.8333 4.31667 12.9666 4.45C13.1 4.58333 13.1666 4.74167 13.1666 4.925C13.1666 5.10833 13.1 5.26667 12.9666 5.4L6.83331 11.5333C6.69998 11.6667 6.54442 11.7333 6.36664 11.7333C6.18886 11.7333 6.03331 11.6667 5.89998 11.5333L3.03331 8.66667C2.89998 8.53333 2.83609 8.375 2.84164 8.19167C2.8472 8.00833 2.91664 7.85 3.04998 7.71667C3.18331 7.58333 3.34164 7.51667 3.52498 7.51667C3.70831 7.51667 3.86664 7.58333 3.99998 7.71667L6.36664 10.1Z"
                                        fill="#0171E6"
                                    />
                                </svg>
                            ) : (
                                <span>{index + 1}</span>
                            )}
                        </div>
                        <div className={styles['step-title']}>
                            <span className={styles['step-title-text']}>{step}</span>
                        </div>
                    </div>
                    {index !== lastStep && (
                        <div style={{ display: 'flex' }}>
                            <svg
                                width="20"
                                height="2"
                                viewBox="0 0 20 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 1H19.5"
                                    stroke={index < currentStep ? '#0171E6' : '#E0E1E9'}
                                    stroke-width="2"
                                />
                            </svg>
                        </div>
                    )}
                </>
            ))}
        </div>
    );
};

export default ExStepper;
