import { apiPharmaInstance, objectToFormData } from "utils/axios";

interface uploadSiafFile {
  file: File;
  point_of_sale_id: number;
}
export const uploadSiafFile = async ({
  file,
  point_of_sale_id
}: uploadSiafFile) => {
  const formData = objectToFormData({ file, point_of_sale_id });

  return await apiPharmaInstance.post(
    "/api/orders/create",
    formData,
    {
      headers: { "content-type": "multipart/form-data" },
    }
  );
};

interface CreateOrderByCartId {
  cart_id: number;
  point_of_sale_id: number;
}
export const createOrderByCartId = async ({
  cart_id,
  point_of_sale_id
}: CreateOrderByCartId) => {
  const formData = objectToFormData({ cart_id, point_of_sale_id });
  return await apiPharmaInstance.post(
    "/api/orders/create",
    formData,
    {
      headers: { "content-type": "multipart/form-data" },
    }
  );
};

interface ContinueToEfficientPurchasePromise {
  file: File;
  point_of_sale_id: number;
  order_id: string;
}
export const continueToEfficientPurchasePromise = async ({file, point_of_sale_id, order_id}: ContinueToEfficientPurchasePromise) => {
  const formData = objectToFormData({ file, point_of_sale_id });

  return await apiPharmaInstance.post(
    "/api/orders/" + order_id + "/continue",
    formData,
    {
      headers: { "content-type": "multipart/form-data" },
    }
  );
};

export const getRecommendedProducts = async (
  pointOfSaleId,
  orderId
) => {
  try {
    return await apiPharmaInstance.get(`api/points-of-sale/${pointOfSaleId}/order/${orderId}/recommended-order-products`);
  } catch (error) {
    console.log(error.message);
  }
};