// modules
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// ar
import esARTextNew from '../locale/es-AR/es-AR.json';
// mx
import mxTextNew from '../locale/mx/mx.json';
// co
import coTextNew from '../locale/co/co.json';
// es
import esESTextNew from '../locale/es-ES/es-ES.json';
// neutro
import esNeutroTextNew from '../locale/es/es.json';
// br
import ptBRTextNew from '../locale/pt-BR/pt-BR.json';
// En
import enUSTextNew from '../locale/en-US/en-US.json';

export const fallbackNS = 'fallback';
export const defaultNS = 'translation';

i18next.use(initReactI18next).init({
    debug: false, // if you need to see the init json write true instead of false
    fallbackLng: 'es',
    defaultNS,
    fallbackNS,
    resources: {
        'es-AR': esARTextNew,
        mx: mxTextNew,
        co: coTextNew,
        'es-ES': esESTextNew,
        es: esNeutroTextNew,
        'pt-BR': ptBRTextNew,
        'en-US': enUSTextNew,
    },
    interpolation: {
        escapeValue: false,
    },
});

export default i18next;
