export function FreshDesk(props) {
  return (
    <svg data-name="Capa 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...props}>
      <path d="M26.89 5.38h-3.94V1.96A1.16 1.16 0 0 0 21.79.8H1.11A1.159 1.159 0 0 0-.04 1.96V17.6a1.159 1.159 0 0 0 1.15 1.16h2.56a.158.158 0 0 1 .16.16v2.55a1.128 1.128 0 0 0 .78 1.09 1.106 1.106 0 0 0 1.23-.32l3.71-3.42a.143.143 0 0 1 .12-.06h2.09v3.42a1.144 1.144 0 0 0 1.15 1.15h6.5c.05 0 .09.03.17.12l3.61 3.31a1.112 1.112 0 0 0 .9.44.986.986 0 0 0 .38-.07 1.129 1.129 0 0 0 .78-1.09v-2.55a.158.158 0 0 1 .16-.16h1.48a1.144 1.144 0 0 0 1.15-1.15V6.53a1.144 1.144 0 0 0-1.15-1.15zM11.76 17.76H9.67a1.101 1.101 0 0 0-.85.38l-3.71 3.43a.168.168 0 0 1-.18.05.16.16 0 0 1-.1-.15v-2.55a1.16 1.16 0 0 0-1.16-1.16H1.11a.156.156 0 0 1-.15-.16V1.96a.156.156 0 0 1 .15-.16h20.68a.158.158 0 0 1 .16.16V17.6a.158.158 0 0 1-.16.16H11.76zm15.28 4.42a.147.147 0 0 1-.15.15h-1.48a1.16 1.16 0 0 0-1.16 1.16v2.55a.161.161 0 0 1-.1.15c-.05.02-.12.02-.23-.11l-3.61-3.31a1.143 1.143 0 0 0-.9-.44h-6.5a.154.154 0 0 1-.15-.15v-3.42h9.03a1.16 1.16 0 0 0 1.16-1.16V6.38h3.94a.147.147 0 0 1 .15.15z" />
      <path d="M17.878 9.12h-2.453a.5.5 0 0 0 0 1h2.453a.5.5 0 0 0 0-1zM13.319 9.62a.5.5 0 0 0-.5-.5H5.027a.5.5 0 1 0 0 1h7.792a.5.5 0 0 0 .5-.5zM5.027 6.375h2.454a.5.5 0 0 0 0-1H5.027a.5.5 0 0 0 0 1zM10.087 6.375h7.791a.5.5 0 0 0 0-1h-7.791a.5.5 0 0 0 0 1zM7.481 12.862H5.027a.5.5 0 1 0 0 1h2.454a.5.5 0 0 0 0-1zM18.378 13.362a.5.5 0 0 0-.5-.5h-7.791a.5.5 0 0 0 0 1h7.791a.5.5 0 0 0 .5-.5z" />
    </svg>
  );
}

export function FullFreshDesk(props) {
  return (
    <svg data-name="Capa 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...props}>
      <path d="M26.89 5.38h-2.95v12.2a2.163 2.163 0 0 1-2.16 2.16H11.76v2.44a1.144 1.144 0 0 0 1.15 1.15h6.5c.05 0 .09.03.17.12l3.61 3.31a1.114 1.114 0 0 0 .9.44.986.986 0 0 0 .38-.07 1.128 1.128 0 0 0 .78-1.09v-2.55a.158.158 0 0 1 .16-.16h1.48a1.144 1.144 0 0 0 1.15-1.15V6.53a1.144 1.144 0 0 0-1.15-1.15zm-3.95 12.2V1.93a1.603 1.603 0 0 0-.02-.23 1.143 1.143 0 0 0-.31-.56 1.156 1.156 0 0 0-.82-.34H1.11A1.159 1.159 0 0 0-.04 1.96V17.6a1.677 1.677 0 0 0 .02.23 1.104 1.104 0 0 0 .31.57 1.117 1.117 0 0 0 .82.36h2.56a.142.142 0 0 1 .14.09c.01.01.01.02.01.04a.036.036 0 0 1 .01.03v2.55a1.062 1.062 0 0 0 .01.18.734.734 0 0 0 .04.16.805.805 0 0 0 .05.14 1.158 1.158 0 0 0 .11.19 1.258 1.258 0 0 0 .56.4 1.317 1.317 0 0 0 .39.06 1.062 1.062 0 0 0 .84-.38l3.71-3.42a.142.142 0 0 1 .12-.06h12.12a1.16 1.16 0 0 0 1.16-1.16zM7.48 13.87H5.03a.501.501 0 0 1-.5-.5.495.495 0 0 1 .5-.5h2.45a.495.495 0 0 1 .5.5.501.501 0 0 1-.5.5zm10.4-7.49h-7.79a.501.501 0 0 1-.5-.5.495.495 0 0 1 .5-.5h7.79a.501.501 0 0 1 .5.5.508.508 0 0 1-.5.5zm.5 3.24a.501.501 0 0 1-.5.5h-2.46a.501.501 0 0 1-.5-.5.508.508 0 0 1 .5-.5h2.46a.508.508 0 0 1 .5.5zM7.98 5.88a.501.501 0 0 1-.5.5H5.03a.501.501 0 0 1-.5-.5.495.495 0 0 1 .5-.5h2.45a.495.495 0 0 1 .5.5zm-2.95 4.24a.495.495 0 0 1-.5-.5.501.501 0 0 1 .5-.5h7.79a.501.501 0 0 1 .5.5.495.495 0 0 1-.5.5zm5.06 2.75h7.79a.501.501 0 0 1 .5.5.508.508 0 0 1-.5.5h-7.79a.501.501 0 0 1-.5-.5.495.495 0 0 1 .5-.5z" />
    </svg>
  );
}
