// react
import { useEffect, useState } from 'react';

// components
import {
    DrawerAlertHeader,
    DrawerAlertClient,
    DrawerAlertCountry,
    DrawerAlertFooter,
    DrawerAlertTitle,
    DrawerAlertTypeAlert,
    DrawerLineSeparate,
    DrawerUbicationAlert,
    DescriptionAlert,
    LinkAlert,
} from '.';
// rsuite
import { Drawer, Form } from 'rsuite';
// utils
import { auxiliaryFunctions } from './utils';
import { useAlerts } from 'context/Alerts/Alerts';

const DrawerAlert = ({ openDrawer, setOpenDrawer, lastId }) => {
    // context
    const { alerts, fetch, setFetch } = useAlerts();

    // state
    const [country, setCountry] = useState([]);
    const [clients, setClients] = useState([]);
    const [alertType, setAlertType] = useState(null);
    const [alertUbication, setAlertUbication] = useState([]);
    const [description, setDescription]: any = useState({});
    const [links, setLinks] = useState({
        address: '',
        description: '',
    });
    const [formChanged, setFormChanged] = useState(false);
    const [ isFormRequired , setIsFormRequired ] = useState([])
    const [isEditForm, setIsEditForm] = useState(false);
    // logic
    const actions = auxiliaryFunctions();

    const updateItem = actions.getTheLastItem(alerts, lastId);

    const formData = actions.sendFormData(lastId, country, clients, alertType, alertUbication, description, links);

    const updateFormData = actions.updateFormData(
        lastId,
        country,
        clients,
        alertType,
        alertUbication,
        description,
        links,
        updateItem,
    );

    useEffect(() => {
      (async () => actions.getTheLastItem(alerts,lastId))()
    }, [fetch])
    
    useEffect(() => {
        if(!openDrawer) {
            setIsFormRequired([])
            setFormChanged(false)
        }
    }, [openDrawer])

    useEffect(() => {
        if(!!alerts && alerts.length > 0){
            setIsEditForm(true);
        } else {
            setIsEditForm(false)
        }
    },[])
    
    
    return (
        <Drawer open={openDrawer}  className="custom-drawer-content">
            <Drawer.Body>
                <DrawerAlertHeader setOpenDrawer={setOpenDrawer} />

                <DrawerAlertTitle title="Destinatario" />

                <Form fluid>
                    <DrawerAlertCountry
                        setCountry={setCountry}
                        defaultValue={updateItem[0]?.countries}
                        setFormChanged={setFormChanged}
                        lastId = { lastId }
                        country={country}
                        setIsFormRequired =  { setIsFormRequired }
                        isFormRequired = { isFormRequired }
                        isEditForm = { isEditForm }
                    />

                    <DrawerAlertClient
                        country={country}
                        setClients={setClients}
                        clients={ clients }
                        initialValue={updateItem[0]?.clients}
                        setFormChanged={setFormChanged}
                        lastId = { lastId }
                        setIsFormRequired =  { setIsFormRequired }
                        isFormRequired = { isFormRequired }
                        disabled={country?.length === 0}
                    />

                    {/* Separate */}
                    <DrawerLineSeparate />

                    <DrawerAlertTypeAlert
                        setAlertType={setAlertType}
                        alertType={alertType}
                        defaultValue={updateItem[0]?.data?.notification_type.toString()}
                        setFormChanged={setFormChanged}
                        setIsFormRequired =  { setIsFormRequired }
                        isFormRequired = { isFormRequired }
                    />

                    {/* Separate */}
                    <DrawerLineSeparate />

                    <DrawerUbicationAlert
                        setAlertUbication={setAlertUbication}
                        alertUbication={alertUbication}
                        defaultValue={updateItem[0]?.data?.notification_location}
                        setFormChanged={setFormChanged}
                        setIsFormRequired =  { setIsFormRequired }
                        isFormRequired = { isFormRequired }
                    />

                    {/* Separate */}
                    <DrawerLineSeparate />

                    <DescriptionAlert
                        setDescription={setDescription}
                        description={description}
                        descriptionDefaultValue={updateItem[0]?.data?.description}
                        titleDefaultValue={updateItem[0]?.data?.title}
                        setFormChanged={setFormChanged}
                        setIsFormRequired =  { setIsFormRequired }
                        isFormRequired = { isFormRequired }
                    />

                    {/* Separate */}
                    <DrawerLineSeparate />

                    <LinkAlert
                        setLinks={setLinks}
                        links={links}
                        defaultValueLinkAddress={updateItem[0]?.data?.link_address}
                        defaultValueLinkText={updateItem[0]?.data?.link_text}
                        setFormChanged={setFormChanged}
                    />

                    <DrawerAlertFooter
                        handlerSubmit={() => actions.handlerSubmit(formData)}
                        handlerUpdate={() => actions.handlerUpdate(updateFormData)}
                        handlerDelete={() => actions.handlerDelete(lastId)}
                        data={alerts}
                        formChanged={formChanged}
                        setOpenDrawer={setOpenDrawer}
                        setFetch={setFetch}
                        isFormRequired = { isFormRequired }
                    />
                </Form>
            </Drawer.Body>
        </Drawer>
    );
};

export default DrawerAlert;
