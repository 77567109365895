import React from 'react';
import { IHintProps } from '../interfaces/Hint.interface';
import style from './HintWrapper.module.css';

type Props = {
    type: IHintProps['type'];
    children: React.ReactNode;
};

const HintWrapper = ({ type, children }: Props) => {
    return <div className={`${style['container']} ${style[`container-${type}`]}`}>{children}</div>;
};

export default HintWrapper;
