import { apiPharmaInstance } from 'utils/axios';
import { ApiCredential, ResponseType } from 'common/types';

export const createApiCredential = async (request: any) => {
    request.password = window.btoa(request.password);
    return await apiPharmaInstance.post<ResponseType<ApiCredential>>('/api/api-credential', request);
};

export const createApiCredentialV2 = async (request: any) => {
    request.password = window.btoa(request.password);

    // Crear una instancia de FormData
    const formData = new FormData();

    // Agregar cada campo a FormData
    formData.append('username', request.username);
    formData.append('password', request.password);
    formData.append('function', request.function || '');
    formData.append('client_identifier', request.client_identifier || '');

    // Si `dispatch_address` es un objeto, lo convertimos en string JSON para que FormData pueda manejarlo
    formData.append(
        'dispatch_address',
        request.dispatch_address?.label?.length > 0 ? JSON.stringify(request.dispatch_address) : '',
    );

    formData.append('payment_method_id', request.payment_method_id || '');
    formData.append('available_branches', request.available_branches || '');
    formData.append('catalog_id', request.catalog_id || '');
    formData.append('point_of_sale_id', request.point_of_sale_id.toString());
    formData.append('drug_manufacturer_id', request.drug_manufacturer_id.toString());
    formData.append('type_id', request.type_id.toString());
    formData.append('confirmed', request.confirmed.toString());
    if (!!request.file) {
        formData.append('file', request?.file);
    }

    // Realizar la petición con FormData
    return await apiPharmaInstance.post<ResponseType<ApiCredential>>('/api/api-credential', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const uploadFormat = async (file: any, apiCredentialId: any) => {
    await apiPharmaInstance.post(`/api/api-credential/${apiCredentialId}/upload-format-unknown`, file);
};

export const updateApiCredential = async (id, request) => {
    if (request?.password) {
        request.password = window.btoa(request.password);
    }

    return await apiPharmaInstance.patch<ResponseType<ApiCredential>>(`/api/api-credential/${id}`, request);
};

export const updateApiCredentialV2 = async (id, request) => {
    if (request?.password) {
        request.password = window.btoa(request.password);
    }

    // Crear una instancia de FormData
    const formData = new FormData();

    // Agregar cada campo a FormData
    formData.append('username', request.username);
    formData.append('password', request.password);
    formData.append('function', request.function || '');
    formData.append('client_identifier', request.client_identifier || '');

    // Si `dispatch_address` es un objeto, lo convertimos en string JSON para que FormData pueda manejarlo
    formData.append(
        'dispatch_address',
        request.dispatch_address?.label?.length > 0 ? JSON.stringify(request.dispatch_address) : '',
    );

    formData.append('payment_method_id', request.payment_method_id || '');
    formData.append('available_branches', request.available_branches || '');
    formData.append('catalog_id', request.catalog_id || '');
    formData.append('point_of_sale_id', request.point_of_sale_id.toString());
    formData.append('drug_manufacturer_id', request.drug_manufacturer_id.toString());
    formData.append('type_id', request.type_id.toString());
    formData.append('confirmed', request.confirmed.toString());
    if (!!request.file) {
        formData.append('file', request?.file);
    }

    // Realizar la petición con FormData
    return await apiPharmaInstance.post<ResponseType<ApiCredential>>(`/api/api-credential/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
/***
 * @deprecated
 *
 *
 * */
export const updateApiCredentialOrders = async (order_id, apiCredentialId, request) => {
    request.password = window.btoa(request.password);

    await apiPharmaInstance.patch<ResponseType<ApiCredential>>(
        `/api/orders/${order_id}/api-credential/${apiCredentialId}`,
        request,
    );
};
export const apiCredentialRetry = async (order_id, apiCredentialId) => {
    await apiPharmaInstance.post(`/api/orders/${order_id}/retry/${apiCredentialId} `);
};
export const deleteApiCredential = async (apiCredentialId) => {
    return await apiPharmaInstance.delete(`/api/api-credential/${apiCredentialId}`);
};

export const deleteAllApiCredential = async (drugManufacturerId, point_of_sale_id) => {
    const config = {
        data: {
            point_of_sale_id: point_of_sale_id,
        },
    };
    return await apiPharmaInstance.delete(`/api/drug-manufacturers/${drugManufacturerId}/delete-credentials`, config);
};

export const getApiCredential = async (apiCredentialId: any): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.get(
        `/api/api-credential?filters=%7B%0A%20%20%22point_of_sale_id%22%3A%20${apiCredentialId}%0A%7D`,
    );
};

export const getApiCredentialInvalid = async (pointOfSaleId: number): Promise<ResponseType<any>> => {
    const EX2640 = process.env.NEXT_PUBLIC_EX2640;

    if (EX2640 === 'true') {
        return await apiPharmaInstance.get(`/api/api-credential/invalid-status/${pointOfSaleId}`);
    } else {
        return Promise.resolve({
            data: {
                data: [],
            },
        });
    }
};

export const saveAddress = async (id: any, addressSelected: any) => {
    let addressId = null;
    if (typeof addressSelected === 'object' && addressSelected?.id) {
        addressId = addressSelected.id;
    } else if (typeof addressSelected === 'number') {
        addressId = addressSelected;
    }

    return await apiPharmaInstance.post(`/api/api-credential/${id}/set-dispatch-address`, {
        dispatch_address_id: addressId,
    });
};

export const savePaymentMethod = async (credentialId: any, paymentMethod: any) => {
    return await apiPharmaInstance.post(`api/api-credential/${credentialId}/set-payment-method`, {
        payment_method_id: paymentMethod?.id,
    });
};

export const getCatalogs = async () => {
    return await apiPharmaInstance.get('/api/catalogs/');
};

export const saveCatalogForDrugManufacturer = async (
    point_of_sale_id: number,
    client_identifier: string,
    drug_manufacturer_id: number,
    catalog_id: number,
) => {
    return await apiPharmaInstance.post('/api/api-credential/with-catalog', {
        point_of_sale_id: point_of_sale_id,
        client_identifier: client_identifier,
        drug_manufacturer_id: drug_manufacturer_id,
        catalog_id: catalog_id,
    });
};

export const updateCatalogForDrugManufacturer = async (
    apiCredentialId: number,
    point_of_sale_id: number,
    client_identifier: string,
    drug_manufacturer_id: number,
    catalog_id: number,
) => {
    return await apiPharmaInstance.patch(`/api/api-credential/with-catalog/${apiCredentialId}`, {
        point_of_sale_id: point_of_sale_id,
        client_identifier: client_identifier,
        drug_manufacturer_id: drug_manufacturer_id,
        catalog_id: catalog_id,
    });
};
