import React from "react";

export function useMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    handleMenu,
    handleClose,
    anchorEl,
    open: Boolean(anchorEl),
  };
}
