import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { shadows } from "styles/mixins";

const arrowGenerator = (color: string) => ({
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: "0 !important",
    right: 0,
    marginTop: "-0.95em",
    "&::before": {
      borderWidth: "0 1em 1em 1em",
      borderColor: `transparent transparent ${color} transparent`,
    },
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: "0 !important",
    right: 0,
    marginBottom: "-0.95em",
    "&::before": {
      borderWidth: "1em 1em 0 1em",
      borderColor: `${color} transparent transparent transparent`,
    },
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    top: "initial !important",
    marginLeft: "-0.95em",
    height: "2em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 1em 1em 0",
      borderColor: `transparent ${color} transparent transparent`,
    },
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    top: "initial !important",
    marginRight: "-0.95em",
    height: "2em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 0 1em 1em",
      borderColor: `transparent transparent transparent ${color}`,
    },
  },
});

const useStyles = () =>
  makeStyles<
    Theme,
    {
      labelColor?: string;
      backgroundColor?: string;
      textAlign?: string;
      maxWidth?: string;
      minWidth?: string;
      margin?: string;
      maxHeight?: string;
      top?: string;
      fontSize?: string;
      textCenter?:string;
    }
  >(() => ({
    root: {
      cursor: "pointer",
      fontFamily:"Source Sans Pro",
      
    },
    ".MuiTooltip-arrow": {
      color: ({ backgroundColor }) => backgroundColor ?? "#0B2033",
    },
    tooltip: {
      "&.MuiTooltip-tooltip": {
        top: ({ top }) => top ?? 0,
        position: "relative",
        fontFamily:"Source Sans Pro",
        borderRadius: "4px",
        color: ({ labelColor }) => labelColor,
        fontSize: ({ fontSize }) => fontSize ?? "14px",
        maxWidth: ({ maxWidth }) => maxWidth ?? "100%",
        minWidth: ({ minWidth }) => minWidth ?? "84px",
        backgroundColor: ({ backgroundColor }) => backgroundColor ?? "#04273F",
        boxShadow: shadows["1"],
        minHeight: "24px",
        maxHeight: ({ maxHeight }) => maxHeight ?? null,
        margin: ({ margin }) => margin ?? "14px",
        display: "flex",
        alignItems: "center",
        wordBreak: "break-word",
        justifyContent: ({ textAlign }) => textAlign,
        textAlign:({ textCenter }) => textCenter,

        "& .MuiTooltip-arrow.css-kudwh-MuiTooltip-arrow": {
          color: ({ backgroundColor }) => backgroundColor ?? "#04273F",
        },
      },
    },
  }));

export default useStyles;
