import { createContext, useContext, useState } from 'react';

type ContextProps = {
    showFooterContext: boolean;
    setShowFooterContext: (v: any) => void;
    footerExpanded: boolean;
    setFooterExpanded: (v: any) => void;
};

const defaultValues: ContextProps = {
    showFooterContext: true,
    setShowFooterContext: (obj: any) => {},
    footerExpanded: false,
    setFooterExpanded: (obj: any) => {},
};

type Props = {
    children: React.ReactNode;
};

const LayoutFooterContext = createContext<ContextProps>(defaultValues);

export const LayoutFooterProvider = ({ children }: Props) => {
    const [showFooterContext, setShowFooterContext] = useState<any>(defaultValues.showFooterContext);
    const [footerExpanded, setFooterExpanded] = useState<any>(defaultValues.footerExpanded);

    const value: ContextProps = {
        showFooterContext,
        setShowFooterContext,
        footerExpanded,
        setFooterExpanded,
    };

    return <LayoutFooterContext.Provider value={value}>{children}</LayoutFooterContext.Provider>;
};

export const useLayoutFooterContext = () => useContext(LayoutFooterContext);
