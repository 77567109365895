import React from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import useStyles from "./Button.styles";
import cn from "classnames";

export interface ButtonProps extends MuiButtonProps {
  children: React.ReactNode;
  colorName?: any;
  className?: string;
  colorIdx?: any;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, colorName = "", variant, colorIdx, ...props }, ref) => {
    const classes = useStyles({
      color: colorName,
      variant,
      colorIdx: colorIdx,
    });
    return (
      <MuiButton
        {...props}
        variant={variant}
        ref={ref}
        className={cn(className)}
        style={{
          fontWeight: 600,
          ...props.style,
        }}
        classes={{
          root: classes.root,
          contained: classes.contained,
          outlined: classes.outlined,
          text: classes.text,
          disabled: classes.disabled,
        }}
      />
    );
  }
);

export default Button;
