import { Theme } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<
  Theme,
  {
    active: boolean;
  }
>({
  root: {
    position: "absolute",
    overflow: "visible",
    bottom: "72px",
    width: ({ active }) => (active ? "82px" : "62px"),
    backgroundColor: "#e3e3e3",
    boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.2)",
    height: "72px",
    transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "0px 200px 200px 0px",
    justifyContent: "flex-end",
    paddingRight: 16,
    zIndex: 99999,
    "&:hover": {
      backgroundColor: "#e3e3e3",
      width: "82px",
    },
  },
});
export default useStyles;
