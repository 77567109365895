import React from "react";
import { ListItem } from "@material-ui/core";
import { SidebarIconProps } from "components";
import { FullFreshDesk, FreshDesk } from "../icons";
import useStyles from "./FreshChat.styles";

function SidebarFreshChatIcon({
  active,
  SvgComponent,
  color,
}: SidebarIconProps) {
  return (
    <SvgComponent
      style={{ opacity: active ? 1 : 0.7, color: "white" }}
      width={28}
      height={28}
    />
  );
}

export function FreshChat() {
  const [fcVisible, setFcVisible] = React.useState(false);
  const classes = useStyles({
    active: fcVisible,
  });
  const toggleWidget = () => {
    // @ts-ignore
    const freshChatWidget = window.fcWidget;

    if (freshChatWidget) {
      const isOpen = freshChatWidget.isOpen();
      freshChatWidget.on("widget:closed", () => {
        setFcVisible(false);
      });

      freshChatWidget.on("widget:opened", () => {
        setFcVisible(true);
      });

      if (isOpen) {
        freshChatWidget.close();
      } else {
        // @ts-ignore
        document.getElementById("fc_frame").style.display = "block";
        freshChatWidget.open();
      }
    }
  };

  return (
    <ListItem className={classes.root} onClick={toggleWidget} button>
      <SidebarFreshChatIcon
        active
        color="black"
        SvgComponent={fcVisible ? FullFreshDesk : FreshDesk}
      />
    </ListItem>
  );
}

export default FreshChat;
