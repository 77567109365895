import Button from 'components/Button/Button'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import useStyles from './CartButton.styles'
import React from 'react'
import { useCart } from 'context/cart-context'
import { useRouter } from 'next/router'

const CartButton = ({ styles = undefined }) => {
    const classes = useStyles()
    const { quantityCart } = useCart()
    const router = useRouter()

    const goToCart = () => {
        router.push(`/${router.query.clientId}/efficient-purchase/cart`)
    }

    const Dot = ({ quantityCart }) => {
        return <span className={classes.dot}>{quantityCart}</span>
    }

    return (
        <>
            <Button
                disableRipple={true}
                className={`button__shopping-cart ${classes.cartButton}`}
                style={styles}
                variant="contained"
                onClick={() => goToCart()}
            >
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'Source Sans Pro',
                        fontSize: 15,
                    }}
                    className="button__shopping-cart"
                >
                    <Dot quantityCart={quantityCart()} />
                    <ShoppingCartIcon style={{ marginRight: 10, fontSize: 30 }} className="button__shopping-cart" />
                    <p style={{ paddingLeft: 10 }} className="button__shopping-cart">
                        Carrito
                    </p>
                </span>
            </Button>
        </>
    )
}

export default CartButton
