import React, { useEffect, useState } from 'react';
import { CssBaseline } from '@material-ui/core';
import { useUser } from 'utils/hooks';
import MainSidebar from 'components/MainSidebar';
import { getRoleById } from 'utils/string';
import Header from 'components/Header';
import useStyles from './Layout.styles';
import routes from './routes';
import useChangeLanguage from 'utils/hooks/use-change-language';
import DrawerAlert from '@commons/components/DrawerAlert/DrawerAlert';
import { useAlerts } from 'context/Alerts/Alerts';
import { auxiliary } from './utils/aux.utils';

export interface LayoutProps {
    children: React.ReactNode;
    disablePointOfSale?: boolean;
}

export function Layout({ children, disablePointOfSale = false }: LayoutProps) {
    const classes = useStyles();
    // context
    const { alerts, openDrawer, setOpenDrawer, handlerFetchAlerts } = useAlerts();
    const { user } = useUser({});

    const [alertId, setAlertId] = useState(null);
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    const EX3523 = process.env.NEXT_PUBLIC_EX3523 === 'true';
    if (EX3523) useChangeLanguage(userLocaleCountryCode);

    const role = getRoleById(user?.role_id);
    const userRoutes = routes[role];

    /**
     * Returns the last element of an array.
     * @function
     * @param {Array} array - The array from which to retrieve the last element.
     * @returns {*} - The last element of the array.
     */
    const lastElement = !!alerts && alerts[alerts.length - 1];

    useEffect(() => {
        (async () => await handlerFetchAlerts())();
    }, []);

    return (
        <div className={classes.root}>
            {user?.EX4990 && !!alerts && (
                <DrawerAlert openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} lastId={alertId} />
            )}
            <CssBaseline />
            <Header disablePointOfSale={disablePointOfSale} />
            <MainSidebar
                routes={userRoutes}
                role={role}
                setOpenDrawer={setOpenDrawer}
                alerts={alerts}
                lastElement={lastElement}
                setAlertId={setAlertId}
            />
            <main className={classes.mainContent}>
                <div className={classes.headerSeparator} />
                {children}
            </main>
        </div>
    );
}

export default Layout;
