import React from "react";

export interface ModalProps {
  visibility: boolean;
  toggle(): void;
}

export function useModal(): ModalProps {
  const [visibility, setVisibility] = React.useState(false);
  const toggle = React.useCallback(() => setVisibility(!visibility), [
    visibility,
  ]);

  return {
    visibility,
    toggle,
  };
}
