export const ComputerDanger = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="60" viewBox="0 0 80 60" fill="none">
        <rect x="30.8334" y="51.6667" width="20" height="6.66667" fill="#D9D9D9" />
        <rect x="15.8334" y="58.3334" width="50" height="1.66667" rx="0.833333" fill="#D9D9D9" />
        <path d="M5.00006 0.833313V51.6666" stroke="#D9D9D9" stroke-width="0.5" />
        <path d="M79.1667 5.83331L5.00003 5.83331" stroke="#D9D9D9" stroke-width="0.5" />
        <rect x="1.83337" y="1" width="77.1667" height="49.6667" rx="5" stroke="#D9D9D9" stroke-width="2" />
        <path d="M5.00006 5.83331H78.3334V12.5H5.00006V5.83331Z" fill="#F8CBCB" />
    </svg>
);

export const ComputerWarning = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="60" viewBox="0 0 80 60" fill="none">
        <rect x="30.8334" y="51.6667" width="20" height="6.66667" fill="#D9D9D9" />
        <rect x="15.8334" y="58.3334" width="50" height="1.66667" rx="0.833333" fill="#D9D9D9" />
        <path d="M5.00006 0.833313V51.6666" stroke="#D9D9D9" stroke-width="0.5" />
        <path d="M79.1667 5.83331L5.00003 5.83331" stroke="#D9D9D9" stroke-width="0.5" />
        <rect x="1.83337" y="1" width="77.1667" height="49.6667" rx="5" stroke="#D9D9D9" stroke-width="2" />
        <path d="M5.00006 5.83331H78.3334V12.5H5.00006V5.83331Z" fill="#FFF9E6" />
    </svg>
);

export const Delete = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M4.66675 14C4.30008 14 3.98619 13.8694 3.72508 13.6083C3.46397 13.3472 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98619 3.93611 2.85841 3.80833C2.73064 3.68056 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73064 2.98611 2.85841 2.85833C2.98619 2.73056 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06397 2.31944 6.19175 2.19167C6.31953 2.06389 6.47786 2 6.66675 2H9.33341C9.5223 2 9.68064 2.06389 9.80841 2.19167C9.93619 2.31944 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.014 2.73056 13.1417 2.85833C13.2695 2.98611 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2695 3.68056 13.1417 3.80833C13.014 3.93611 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5362 13.3472 12.2751 13.6083C12.014 13.8694 11.7001 14 11.3334 14H4.66675ZM11.3334 4H4.66675V12.6667H11.3334V4ZM6.00008 11.3333H7.33341V5.33333H6.00008V11.3333ZM8.66675 11.3333H10.0001V5.33333H8.66675V11.3333Z"
            fill="#E22E2E"
        />
    </svg>
);
