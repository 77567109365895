import React from "react";
import { Pagination } from "common/types";

export const usePagination = (metadata: Pagination | undefined) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalItems = metadata?.total;
  const pageSize = metadata?.per_page;
  const totalPages = metadata?.total_pages;
  const onPageChange = React.useCallback((page) => setCurrentPage(page), [
    currentPage,
  ]);
  const reset = () => setCurrentPage(1);

  return {
    currentPage,
    pageSize,
    totalPages,
    totalItems,
    onPageChange,
    reset,
  };
};
