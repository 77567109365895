import { makeStyles } from "@material-ui/core/styles";
import { rem } from "polished";

const useStyles = makeStyles({
  container: {
    gap: rem("8px"),
    cursor: "pointer",
    padding: `0 ${rem("29px")} 0 0`,
  },
  counterBox: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: `${rem("1px")} solid #D1D1D1`,
    borderRadius: "4px",
    opacity: 1,
    minWidth: rem("42px"),
    maxWidth: rem("42px"),
    height: rem("42px"),
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > div": {
      width: rem("24px"),
      height: rem("24px"),
    },
    "& > div > svg": {
      width: rem("24px"),
      height: rem("24px"),
      color: (props: any) => (props.disabled ? "#D1D1D1" : "#1DCAD3"),
      opacity: 1,
    },
  },
  backgroundWhite: {
    backgroundColor: (props: any) => (props.disabled ? "#EEEEEE" : "#FFFFFF"),
    "& > div > svg": {
      color: (props: any) => (props.buttonGray ? "#0171E6 !important" : ""),
    },
    "&:hover": {
      backgroundColor: (props: any) =>
        props.buttonGray
          ? "#E3E3E3 !important"
          : props.disabled
          ? "transparent"
          : "#EEEEEE",
    },
    "&:active": {
      backgroundColor: "#0171E6 !important",
    },
  },
  customDisabled: {
    "& > div > svg": {
      color: (props: any) => "#D1D1D1 !important",
    },
    "&:hover": {
      backgroundColor: (props: any) => "#EEEEEE !important",
    },
    "&:active": {
      backgroundColor: (props: any) => "#EEEEEE !important",
    },
  },
  backgroundBlue: {
    backgroundColor: (props: any) => (props.disabled ? "#EEEEEE" : "#1DCAD3"),
    "& > div > svg": {
      color: (props: any) =>
        props.buttonGray ? "#0171E6 !important" : "#FFFFFF !important",
    },
    "&:hover": {
      backgroundColor: (props: any) =>
        props.buttonGray
          ? "#E3E3E3 !important"
          : props.disabled
          ? "transparent"
          : "#80E1E4",
    },
    "&:active": {
      backgroundColor: (props: any) =>
        props.buttonGray ? "#E3E3E3 !important" : "#00B0BC !important",
    },
  },
  numberBox: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: `${rem("1px")} solid #D1D1D1`,
    borderRadius: "4px",
    opacity: 1,
    maxWidth: rem("62px"),
    height: rem("42px"),
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& > div": {
      width: "100%",
    },
    "& > div > p": {
      textAlign: "center",
      letterSpacing: rem("0.08px"),
      color: "#333333",
      opacity: 1,
    },
  },
  textField: {
    "& .MuiInputBase-formControl": {
      backgroundColor: "white",
      fontFamily:"Source Sans Pro",
      "&:hover":{
        backgroundColor: "white",

      }
    },
    verticalAlign: "revert",
    height: "100%",
  },
  input: {
    "&.MuiInputBase-input": {
      padding: 0,
      margin: 0,
      textAlign: "center",
      border: "none",
      verticalAlign: "revert",
      height: "100%",
    },
  },
});

export default useStyles;
