import { Button as RSButton, Loader, Progress } from 'rsuite';
import { IButtonProps } from './interfaces/Button.interface';

import style from './Button.module.css';
import { btnAppearance, btnSize, btnStatus } from './utils/classes';
import ButtonText from './components/ButtonText';
import { useRef } from 'react';
import useHideRippleEffect from './hooks/useHideRippleEffect';

const EXButton = ({
    appearance = 'primary',
    children,
    className,
    disabled,
    fullWidth = false,
    loading,
    loadingText,
    onClick,
    status,
    type = 'button',
    withArrow,
    size = 'default',
    IconComponent,
    EndIconComponent,
    noRippleEffect,
    progress = 100,
}: IButtonProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    useHideRippleEffect({
        buttonRef,
        shouldHideRippleEffect: noRippleEffect || appearance === 'link',
    });

    return (
        <RSButton
            ref={buttonRef}
            block={fullWidth}
            className={`
                ${className || ''} 
                ${style['ex-button']} 
                ${btnSize[size]} 
                ${status ? btnStatus[status]?.styles : ''} 
                ${btnAppearance[appearance]} 
                ${loading ? style['ex-button-loading'] : ''}
            `}
            disabled={disabled}
            onClick={onClick}
            type={type}
        >
            {loading && <Loader className={style['ex-button-loader']} />}

            <span className={style['ex-button-text']}>
                <ButtonText loading={{ loading, loadingText }} status={status}>
                    {IconComponent}
                    {children}
                    {EndIconComponent}
                </ButtonText>
                {withArrow && !loading && (
                    <span style={fullWidth ? { position: 'absolute', right: '-32px' } : {}}>
                        <img
                            alt="arrow"
                            className={`${style['arrow']}`}
                            height={24}
                            src={'/assets/images/arrow_forward.svg'}
                            width={24}
                        />
                    </span>
                )}
            </span>

            {loading && (
                <Progress.Line
                    percent={progress}
                    status="active"
                    showInfo={false}
                    strokeColor="#0171E6"
                    strokeWidth={48}
                    className={style['progress-bar']}
                />
            )}
        </RSButton>
    );
};

export default EXButton;
