export const auxiliary = () => {
    /**
     * Auxiliary function to handle descriptions.
     * @param {any} evt - Event to update the description.
     * @param {string} key - Key to identify the description to update.
     * @param {function} setDescription - Function to set the description.
     */
    const handlerDescriptions = (evt, key, setDescription,setFormChanged,setIsFormRequired,
        isFormRequired) => {
        setFormChanged(true)
        setDescription((prevDescription) => ({
            ...prevDescription,
            [key]: evt,
        }));
        if (!isFormRequired.includes(key)) {
            setIsFormRequired((prevState) => [...prevState, key]);
        }
    };

    return {
        handlerDescriptions,
    };
};
