import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBox from "@material-ui/icons/IndeterminateCheckBox";

export type CheckboxProps = MuiCheckboxProps;

const StyledCheckbox = styled(
  React.memo(({ ...props }) => (
    <MuiCheckbox
      {...props}
      classes={{ checked: "checked", disabled: "disabled" }}
      checkedIcon={<CheckBoxIcon fontSize="small" />}
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      indeterminateIcon={<IndeterminateCheckBox fontSize="small" />}
    />
  ))
)`
  color: ${(props) => props.theme.colors.S["800"]};

  &.checked {
    color: ${(props) => props.theme.colors.Mi["400"]};
  }

  &.disabled {
    color: ${(props) => props.theme.colors.S["500"]};
  }

  &.MuiCheckbox-indeterminate {
    color: ${(props) => props.theme.colors.Mi["400"]};
  }

  &.MuiIconButton-root:hover {
    background-color: ${(props) => rgba(props.theme.colors.Mi["400"], 0.1)};
  }
`;

export const Checkbox = React.memo(function Checkbox({
  ...props
}: CheckboxProps) {
  return <StyledCheckbox {...props} />;
});
