import { Timestamp, Identity, User } from "common/types";

export enum ServiceId {
  ByPriority = 1,
  ByPercentage = 2
}

export interface PointOfSale extends Timestamp, Identity<number> {
  name: string;
  users: Array<User>;
  address: string;
  service_id?: ServiceId;
  distribution_id?: number | null;
  priority_id?: number | null;
  tolerance?: number | null ;
  client_id: string;
  erp_id?: number | null;
  d_tolerance?:number | null;
  availableDrugManufacturers?: any;
  reference_name?:string;
  cdo_status_id?:any
}

export default PointOfSale;
