import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, OutlinedInput } from '@mui/material';
import Loading from 'components/Loading';
import NewCounterBox from 'components/NewCounterBox/NewCounterBox';
import { EX5225 } from 'config/flags';
import { usePointOfSale } from 'context';
import { useCart } from 'context/cart-context';
import { useSearchProduct } from 'context/search-product-context';
import { useRouter } from 'next/router';
import { rem } from 'polished';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'styled-components';
import { useDebounce } from 'utils/hooks';
import { isEmptyValue } from 'utils/string';
import useStyles from './SearchProductBar.styles';

enum TypeOperation {
    ADD = 'add',
}

interface SearchProductBarProps {
    activateOverLay?: boolean;
    valueSearchProp?: string;
    setOverlay?: (value: boolean) => void;
    inputStyles?: CSSProperties;
    betaTextStyles?: CSSProperties;
}

const ItemRow = ({ dataRow, valueSearch, setOpenSearch, setOverlay }) => {
    const classes = useStyles({ offSet: 0 });
    const [loading, setLoading] = React.useState<boolean>(false);
    const { addProduct, updateProduct, existsProductInCart, productsList, getProductByBarcode } = useCart();
    const boldString = React.useRef<HTMLSpanElement>(null);
    const { push, query } = useRouter();
    const { pointOfSale } = usePointOfSale();

    const handleUpdateQuantity = (productData: any, quantity: number) => {
        const product = getProductByBarcode(productData._source.ean[0]);
        if (product && +quantity !== +product.quantity) {
            updateProduct(pointOfSale?.id, product, quantity);
        }
    };

    const handleAddProduct = (productData: any) => {
        addProduct(pointOfSale?.id, productData);
    };

    // FUNCION ENCARGADA DE DECIDIR OPERACION A REALIZAR
    const handleOperation = useCallback(
        (typeOperation: string, item?: any, quantity?: number) => {
            switch (typeOperation) {
                case TypeOperation.ADD:
                    handleAddProduct(item);
                    break;
                default:
                    handleUpdateQuantity(item, quantity);
                    break;
            }
        },
        [productsList],
    );

    const boldStringResult = (productData) => {
        const text = `${isEmptyValue(productData?._source?.ean[0]) ? '' : productData?._source?.ean[0]} ${
            isEmptyValue(productData?._source?.description) ? '' : productData?._source?.description
        } ${isEmptyValue(productData?._source?.laboratory) ? '' : productData?._source?.laboratory}`;
        const resultText = text
            .toLowerCase()
            .replace(valueSearch.toLowerCase(), `<b style="font-size: 17px">${valueSearch.toLowerCase()}</b>`);
        if (boldString.current) {
            boldString.current.innerHTML = resultText;
        }
    };

    React.useEffect(() => {
        boldStringResult(dataRow);
    }, [boldString]);

    return (
        <Grid container className={classes.containerRow}>
            <Grid item>
                <SearchIcon htmlColor="#545454" style={{ marginRight: 15, marginBottom: -7, fontSize: '1.5rem' }} />
            </Grid>
            <Grid item>
                <span ref={boldString} />
            </Grid>
            <Grid item>
                <NewCounterBox
                    item={dataRow}
                    isInCart={existsProductInCart(dataRow._source?.ean[0])}
                    quantity={getProductByBarcode(dataRow._source?.ean[0])?.quantity}
                    handleOperation={handleOperation}
                    disabled={false}
                    heightButton={rem('36px')}
                    widthButton={rem('125px')}
                    styleBoxLeft={{
                        height: rem('36px'),
                        maxWidth: rem('35px'),
                        minWidth: rem('35px'),
                    }}
                    styleBoxRight={{
                        height: rem('36px'),
                        maxWidth: rem('35px'),
                        minWidth: rem('35px'),
                    }}
                    styleContainer={{
                        width: rem('122px'),
                        padding: 0,
                    }}
                    styleBoxInput={{
                        height: rem('36px'),
                        minWidth: rem('39px'),
                        maxWidth: rem('39px'),
                    }}
                    loading={loading}
                    setLoading={setLoading}
                />
            </Grid>
        </Grid>
    );
};

const ShowLastSearched = ({
    lastSearchItem,
    setValueSearch,
    setOpenSearch,
    redirectToSearchResult,
    setAvoidOpenSearchBox,
    setOverlay,
}) => {
    const classes = useStyles({ offSet: 0 });

    const refillSearchBar = () => {
        setOverlay(false);
        setOpenSearch(false);
        setAvoidOpenSearchBox(true);
        setValueSearch(lastSearchItem);
        redirectToSearchResult(lastSearchItem);
    };

    return (
        <Grid container className={classes.containerRow} onClick={refillSearchBar}>
            <Grid item>
                <AccessTimeIcon htmlColor="#545454" style={{ marginRight: 15, marginBottom: -7, fontSize: '1.5rem' }} />
            </Grid>
            <Grid item>
                <span>{lastSearchItem}</span>
            </Grid>
        </Grid>
    );
};

const ShowMoreResults = ({ dataLength, goToSearchResults }) => {
    const classes = useStyles({ offSet: 0 });
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const { t } = useTranslation();
    return (
        <Grid container xs={12} className={classes.lastRow} onClick={goToSearchResults}>
            <Grid item style={{ height: 36 }}>
                <p className={[classes.showMoreResults, 'link__show-results'].join(' ')}>
                    {EX3526
                        ? t('shoppingCart.shoppingCart_Ten', { value: dataLength })
                        : `Mostrar ${dataLength} resultados`}
                </p>
            </Grid>
        </Grid>
    );
};

const NoResultsFound = () => {
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const { t } = useTranslation();
    const classes = useStyles({ offSet: 0 });
    return (
        <Grid container xs={12} className={classes.noResultsFound}>
            <Grid item>
                <p>{EX5225 ? t('shoppingCart.labelNoResults') : 'No hay resultados que coincidan con tu búsqueda'}</p>
            </Grid>
        </Grid>
    );
};

const SearchProductBar = ({
    activateOverLay = false,
    valueSearchProp = '',
    setOverlay,
    inputStyles,
    betaTextStyles,
}: SearchProductBarProps) => {
    const [valueSearch, setValueSearch] = React.useState(valueSearchProp);
    const [openSearch, setOpenSearch] = React.useState(false);
    const [showLastSearched, setShowLastSearch] = React.useState(false);
    const [offSet, setOffSet] = React.useState(null);
    const inputRef = React.useRef(null);
    const boxEvent = React.useRef(null);
    const { push, query, pathname } = useRouter();
    const {
        setCurrentSearch,
        lastSearch,
        setLastSearch,
        setFetchProducts,
        isLoading,
        listedProducts,
        cleanSearchData,
        searchDataLength,
        pagination,
        searchData,
        setResults,
        setIsUpdateResult,
    } = useSearchProduct();
    const delay = 1000;
    const classes = useStyles({ offSet });
    const debouncedValue = useDebounce(valueSearch, delay);
    const [avoidOpenSearchBox, setAvoidOpenSearchBox] = React.useState(false);
    const EX3411 = process.env.NEXT_PUBLIC_EX3411 === 'true';
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const { t } = useTranslation();

    const openSearchEffect = () => {
        if (EX3411) {
            if (valueSearch.trim() !== '' && !avoidOpenSearchBox) {
                setOpenSearch(true);
                setShowLastSearch(false);
                return;
            }
            setAvoidOpenSearchBox(false);
            setOpenSearch(false);
            setShowLastSearch(false);
        } else {
            if (valueSearch.trim() !== '') {
                setOpenSearch(true);
                setShowLastSearch(false);
                return;
            }
            setOpenSearch(false);
            setShowLastSearch(false);
        }
    };

    const handleClickOutside = (event) => {
        const isClickOutside = !boxEvent?.current?.contains(event.target);
        if (isClickOutside && window.getSelection().containsNode(event.target, true)) {
            setOpenSearch(false);
            activateOverLay && setOverlay(false);
            removeChangeColorBorder();
        }
    };

    const redirectToSearchResult = (value = '') => {
        push({
            pathname: `/${query.clientId}/efficient-purchase/search-results`,
            query: { query: value !== '' ? value : valueSearch },
        });
    };

    const goToSearchResult = (e: any = null) => {
        if (e !== null) e.stopPropagation();

        setOpenSearch(false);
        setOverlay(false);
        //Actualizo estado para que la vista de resultados actualce current page y cantidad de items por pagina
        setIsUpdateResult(true);
        //Recien al clickear en mostrar mas cargamos el estado que necesita la vista de resultados
        setResults(searchData);
        if (EX3411) {
            setFetchProducts(listedProducts);
        } else {
            setFetchProducts([...listedProducts]);
        }

        if (valueSearch.trim() !== '') {
            redirectToSearchResult();
        }
    };

    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            if (EX3411) setAvoidOpenSearchBox(true);
            goToSearchResult();
        }
    };

    const changeColorBorder = () => {
        if (inputRef?.current) {
            inputRef.current.children[2].style.borderColor = '#017C7A';
        }
    };

    const removeChangeColorBorder = () => {
        if (inputRef?.current) {
            inputRef.current.children[2].style.borderColor = '';
        }
    };

    const updateLastSearch = (debouncedValue) => {
        if (lastSearch?.length === 0 || lastSearch?.indexOf(debouncedValue) === -1) {
            lastSearch?.unshift(debouncedValue);
            const slicedLastSearch = lastSearch.slice(0, 6);
            setLastSearch(slicedLastSearch);
            localStorage.setItem('lastSearch', JSON.stringify(slicedLastSearch));
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        if (EX3411) setAvoidOpenSearchBox(false);
    }, []);

    React.useEffect(() => {
        if (inputRef?.current) {
            const newOffSet = {
                offSetWidth: inputRef.current?.offsetWidth,
                offSetTop: inputRef.current?.offsetTop,
                offSetLeft: inputRef.current?.offsetLeft,
            };
            setOffSet({ ...offSet, ...newOffSet });
        }
    }, [inputRef?.current]);

    React.useEffect(() => {
        if (debouncedValue.trim() !== '' && debouncedValue.length >= 3) {
            openSearchEffect();
            setCurrentSearch(debouncedValue);
            updateLastSearch(debouncedValue);
            return;
        }
    }, [debouncedValue, valueSearch]);

    const renderG4tagHtml = () => {
        let value = '';
        if (pathname.split('/')[3] === 'search-results') {
            value = 'input__search-2';
        } else if (pathname.split('/')[3] === 'cart') {
            value = 'input__search-3';
        } else {
            value = 'input__search-1';
        }
        return value;
    };

    // add class G4tag
    const inputSearchProductBar = document.getElementById(renderG4tagHtml());
    if (!!inputSearchProductBar && inputSearchProductBar.classList)
        inputSearchProductBar.classList.add(renderG4tagHtml());

    const placeHolderText = EX3526
        ? t('shoppingCart.shoppingCart_Nine')
        : 'Buscar por nombre, droga, código o laboratorio';
    return (
        <>
            <OutlinedInput
                id={renderG4tagHtml()}
                type="text"
                className={classes.searchBar}
                style={inputStyles}
                placeholder={placeHolderText.toString()}
                value={valueSearch}
                endAdornment={
                    <IconButton onClick={goToSearchResult} color="inherit" style={{ paddingLeft: 12 }}>
                        <SearchIcon htmlColor="#5AB1BC" />
                    </IconButton>
                }
                onClick={(e) => {
                    activateOverLay && setOverlay(true);
                    changeColorBorder();
                    if (valueSearch === '' && lastSearch?.length > 0) {
                        setOpenSearch(true);
                        setShowLastSearch(true);
                        return;
                    }
                    openSearchEffect();
                }}
                onMouseOver={(e) => changeColorBorder()}
                onMouseLeave={(e) => !openSearch && removeChangeColorBorder()}
                onKeyDown={handleEnter}
                onChange={(e) => setValueSearch(e.target.value)}
                ref={inputRef}
            />

            {openSearch && (
                <div className={classes.searchResult} ref={boxEvent}>
                    {isLoading ? (
                        <>
                            <Loading text="" />
                        </>
                    ) : (
                        <>
                            {showLastSearched &&
                                lastSearch?.map((lastSearchItem) => (
                                    <ShowLastSearched
                                        lastSearchItem={lastSearchItem}
                                        setOpenSearch={setOpenSearch}
                                        setOverlay={setOverlay}
                                        setValueSearch={setValueSearch}
                                        redirectToSearchResult={redirectToSearchResult}
                                        setAvoidOpenSearchBox={setAvoidOpenSearchBox}
                                    />
                                ))}
                            {searchDataLength() > 0 &&
                                !showLastSearched &&
                                listedProducts?.slice(0, 5)?.map((value, key) => {
                                    return (
                                        <ItemRow
                                            valueSearch={valueSearch}
                                            setOpenSearch={setOpenSearch}
                                            setOverlay={setOverlay}
                                            dataRow={value}
                                        />
                                    );
                                })}
                            {searchDataLength() > 5 && !showLastSearched && (
                                <ShowMoreResults dataLength={pagination.total} goToSearchResults={goToSearchResult} />
                            )}
                            {searchDataLength() === 0 && !showLastSearched && <NoResultsFound />}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default SearchProductBar;
