import { useState } from 'react';
import { Form, Input } from 'rsuite';
import style from './LinkAlert.module.css';
import { auxiliary } from './utils';

const LinkAlert = ({
    setLinks,
    links,
    defaultValueLinkText,
    defaultValueLinkAddress,
    setFormChanged,
}) => {
    // aux
    const actions = auxiliary();

    return (
        <Form.Group>
            <span className={style['form-data']}>Enlace</span>
            <Form.ControlLabel style={{ marginTop: 16 }}>
                <span className={style['placeholder']}>Dirección del enlace </span>
                <span className={style['optional']}>(opcional)</span>
            </Form.ControlLabel>
            <Input
                className={style['text-input']}
                //value={links?.address}
                onChange={(e) => actions.handlerLinks(e, 'address', setLinks, setFormChanged)}
                placeholder="http://"
                style={{ marginBottom: 32 }}
                defaultValue={defaultValueLinkAddress}
                name="link_address"
            />

            <Form.ControlLabel style={{ marginTop: 16 }}>
                <span className={style['placeholder']}>Texto del enlace</span>
                <span className={style['required']}>*</span>
            </Form.ControlLabel>
            <Input
                disabled={links?.address?.length === 0}
                onChange={(e) => actions.handlerLinks(e, 'description', setLinks, setFormChanged)}
                // value={links?.description}
                className={links?.description?.length === 40 ? style['text-error-input'] : style['text-input']}
                placeholder="Ingresá en neutro, una descripción corta y clara."
                defaultValue={defaultValueLinkText}
                name="link_text"
                maxLength={40}
            />
            <p className={style['counter-characters']} style={{ marginBottom: 82 }}>
                {links?.description?.length}/40
            </p>
        </Form.Group>
    );
};

export default LinkAlert;
