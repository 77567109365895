export const auxiliaryFunctions = () => {
    /**
     * Function that handles checkbox selection.
     *
     * @param {any} evt - The event triggering the function.
     * @param {function} setSelectedValue - The function to set the selected value.
     * @param {function} setFormChanged - Function to set the form changed state.
     */
    const handlerSelectCheckbox = (evt, setSelectedValue, setFormChanged, setIsFormRequired, isFormRequired,key) => {
        setSelectedValue(evt);
        setFormChanged(true);
        if (!isFormRequired.includes(key)) {
            setIsFormRequired((prevState) => [...prevState, key]);
        }
    };

    return {
        handlerSelectCheckbox,
    };
};
