import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import * as SS from "styled-system";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";

export interface ButtonProps extends MuiButtonProps, SS.SpaceProps {
  children: React.ReactNode;
  colorName: any;
  coloridx?: number;
}

const StyledButton = styled(
  React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ colorName, ...props }, ref) => (
      <MuiButton
        {...props}
        ref={ref}
        classes={{
          contained: "contained",
          outlined: "outlined",
          text: "text",
          disabled: "disabled",
        }}
      />
    )
  )
)`
  box-shadow: none;
  font-weight: 600;

  letter-spacing: 0;
  font-family:Source Sans Pro;
  white-space: nowrap;
  ${SS.space}

  &.contained {
    color: white;
    font-weight: 600;
    padding:8px 16px;
    font-family:Source Sans Pro;
    border-radius: 4px;
    height:40px;
    font-size:14px;
    box-shadow: none;
    background-color: ${(props) => props.colorName[400]};

    &:hover {
      background-color: ${(props) => props.colorName[300]};
      box-shadow: none;
    }

    &:active {
      background-color: ${(props) => props.colorName[500]};
      box-shadow: none;
    }
  }

  &.text {
    color: ${(props) => props.colorName[700] ?? props.colorName[600]};
    background-color: white;
    font-weight: 600;
    font-family:Source Sans Pro;

    &:hover {
      background-color: "${(props) => props.colorName[300]}";
    }

    &:active {
      background-color: ${(props) => props.colorName[400]};
    }
  }

  &.outlined {
    color: ${(props) => props.colorName[props?.coloridx || 400]};
    background-color: white;
    height: 42px;
    padding:8px 16px;
    border-radius:4px;
    font-family:Source Sans Pro;
    border: ${rem("1px")} solid
      ${(props) => props.colorName[props?.coloridx || 400]};

    &:hover {
      background-color:#EEEEEE;
      border: ${rem("1px")} solid
      ${(props) => props.colorName[props?.coloridx || 400]};
    }

    &:active {
      background-color: ${(props) => props.colorName[200]};
    }
  }

  &.disabled {
    color: ${(props) => props.theme.colors.S["400"]};
    background-color: ${(props) =>
      props.variant === "contained" ? props.theme.colors.S["300"] : "white"};
    border-color: ${(props) => props.theme.colors.S["400"]};
  }
`;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => (
    <StyledButton ref={ref} {...props}>
      {children}
    </StyledButton>
  )
);
