export enum Role {
  Buyer = 2,
  Manager = 6,
  Admin = 7,
  Client = 20,
  Owner = 21,
  VendorAdmin = 31,
  VendorManager =32
}

export default Role;
