import {
  createCartItem,
  deleteCartItemById,
  emptyCartByPointOfSaleId,
  updateCartItem,
  getCartInProgress
} from "api/cart";
import React from "react";
import { existsProductInCartById, getProductCartById } from "utils/cart";
import { isEmptyValue } from "utils/string";
export interface CartProviderProps {
  children: React.ReactNode;
  productsList: any[];
  addProduct: (pointOfSaleId, product) => void;
  emptyCart: (pointOfSale) => void;
  getProductByBarcode: (code: number) => any;
  existsProductInCart: (code: number) => boolean;
  updateProduct: (pointOfSale: any, product: any, quantity: number) => void;
  loadProductsInCart: (products: any[]) => void;
  deleteProductById: (pointOfSaleId: number, barcode: number) => void;
  removeProductInState: (pointOfSaleId: number, id: number) => void;
  quantityCart: () => number;
  setUndoTimeout(option: any);
  undoItem(code: number, idx: number): void;
  undoTimeout: boolean;
  ongoingComparisonSnackBar: boolean;
  setOngoingComparisonSnackBar: (value: boolean) => void;
  emptyCartSnackbar: boolean;
  setEmptyCartSnackbar: (value: boolean) => void;
  getShowMessageAgain: () => boolean;
  setShowMessageAgain: (value: boolean) => void;
  getCart: (pointOfSaleId: number) => Promise<void>;
  visibility: boolean;
  toggle: () => void;
  isExpanded: boolean;
  expand: () => void;
  openModalDelete: boolean;
  setOpenModalDelete: (v) => void;
  animation: boolean;
}

const CartContext = React.createContext<Omit<CartProviderProps, "children">>(null);

export function CartProvider({ children, ...props }) {
  const [productsList, setProductsList] = React.useState([]);
  const [undoTimeout, setUndoTimeout] = React.useState(false);
  const [emptyCartSnackbar, setEmptyCartSnackbar] = React.useState(false);
  const [ongoingComparisonSnackBar, setOngoingComparisonSnackBar] = React.useState(false);
  const [visibility, setVisibility] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [animation, setAnimation] = React.useState(false);

  const toggle = React.useCallback(() => {
    setAnimation(true);
    setTimeout(() => {
      setVisibility(!visibility)
      setIsExpanded(false)
      setAnimation(false);
    }, 200)
  }, [
    visibility,
  ]);

  const expand = React.useCallback(() => setIsExpanded(!isExpanded), [
    isExpanded,
  ]);

  React.useEffect(() => {
    if (undoTimeout) {
      setUndoTimeout(false);
    }
  }, [undoTimeout]);

  // CARGAR CARRITO
  const loadProductsInCart = async (products: any[]) => {
    setProductsList(products)
  };

  // AGREGAR UN PRODUCTO
  const addProduct = async (pointOfSaleId: number, product: any) => {
      let newProduct = {
        "barcode": isEmptyValue(product._source?.ean[0]) ? " " : String(product._source.ean[0]),
        "description": `${isEmptyValue(product._source?.description) ? "" : product._source.description} ${isEmptyValue(product._source?.laboratory) ? " " : product._source.laboratory}`,
        "quantity": 1
      };
      // CREATE PRODUCT
      const productInCart = await createCartItem(pointOfSaleId, newProduct);
      const { cart_id } = productInCart.data.data;
      // IT'S ANOTHER CART
      if (!!productsList.length && productsList[0].cart_id !== cart_id) {
        // SHOW SNACKBAR AND RESET STATE WITH ONE PRODUCT
        setOngoingComparisonSnackBar(true);
        loadProductsInCart([productInCart.data.data]);
      } else {
        return await getCart(pointOfSaleId);
      };

  };

  // GET CART IN PROGRESS
  const getCart = async (pointOfSaleId: number) => {
    const currentCart: any = await getCartInProgress(pointOfSaleId);
    if (currentCart?.items && !!currentCart.items.length) {
      // LOAD CART WITH ALL PRODUCTS
      loadProductsInCart(currentCart.items);
    } else {
      loadProductsInCart([]);
    };
  };

  // VACIAR CARRITO
  const emptyCart = async (pointOfSaleId: number) => {
    await emptyCartByPointOfSaleId(pointOfSaleId);
      setEmptyCartSnackbar(true);
      loadProductsInCart([]);
   
  };

  // ELIMINAR UN PRODUCTO DE LA DB POR ID
  const deleteProductById = async (pointOfSaleId: number, id: number) => {
    const response = await deleteCartItemById(pointOfSaleId, id);
   
      const { data } = response.data;
      if (data?.error && data.error === "CART_IN_PROGRESS_NOT_FOUND") {
        // SHOW SNACKBAR AND RESET STATE
        setOngoingComparisonSnackBar(true);
        loadProductsInCart([]);
      };
    
  };

  // ELIMINAR UN PRODUCTO DEL ESTADO POR ID
  const removeProductInState = async (pointOfSaleId: number, id: number) => {
    const newList = productsList.filter(item => item.id !== id);
    setProductsList([...newList])
  };

  // OBTENER UN PRODUCTO POR ID
  const getProductByBarcode = (barcode: number) => {
    const product = getProductCartById(productsList, barcode);
    return product;
  };

  // VALIDAR SI EXISTE UN PRODUCTO POR ID
  const existsProductInCart = (barcode: number) => {
    const existProductInCart = existsProductInCartById(productsList, barcode)
    return existProductInCart;
  };

  // ACTUALIZAR CANTIDAD DE UN PRODUCTO POR ID
  const updateProduct = async (pointOfSaleId: number, product: any, quantity: number) => {

      let newProduct = {
        "barcode": product.barcode,
        "description": product.description,
        "quantity": quantity
      };

      // UPDATE PRODUCT QUANTITY
      const updateProductInCart: any = await updateCartItem(pointOfSaleId, product.id, newProduct);
      const { data } = updateProductInCart.data;

      if (data?.error && data.error === "CART_IN_PROGRESS_NOT_FOUND") {
        // SHOW SNACKBAR BECAUSE ERROR AND RESTART STATE
        setOngoingComparisonSnackBar(true);
        loadProductsInCart([]);
      } else {
        // LOAD STATE WITH PRODUCTS 
        await getCart(pointOfSaleId);
      };
  };

  // EVITAR ELIMINAR UN PRODUCTO
  const undoItem = async (item: any, idx: number) => {
    setProductsList(productsList);
  };

  // CANTIDAD DE PRODUCTOS EN CARRITO
  const quantityCart = (): number => {
    return productsList.length;
  };

  // GET LOCALSTORAGE PARA MOSTRAR MODAL AL INICIAR COMP.
  const getShowMessageAgain = () => {
    const value = localStorage.getItem("show_message_again");
    if (value !== null) return JSON.parse(value);
    return false;
  };

  // SET LOCALSTORAGE PARA MOSTRAR MODAL AL INICIAR COMP.
  const setShowMessageAgain = (value: boolean) => {
    return Promise.resolve().then(function () {
      localStorage.setItem("show_message_again", JSON.stringify(value));
      getShowMessageAgain();
    });
  };

  return (
    <CartContext.Provider
      value={{
        productsList,
        emptyCart,
        getProductByBarcode,
        existsProductInCart,
        addProduct,
        updateProduct,
        loadProductsInCart,
        deleteProductById,
        quantityCart,
        undoItem,
        setUndoTimeout,
        undoTimeout,
        removeProductInState,
        ongoingComparisonSnackBar,
        setOngoingComparisonSnackBar,
        emptyCartSnackbar,
        setEmptyCartSnackbar,
        getShowMessageAgain,
        setShowMessageAgain,
        getCart,
        visibility,
        toggle,
        isExpanded,
        expand,
        openModalDelete,
        setOpenModalDelete,
        animation,
        ...props
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = React.useContext(CartContext);

  if (context === undefined) {
    throw new Error("useCartContext must be used within a ConfirmationProvider");
  }

  return context;
}
