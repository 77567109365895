import { DrugManufacturersId } from 'common/types';

// Función para extraer el texto dentro de comillas dobles
const extractTextInQuotes = (text) => {
    const match = text?.match(/"([^"]*)"\s*([^"]*)$/);
    return match ? `${match[1]} ${match[2]}`.trim() : text;
};

export default function stringAvatar(
    name: string,
    serviceId: boolean,
    drug_manufacturer_id: number,
    isOfflineDrugName = false, // sent as true when the name is from an offline drugmanufacturer
) {
    const extractedName = isOfflineDrugName ? name : extractTextInQuotes(name); // Extraer el texto dentro de comillas
    switch (serviceId) {
        case true: {
            let percentage = null;
            const array = extractedName?.split(' ');
            const withoutPercentage = array?.filter((element) => !element.includes('%'));

            for (const element of array) {
                if (element.includes('%')) {
                    percentage = element;
                }
            }
            let result = handleAvatarName(withoutPercentage, extractedName, drug_manufacturer_id);
            return `${result} ${percentage ? `${percentage}` : ''}`;
        }
        case false:
            let array = extractedName?.split(' ');
            let result = handleAvatarName(array, extractedName, drug_manufacturer_id);
            return result;

        case undefined:
            return null;
    }
}

const handleAvatarName = (array, name, drug_manufacturer_id) => {
    if (array?.length === 1 && drug_manufacturer_id === DrugManufacturersId.Kellerhoff) {
        return `KE`;
    } else if (array?.length >= 3) {
        return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}${name.split(' ')[2][0]}`;
    } else if (array?.length > 2) {
        return `${name.split(' ')[0][0]}${name.split(' ')[2][0]}`;
    } else if (array?.length > 1) {
        return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    } else if (array?.length === 1 && drug_manufacturer_id === DrugManufacturersId.Asoprofarma) {
        return `ASP`;
    } else {
        return `${name?.split(' ')[0][0]}`;
    }
};
