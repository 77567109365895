import { RefObject, useEffect, useState } from "react";

type Props = {
    buttonRef: RefObject<HTMLButtonElement | undefined>;
    shouldHideRippleEffect: boolean;
}

const useHideRippleEffect = ({buttonRef, shouldHideRippleEffect} : Props) => {
    const [hideRippleEffect] = useState(shouldHideRippleEffect);

    useEffect(() => {
        if (hideRippleEffect && buttonRef.current) {
            const buttonChildrenNodes = buttonRef.current.childNodes;
            buttonChildrenNodes.forEach(node => {
                if (node instanceof HTMLElement && node.classList.contains('rs-ripple-pond')) {
                    node.remove();
                }
            });
        }
    }, [buttonRef?.current, hideRippleEffect])
}

export default useHideRippleEffect;