import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    updateParentProduct: any;
    setUpdateParentProduct: (v: any) => void;
};

const defaultValues: ContextProps = {
    updateParentProduct: [],
    setUpdateParentProduct: (v: any) => {},
};

type Props = {
    children: React.ReactNode;
};

const UpdateParentProductContext = createContext<ContextProps>(defaultValues);

export const UpdateParentProductProvider = ({ children }: Props) => {
    const [updateParentProduct, setUpdateParentProduct] = useState([]);

    const value: ContextProps = useMemo(
        () => ({
            updateParentProduct,
            setUpdateParentProduct,
        }),
        [updateParentProduct, setUpdateParentProduct],
    );

    return <UpdateParentProductContext.Provider value={value}>{children}</UpdateParentProductContext.Provider>;
};

export const useUpdateParentProductContext = () => useContext(UpdateParentProductContext);
