import { useState } from "react";

// mui v5
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Skeleton,
} from "@mui/material";

// style
import style from "./AdminTableHead.module.css";
// utils
import { capitalizeFirstLetter } from "utils/string";

const AdminTableHead = ({ sorts, columns, showSkeleton, sortData }) => {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSort = (columnName) => {
    if (orderBy === columnName) {
      setOrderBy(columnName);
      setOrder(order === "asc" ? "desc" : "asc");
      sortData(columnName, order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(columnName);
      setOrder("asc");
      sortData(columnName, "asc");
    }
  };

  return (
    <TableHead
      className={
        showSkeleton
          ? style["container-loading-table-head"]
          : style["container-table-head"]
      }
    >
      <TableRow>
        {columns.map(({ name, align, sorteable, key, width }) => {
          const tableHeadInformation = capitalizeFirstLetter(name);
          return (
            <>
              <TableCell
                align={align}
                className={style["table-cell-container"]}
                style={{width:width}}
              >
                {showSkeleton ? (
                  <span>
                    <Skeleton variant="text" width={100} height={20} />
                  </span>
                ) : (
                  <TableSortLabel
                    active={orderBy === key && sorteable}
                    //@ts-ignore
                    direction={orderBy === key ? order : "asc"}
                    onClick={() => {
                      handleSort(key);
                    }}
                  >
                    <span style={{ fontFamily: "Source Sans Pro " }}>
                      {tableHeadInformation}
                    </span>
                  </TableSortLabel>
                )}
              </TableCell>
            </>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default AdminTableHead;
