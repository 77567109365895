import React from "react";
import { User } from "common/types";
import Menu, { MenuProps } from "../Menu";

interface UserPanelAction {
  className:string,
  label: string;
  onClick(): void;
  icon: React.ReactNode;
  disabled?: boolean;
}

interface UserPanelMenuProps extends Omit<MenuProps, "children"> {
  actions: Array<UserPanelAction>;
  user: User;
  getContentAnchorEl?:any;
}

function UserPanelMenu({ actions, user, ...props }: UserPanelMenuProps) {
  return (
    <Menu {...props}>
      {actions.map((action) => (
        <Menu.Item
          key={action.label}
          icon={action.icon}
          onClick={action.onClick}
          disabled={action?.disabled}
          style={{
            width: '100%'
          }}
        >
          <span className={action.className} style={{fontFamily:"Source Sans Pro"}}>{action.label}</span>
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default UserPanelMenu;
