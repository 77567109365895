import { createContext, useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { apiPharmaInstance } from 'utils/axios';
import { getCurrentUser } from 'api';
import { User, ResponseType, Role } from 'common/types';
import rules from 'rbac-rules';
import { useUser } from 'utils/hooks';
import { getRoleById } from 'utils/string';
import { useRouter } from 'next/router';
import DefaultErrorPage from 'next/error';
export interface AuthProviderValue {
    login: (username: string, password: string) => Promise<ResponseType<User>> | undefined;
    loading: boolean;
    logout: () => void;
    checkRoleAction: (roleId: number, action: string) => boolean;
}

const AuthContext = createContext<AuthProviderValue>(undefined);

export function AuthProvider({ children }) {
    const [loading, setLoading] = useState<boolean>(false);
    const { mutateUser } = useUser({});

    const login = async (username: string, password: string) => {
        try {
            setLoading(true);
            const { data } = await apiPharmaInstance.post('api/auth/login', null, {
                auth: {
                    username,
                    password,
                },
            });
            const { token } = data;
            if (token) {
                Cookies.set('token', token, { expires: 60 });
                Cookies.remove('resendsId');
                localStorage.removeItem('notification_priority_tolerance');
                localStorage.removeItem('openSections');
                localStorage.removeItem('showPanel');
                const { data } = await getCurrentUser();
                setLoading(false);
                return data;
            }
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const logout = () => {
        mutateUser(null);
        Cookies.remove('token');
        Cookies.remove('resendsId');
        localStorage.removeItem('notification_priority_tolerance');
        // These 3 show_warnings are no longer used, but are left to be removed for all users
        localStorage.removeItem('show_warning_alert'); //version 1.0
        localStorage.removeItem('show_warning_alert_update'); //version 2.0
        localStorage.removeItem('show_warning_alert_v2'); //version 3.0
        // remove redirectToLogin
        localStorage.removeItem('redirectToLogin');
        // remove firstPosLoginFetched
        localStorage.removeItem('firstPosLoginFetched');
    };

    function checkRoleAction(roleId, action) {
        const role = getRoleById(roleId);

        const permissions = rules[role];
        const permissionCondition = permissions.dynamic?.some((permission) => permission === action);

        if (!permissionCondition) {
            return false;
        }

        return true;
    }

    return <AuthContext.Provider value={{ login, loading, logout, checkRoleAction }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
}

export function ProtectRoute(Component) {
    return () => {
        const { pathname, query, push } = useRouter();
        const { user } = useUser({
            redirectTo: '/login',
        });

        useEffect(() => {
            if (user) {
                if (pathname === '/') {
                    const role = getRoleById(user.role_id);
                    const { initialResource } = rules[role];
                    if (user.role_id === Role.Admin) {
                        push(`${initialResource}`);
                        return;
                    }
                    //@ts-ignore
                    push(`${user.client.id}/${initialResource}`);
                }
            }
        }, [user]);

        if (!user) {
            return <div data-testid="empty" />;
        }
        const [, resource, ...subdomain] = pathname.split('/');

        const performResource = () => {
            if (subdomain.length > 0) {
                const valueIsId = subdomain[0].match(/\[(.*?)\]/);
                if (valueIsId) {
                    return `${resource}:visit`;
                } else {
                    return `${subdomain[0]}:visit`;
                }
            }
            return `${resource}:visit`;
        };

        return (
            <Can
                user={user}
                pathname={pathname}
                perform={performResource()}
                yes={() => <Component {...user} />}
                no={() => <DefaultErrorPage statusCode={404} />}
            />
        );
    };
}

const checkHasFlagRouteAccess = ({ user, pathname }: { user: User; pathname: string }) => {
    const EX5242_ME = user?.EX5242; //epic drugmanufacturer offline
    if (!EX5242_ME && pathname === '/[clientId]/settings/POS/[posId]/addOfflineDrugstore') {
        return false;
    }
    return true;
};

function checkRules(rules, action, data, user: User, pathname) {
    const role = getRoleById(user.role_id);

    const permissions = rules[role];
    if (!permissions) {
        return false;
    }

    const hasFlagRouteAccess = checkHasFlagRouteAccess({ user, pathname });
    if (!hasFlagRouteAccess) {
        return false;
    }

    const { static: staticPermissions, dynamic } = permissions;

    if (staticPermissions && staticPermissions.includes(action)) {
        return true;
    }

    if (dynamic) {
        const permissionCondition = dynamic[action];
        if (!permissionCondition) {
            return false;
        }

        return permissionCondition(data);
    }
    return false;
}

export function Can({ perform, data = {}, user, pathname, ...props }) {
    return checkRules(rules, perform, data, user, pathname) ? props.yes() : props.no();
}
