import React from 'react'
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const CustomButton = withStyles((theme) => (
    {
    root: {
        color: (props: any) => props.color,
        backgroundColor: (props: any) => props.background,
        width: (props: any) => props.width,
        minWidth: (props: any) => props.minWidth,
        height: (props: any) => props.height,
        textTransform: "capitalize",
        fontSize: "1.1rem",
        "&:hover": {
            backgroundColor: "#80E1E4",
        },
        "&:active": {
            backgroundColor: "#00B0BC",
        },
    },
}))(Button);

export default CustomButton;