// ModalContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const ModalContextVolumeDiscount = createContext(null);

export const ModalProviderVolumeDiscount = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [drugManufacturerName, setDrugManufacturerName] = useState('');
    const [bonusProduct, setBonusProduct] = useState([]);
    const [quantityProduct, setQuantityProduct] = useState(0);
    const [isApplied, setIsApplied] = useState([]);
    const [isCellChecked, setIsCellChecked] = useState(false);
    const [ newBonusProduct, setNewBonusProduct ] = useState([]);
    // sort bonus product by paid quantity in descending order
    const sortedBonusProduct = [...bonusProduct].sort((a, b) => b.paid - a.paid);

    const openModal = () => {
        setIsModalOpen(true);
        // setIsApplied(isApplied);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        // const newAppliedIndices = [];
        // let bonusProducts = 0;
        // bonusProduct.forEach((item, index) => {
        //     bonusProducts += item.paid;
        //     if (quantityProduct >= bonusProducts) {
        //         newAppliedIndices.push(index);
        //     }
        // });
        // setIsApplied(newAppliedIndices);
        setIsCellChecked(isCellChecked);
        // if (bonusProduct.length > 0) {
        //     setBonusProduct(sortedBonusProduct);
        // }
    }, [bonusProduct, quantityProduct]);

    useEffect(() => {
        setDrugManufacturerName(drugManufacturerName);
        // setIsApplied(isApplied);
        // setBonusProduct(sortedBonusProduct);
        setQuantityProduct(quantityProduct);
        // if (bonusProduct.length > 0) {
        //     setBonusProduct(sortedBonusProduct);
        // }
    }, [drugManufacturerName]);

    return (
        <ModalContextVolumeDiscount.Provider
            value={{
                isModalOpen,
                openModal,
                closeModal,
                drugManufacturerName,
                setDrugManufacturerName,
                bonusProduct,
                setBonusProduct,
                isApplied,
                setIsApplied,
                quantityProduct,
                setQuantityProduct,
                isCellChecked,
                setIsCellChecked,
                setNewBonusProduct,
                newBonusProduct
            }}
        >
            {children}
        </ModalContextVolumeDiscount.Provider>
    );
};

export const useModalVolumeDiscount = () => {
    return useContext(ModalContextVolumeDiscount);
};
