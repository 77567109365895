
// aria
export const ariaLabelClient = ["id", "name","email","country","points_of_sale_qty","created_at"];
export const ariaNewLabelClient = ["id","name","email","client_type_name","country", "points_of_sale_qty", "created_at"];
export const ariaLabelPOS = ["id", "name", "address", "format_status_id", "created_at", "clone", "has_clone"];
export const ariaNewLabelPOS = ["id", "name", "address", "drugManufacturers", "created_at", "clone", "has_clone"];
export const ariaLabelUser = ["id", "first_name", "email", "username", "role_id", "pointsOfSale", "status"];

export const ariaLabelUserSearch = ["id", "first_name", "client", "email", "username", "role_id", "status"];
export const ariaLabelPosSearch = ["id", "name", "client_name", "address", "created_at",'fake_1','fake_2'];
export const ariaLabelClientSearch = ["id", "name", "email",'fake_2',"created_at",'fake_2','fake_3','fake_4'];
export const ariaNewLabelClientSearch = ["id", "name", "email","client_type_name","created_at",'fake_2','fake_3','fake_4'];