// json data
import { EX6166 } from 'config/flags';
import imageMap from './image.json';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';

export const addImageManufacturedCredentials = (drugManufacturedData) => {
    const baseUrl = '/assets/images/';

    return drugManufacturedData?.data?.map(
        ({
            id,
            name,
            buy_opetarion_type: buyOperationType,
            credential_types,
            deposits,
            client_types,
            format_status,
            url,
            is_offline,
            offline,
            type,
            speciality,
            is_on,
            vendor,
            last_available
        }) => {
            let nameWithoutBar = name;
            if (name.includes('/')) {
                nameWithoutBar = name.split('/')[0].trim();
            }

            const imageName = imageMap[nameWithoutBar.toLowerCase()];
            const image = imageName ? `${baseUrl}${imageName}` : null;
            return {
                id,
                name,
                buy_opetarion_type: buyOperationType,
                credential_types,
                deposits,
                client_types,
                format_status,
                image,
                url,
                is_offline,
                offline,
                type,
                speciality,
                is_on,
                vendor,
                last_available
            };
        },
    );
};

const orderCredentials = (arr: any[], pos1: number, pos2: number, pos3: number): any[] => {
    // Order the elements in the specified positions and clean undefined
    if (arr?.length > 0) return [arr[pos1], arr[pos2], arr[pos3]]?.filter((item: any) => item != undefined);
};

export const credentialTypesInOrder = (credentialTypes): any[] => {
    const POS1 = 1;
    const POS2 = 2;
    const POS3 = 0;

    return orderCredentials(credentialTypes, POS1, POS2, POS3);
};

export const handleSwitch = (name, credentialTypes) => {
    switch (name) {
        case 'scraper':
            if (credentialTypes?.length > 2) {
                return 'web actual';
            } else {
                return 'web';
            }
        case 'scraper_np':
            return 'web nueva';
        case 'hola':
            return 'web/api';
        default:
            return 'api';
    }
};

export const countInstances = (credentialType) => {
    return credentialType?.reduce((acc, elem) => {
        if (elem.connected === true) {
            return acc + 1;
        } else {
            return acc;
        }
    }, 0);
};

export const countInstancesV2 = (credentialType) => {
    const filterCredentials = (elem) => {
        const oldIsOnlineConnected = elem?.itUsesAddressAR
            ? !!elem?.connected === true && !elem?.errorUserPasswordConnection && elem?.hasAddressSelectedAR !== false
            : !!elem?.connected === true && !elem?.errorUserPasswordConnection;
        const newIsOnlineConnected = elem?.itUsesSteps
            ? !!elem?.connected === true && !elem?.errorUserPasswordConnection && elem?.hasStepCompleted
            : !!elem?.connected === true && !elem?.errorUserPasswordConnection;
        const isOnlineConnected = EX6166 ? newIsOnlineConnected : oldIsOnlineConnected;
        const isOffline = !!elem?.is_offline && !!elem?.offline.price_request_enabled;
        return isOnlineConnected || isOffline;
    };
    return credentialType?.filter(filterCredentials)?.length;
};

export const countInstancesV3 = (credentialType) => {
    const filterCredentials = (elem) => {
        const isOnlineConnected = !!elem?.connected === true && !elem?.errorUserPasswordConnection;
        const isOffline = !!elem?.is_offline && !!elem?.offline.price_request_enabled;
        const isVendor = elem?.vendor?.status === 1 && elem?.type?.id === EDrugManufacturerTypeId.vendor
        return isOnlineConnected || isOffline || isVendor;
    };
    return credentialType?.filter(filterCredentials)?.length;
};
