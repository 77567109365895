import React, { createContext, useContext, useState } from 'react';

const ProductUpdateQuantityContext = createContext(null);

export const ProductUpdateQuantityProvider = ({ children }) => {
    const [updateSuccessful, setUpdateSuccessful] = useState(false);

    return (
        <ProductUpdateQuantityContext.Provider
            value={{
                updateSuccessful,
                setUpdateSuccessful,
            }}
        >
            {children}
        </ProductUpdateQuantityContext.Provider>
    );
};

export const useProductUpdateQuantity = () => useContext(ProductUpdateQuantityContext);
