// next
import { useRouter } from 'next/router';
import Image from 'next/image';
// react
import { useState } from 'react';
// rsuite
import { IconButton } from 'rsuite';
// owner css
import style from './ExButtonONOFF.module.css';
// utils
import { auxiliaryPaper } from './utils';
// interface
import { IExButtonONOFFProps, TExButtonONOFF } from './ExButtonONOFF.interface';
// context
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';

const ExButtonONOFF: React.FC<IExButtonONOFFProps> = ({ initialState = '', drugManufacturerId, record, from }) => {
    // Next
    const router = useRouter();
    const { query } = router;
    // context
    const { setIsFetchingStateOnOff,setFromFetching } = useModalCredentialsContext();
    // Record last item
    const { last_available } = record;

    // Auxiliary function for getting icon and styles
    const { getStylesAndIcons, handlerToggleSubmit } = auxiliaryPaper();
    const { icon, classNameStyle } = getStylesAndIcons(style, initialState);

    // Click handler to toggle visibility state
    const handleToggle = () => {
        handlerToggleSubmit(drugManufacturerId, query, setIsFetchingStateOnOff,from,setFromFetching);
    };

    return (
        <IconButton
            className={`${style['base-container']} ${classNameStyle} ${
                initialState === 'OFF' ? 'button__wholesaler-on' : 'button__wholesaler-off'
            }`}
            icon={
                <Image
                    src={icon}
                    width={16}
                    height={16}
                    alt={`${initialState} state icon`} // Dynamic alt text for accessibility
                    draggable={false}
                />
            }
            onClick={() => handleToggle()} // Toggle state on button click
            aria-label={`Turn ${initialState === 'ON' ? 'on' : 'off'}`} // Accessibility label
            disabled={initialState === '' || initialState === null || !!last_available}
        />
    );
};

export default ExButtonONOFF;
