import { createContext, useContext, useEffect, useState } from 'react';

import { existsProductInCartById, updateProductCartById } from 'utils/cart';
import { useCart } from '../cart-context';

import { apiPharmaInstance } from 'utils/axios';
import { filterSearch } from 'utils/filterFunction';

import { DEV } from 'config/flags';
import { useUser } from 'utils/hooks';

interface Product {
    id: number;
    code: number;
    description: string;
    quantity: number;
}

export interface SearchProductProviderProps {
    children: React.ReactNode;
    productsList: any[];
    currentSearch: string;
    setCurrentSearch: any;
    lastSearch: any[];
    setLastSearch: any;

    isLoading: boolean;
    setIsLoading: any;
    listedProducts: any;
    updateProductQuantity: (code: number, quantity: number) => void;
    existsProductInCart: (code: number) => boolean;
    searchDataLength: () => number;
    fetchData: (currentValue: string, page: number, size: number, isReturnResult: boolean, aggregations: any) => void;
    cleanSearchData: () => void;
    pagination: any;
    searchData: any;
    isOpen: boolean;
    handleOpen: (v: boolean) => void;
    resultsList: any;
    isLoadingByResults: boolean;
    paginationByResults: any;
    setSearchData: (v) => void;
    handleResetSearch: () => void;
    searchAggregations: any;
    setAggregationsTypes: (v) => void;
    aggregationsTypes: any;
    updateLastSearch: (value: string) => void;
    setFromAction?:(value: string) => void;
    activeShakeAnimation?:boolean;
    isClickButtonByCode?:boolean;
    handleClickButtonByCode?:() => void;
}

const SearchProductContext = createContext<Omit<SearchProductProviderProps, 'children'>>(null);

export function NewSearchProductProvider({ children, ...props }) {
    const [lastSearch, setLastSearch] = useState([]);
    const [listedProducts, setListedProducts] = useState([]);
    const [currentSearch, setCurrentSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [searchAggregations, setSearchAggregations] = useState([]);
    const [aggregationsTypes, setAggregationsTypes] = useState({});
    const [pagination, setPagination] = useState<any>({});
    const { productsList } = useCart();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // states by list results only
    const [resultsList, setResultsList] = useState<any>([]);
    const [isLoadingByResults, setIsLoadingByResults] = useState<boolean>(false);
    const [paginationByResults, setPaginationByResults] = useState<any>({});
    const [activeShakeAnimation, setActiveShakeAnimation] = useState(false);
    const [isClickButtonByCode, setIsClickButtonByCode] = useState(false);

    const handleOpen = (v) => {
        setIsOpen(v);
    };
    const { user } = useUser({});
    const EX5943 = user?.EX5943; //epic rediseño home

    const handleResetSearch = () => {
       
        // reset states when close open input
        setCurrentSearch('');
        setSearchData([]);
        setPagination({});
        setAggregationsTypes({});
    };

    const fetchData = async (currentValue, page = 1, size = 50, loadResults = false, aggregations = {}) => {
        if (!!loadResults) setIsLoadingByResults(true);
        setIsLoading(true);
        try {
            // DEVELOPMENT MODE IF
            if (DEV) {
                const { data: response } = await apiPharmaInstance.post(`/api/points-of-sale/44/search-products`, {
                    search: currentValue,
                    size,
                    from: page,
                    ...aggregations,
                });

                !!user?.EX5178 && setSearchData(response.data.hits);
                user?.EX5178 && setSearchAggregations(response.data.aggregations);
                !user?.EX5178 && setSearchData(response.data.hits);

                if (!!loadResults) {
                    setResultsList(response.data.hits);
                    setPaginationByResults(response.meta.pagination);
                    if(EX5943) setPagination(response.meta.pagination);
                } else {
                    setPagination(response.meta.pagination);
                }
                setIsLoading(false);
                if (!!loadResults) setIsLoadingByResults(false);
            } else {
                const posId = JSON.parse(localStorage.getItem('pdvSelected')).id;
                const { data: response } = await apiPharmaInstance.post(
                    `/api/points-of-sale/${posId}/search-products`,
                    { search: currentValue, size, from: page, ...aggregations },
                );
                !!user?.EX5178 && setSearchData(response.data.hits);
                !!user?.EX5178 && setSearchAggregations(response.data.aggregations);
                !user?.EX5178 && setSearchData(response.data.hits);
                if (!!loadResults) {
                    setResultsList(response.data.hits);
                    setPaginationByResults(response.meta.pagination);
                    if(EX5943) setPagination(response.meta.pagination);
                } else {
                    setPagination(response.meta.pagination);
                }
                setIsLoading(false);
                if (!!loadResults) setIsLoadingByResults(false);
            }

            return;
        } catch (error) {
            const statusCode = [500, 400, 404];
            if (statusCode.includes(error?.response?.status)) {
                setIsLoading(false);
                if (!!loadResults) setIsLoadingByResults(false);
                setSearchData([]);
                setPagination([]);
            }
        }
    };

    const searchDataLength = () => {
        return searchData?.length;
    };

    const cleanSearchData = () => {
        setSearchData([]);
        setListedProducts([]);
    };

    const updateProductQuantity = (code: number, quantity: number) => {
        return updateProductCartById(productsList, code, quantity);
    };

    const updateLastSearchFromLocalStorage = () => {
        const lastSearchFromLocalStorage = JSON.parse(localStorage.getItem('lastSearch'));
        if (!lastSearchFromLocalStorage) {
            localStorage.setItem('lastSearch', JSON.stringify([]));
        }
        setLastSearch(lastSearchFromLocalStorage);
    };

    const updateLastSearch = (debouncedValue) => {
        // update last search localStorage
        if (
          lastSearch?.length === 0 ||
          lastSearch?.indexOf(debouncedValue) === -1
        ) {
          lastSearch?.unshift(debouncedValue);
          const slicedLastSearch = lastSearch.slice(0, 6);
          setLastSearch(slicedLastSearch);
          localStorage.setItem('lastSearch', JSON.stringify(slicedLastSearch));
        }
      };

    // VALIDAR SI EXISTE UN PRODUCTO POR ID
    const existsProductInCart = (code: number) => {
        const existProductInCart = existsProductInCartById(productsList, code);
        return existProductInCart;
    };

    useEffect(() => {
        updateLastSearchFromLocalStorage();
    }, []);

    useEffect(() => {
        if (currentSearch.trim() !== '') {
            if(EX5943) {
                fetchData(currentSearch, 1, 50, true);
            }else {
                fetchData(currentSearch);
            }
        }
    }, [currentSearch]);

    useEffect(() => {
        const filterSearchData = filterSearch(searchData, currentSearch);
        setListedProducts(filterSearchData);
    }, [searchData]);

    const handleClickButtonByCode = () => {
        // Cierra el dropdown y limpia los valores
         setCurrentSearch('');
        
        
        handleOpen(false);

        handleResetSearch();
        

        // Activa la animación de shake
        setActiveShakeAnimation(true);
        // Indica que se hizo clic en el botón
        setIsClickButtonByCode(true);
        // Desactiva la animación y el clic después de 1 segundo
        setTimeout(() => {
            setActiveShakeAnimation(false);
            setIsClickButtonByCode(false);
        }, 1000);
    };

    return (
        <SearchProductContext.Provider
            value={{
                productsList,
                currentSearch,
                setCurrentSearch,
                lastSearch,
                setLastSearch,
                updateProductQuantity,
                existsProductInCart,
                isLoading,
                setIsLoading,
                fetchData,
                searchDataLength,
                cleanSearchData,
                listedProducts,
                pagination,
                searchData,
                isOpen,
                handleOpen,
                resultsList,
                isLoadingByResults,
                paginationByResults,
                setSearchData,
                handleResetSearch,
                searchAggregations,
                setAggregationsTypes,
                aggregationsTypes,
                updateLastSearch,
                activeShakeAnimation,
    isClickButtonByCode,
    handleClickButtonByCode,
                ...props,
            }}
        >
            {children}
        </SearchProductContext.Provider>
    );
}

export function useNewSearchProduct() {
    const context = useContext(SearchProductContext);

    if (context === undefined) {
        throw new Error('useSearchProductContext must be used within a ConfirmationProvider');
    }

    return context;
}
