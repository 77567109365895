// ModalContext.js
import { getAlerts } from 'api/alerts/alerts';
import React, { createContext, useContext, useState, useEffect } from 'react';

const AlertsContext = createContext(null);

export const AlertsProviders = ({ children }) => {
    const [alerts, setAlerts] = useState([]);
    const [fetch, setFetch] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    /**
     * Handles fetching alerts.
     * @async
     * @function
     * @returns {Promise<Array<Object>>} - Promise resolving to an array of alert data.
     */
    const handlerFetchAlerts = async () => {
        try {
            const response = await getAlerts();
            setAlerts(response?.data);
        } catch (ex) {
            console.error(ex.message);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            (async () => await handlerFetchAlerts())();
        }, 2000);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (!!fetch) (async () => await handlerFetchAlerts())();
        }, 2000);
    }, [fetch, openDrawer]);

    return (
        <AlertsContext.Provider
            value={{ alerts, setAlerts, setFetch, fetch, openDrawer, setOpenDrawer, handlerFetchAlerts }}
        >
            {children}
        </AlertsContext.Provider>
    );
};

export const useAlerts = () => {
    return useContext(AlertsContext);
};
