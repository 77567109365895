import React from "react";
import { ModalDontHaveCredentialsProps } from "./modal-dont-have-credentials-interface";

const ModalDontHaveCredentialsContext = React.createContext<Omit<ModalDontHaveCredentialsProps, "children">>(
    null
);

export const ModalDontHaveCredentialsProvider = ({ children }) => {
    const [dontHaveCredentialsModal, setDontHaveCredentialsModal] = React.useState<boolean>(false);

    return (
        <ModalDontHaveCredentialsContext.Provider
            value={{
                dontHaveCredentialsModal,
                setDontHaveCredentialsModal,
            }}
        >
            {children}
        </ModalDontHaveCredentialsContext.Provider>
    );
};

export function useModalDontHaveCredentials() {
    const context = React.useContext(ModalDontHaveCredentialsContext);
    if (context === undefined) {
        throw new Error("usePos must be used within a PosProvider");
    }
    return context;
}