// react
import { useEffect, useRef, useState } from 'react';
// components
import { DrawerAlertSelect } from '../DrawerAlertSelect';
// rsuite
import { CheckPicker, Checkbox, Button } from 'rsuite';
// style
import style from './DrawerAlertCountry.module.css';
// utils
import { auxiliary } from './utils';
import { useClientCountry } from 'utils/hooks/use-clients';

const DrawerAlertCountry = ({
    setCountry,
    defaultValue,
    setFormChanged,
    lastId,
    country,
    setIsFormRequired,
    isFormRequired,
    isEditForm,
}) => {
    // ref
    const buttonRef = useRef(null);
    //hooks
    const { countries } = useClientCountry();
    // state
    const [hasError, setHasError] = useState(false);

    // logics
    const actions = auxiliary();

    const recordset = actions.recordsetCountries(countries);
    const allValues = actions.selectAllValues(recordset);

    // init
    useEffect(() => {
        if (defaultValue && defaultValue.length > 0 && !!lastId) {
            setCountry(defaultValue);
        } else {
            setCountry([]);
            setHasError(false);
        }
    }, []);

    return (
        <DrawerAlertSelect label="País">
            <CheckPicker
                ref={buttonRef}
                data={recordset} // recordset
                block
                searchable={true}
                value={country}
                className={!!hasError && country?.length === 0 ? style['text-error-input'] : style['checkbox-picker']}
                placeholder={'Seleccioná el país'}
                onClean={() => actions.handleResetValues(setCountry, setHasError, country)}
                onChange={(evt) =>
                    actions.handleSelectCountry(
                        evt,
                        setCountry,
                        isFormRequired,
                        setIsFormRequired,
                        'country',
                        isEditForm,
                        setFormChanged,
                    )
                }
                name="countries"
                renderExtraFooter={() => (
                    <div className={style['checkAll-container']}>
                        <Checkbox
                            indeterminate={country?.length > 0 && country?.length < allValues?.length}
                            checked={country?.length === allValues?.length}
                            onChange={() =>
                                actions.handlerSelectAllChange(
                                    setCountry,
                                    country,
                                    allValues,
                                    isFormRequired,
                                    setIsFormRequired,
                                    'country',
                                    isEditForm,
                                    setFormChanged,
                                )
                            }
                            inline
                        >
                            Seleccionar todo
                        </Checkbox>
                        <Button
                            appearance="primary"
                            className={style['container-button']}
                            onClick={(evt) =>
                                actions.handleApplyClick(
                                    evt,
                                    buttonRef,
                                    setCountry,
                                    country,
                                    isFormRequired,
                                    setIsFormRequired,
                                    'country',
                                    setFormChanged,
                                    isEditForm,
                                )
                            }
                        >
                            Aplicar
                        </Button>
                    </div>
                )}
            />
        </DrawerAlertSelect>
    );
};

export default DrawerAlertCountry;
