import React, { createContext, useContext, useState } from "react";
import { RecommendedProductsProviderProps } from "./recommended-products-interface";

const RecommendedProductsContext = createContext<Omit<RecommendedProductsProviderProps, "children">>(null);

export function RecommendedProductsProvider({ children, ...props }) {
    const [recommendedProducts, setRecommendedProducts] = useState([]);
    const [fetching, setFetching] = useState<boolean>(false);
    const [reFetching, setReFetching] = useState<boolean>(false);
    const [pagination, setPagination] = useState<any | null>(null);

    // load state
    const loadRecommendedProducts = (data: any) => {
        if(data.length) {
            setRecommendedProducts(data);
        }else {
            setRecommendedProducts([]);
        };
    };

    const setterFetching = (v: boolean) => {
        setFetching(v);
    };

    return (
        <RecommendedProductsContext.Provider
            value={{
                recommendedProducts,
                fetching,
                loadRecommendedProducts,
                setterFetching,
                reFetching,
                setReFetching,
                pagination,
                setPagination,
                ...props
            }}
        >
            {children}
        </RecommendedProductsContext.Provider>
    );
}

export function useRecommendedProducts() {
    const context = useContext(RecommendedProductsContext);

    if (context === undefined) {
        throw new Error("useRecommendedProducts must be used within a ConfirmationProvider");
    }

    return context;
}
