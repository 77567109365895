import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { IconButton } from "./icon-button";
import { Tooltip, TooltipProps } from "./tooltip";
import { makeStyles } from '@material-ui/core/styles';
export interface ToolbarIconProps {
  label: string;
  onClick?: () => void;
  IconComponent: React.ReactNode;
  style?: TooltipProps["style"];
  disabled?: boolean;
}

const useStyles = makeStyles({
  tooltipIcon: {
    "&:disabled": {
      backgroundColor: 'transparent !important'
    },
    margin: "0 8px"
  },
});

export function ToolbarIcon({
  label,
  onClick,
  IconComponent,
  disabled,
  ...props
}: ToolbarIconProps) {
  const theme = useContext(ThemeContext);
  const classes = useStyles();

  return (
    <Tooltip placement="bottom" label={label} textAlign="center" {...props}>
      <IconButton
        color="white"
        onClick={onClick}
        hoverColor={theme.colors.S[300]}
        className={classes.tooltipIcon}
        disabled={disabled ? disabled : false}
      >
        {IconComponent}
      </IconButton>
    </Tooltip>
  );
}
