import useSWR from 'swr';
// configs
import { FFRECOMMENDED } from 'config/flags';

export const useRecommendedProductsByOrderId = (pointOfSaleId, orderId) => {
    if (FFRECOMMENDED) {
        const { data } = useSWR(`/api/points-of-sale/${pointOfSaleId}/order/${orderId}/recommended-order-products`, {
            revalidateOnFocus: false, // No revalidar al recuperar el foco
            dedupingInterval: 300000, // 5 minutos para evitar solicitudes duplicadas
            shouldRetryOnError: false, // No reintentar en caso de error
        });
        return { data };
    }else {
        return {}
    }
};
