import useSWR from "swr";

export function useOrderDeliveryStatus(orderId) {
  const { data: order, mutate: mutateOrder } = useSWR(
    `/api/orders/${orderId}/deliveries`
  );
  return { order, mutateOrder };
}

export function useOrderProcess(orderId) {
  const { data: process, mutate: mutateOrderProcess } = useSWR(
    `/api/orders/${orderId}/process`
  );
  return { process, mutateOrderProcess };
}

export function useOrderCheckHaveShortages(orderId) {
  const { data } = useSWR(
    `/api/orders/${orderId}/order-products?filters[availability]=unavailable`
  );
  return { data };
}