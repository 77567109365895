import { IToastProps } from "../interfaces/Toast.interface";
import style from './ToastProgressBar.module.css'

type Props = {
    progress: number;
    type: IToastProps["type"]
}

const ToastProgressBar = ({progress, type} : Props) => {
    return (
        <div
            className={`${style['progress-bar']} ${style[`progress-bar-${type}`]}`}
            style={{ width: `${progress}%` }}
        ></div>
    )
}

export default ToastProgressBar;