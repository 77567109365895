import ModalErrorPlatform from 'components/ModalErrorPlatform/ModalErrorPlatform';
import React from 'react';

export interface AlertErrorProps {
    alertError: boolean;
    showModal: boolean;
    setAlertError: (alertError: boolean) => void;
    setShowModal: (alertError: boolean) => void;
}

const AlertErrorContext = React.createContext<Omit<AlertErrorProps, "children">>(
    null
);


export const AlertError = ({ children }) => {
    const [alertError, setAlertError] = React.useState<boolean>(true);
    const [showModal, setShowModal] = React.useState<boolean>(false);

    return (
        <AlertErrorContext.Provider
            value={{
                setAlertError,
                setShowModal,
                alertError,
                showModal
            }}
        >   
            <ModalErrorPlatform open={showModal} setOpen={() => setShowModal(false)} />
            {children}
        </AlertErrorContext.Provider>
    );

}

export function useAlertError() {
    const context = React.useContext(AlertErrorContext);
    if (context === undefined) {
        throw new Error("usePos must be used within a PosProvider");
    }
    return context;
}