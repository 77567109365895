import React, { useState } from "react";

export function useEllipsis(element: any) {
    const [isShowEllipsis, setIsShowEllipsis] = useState(false);

    const hasEllipsis = (element) => element.scrollWidth > element.clientWidth;

    const handleMouseEnter = () => {
        const productName = document.getElementById(`${element}`);

        if (productName && hasEllipsis(productName)) {
            setIsShowEllipsis(true);
        }
    };

    const handleMouseLeave = () => {
        setIsShowEllipsis(false);
    };
    return {
        isShowEllipsis,
        handleMouseEnter,
        handleMouseLeave,
        hasEllipsis,
    };
}
