// mui v5
import { CheckCircle, Error, ReportProblem } from '@mui/icons-material';

// enum
import { Status } from './StatusIcon.interface';

const StatusIcon = ({ value }) => {
  const getIcon = (name: string) => {
    switch (name) {
      case Status.VALIDATED:
        return (
          <CheckCircle
            style={{
              color: '#07ad4b',
            }}
          />
        );
      case Status.VALIDATION_PENDING:
        return (
          <ReportProblem
            style={{
              color: '#ffc11b',
            }}
          />
        );
      case Status.UNKNOWN:
        return (
          <Error
            style={{
              color: '#ff3030',
            }}
          />
        );
      default:
        null;
        break;
    }
  };

  const renderIcon = (value: any) => {
    const status = value.map((v: any) => v.name);
    return status.map((statusName: string) => getIcon(statusName));
  };
  
  const iconToRender = renderIcon(value);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      }}
    >
      {iconToRender}
    </div>
  );
};

export default StatusIcon;
