import React, { useState } from "react";
import Link from "next/link";
import styled from "styled-components";
import { rem } from "polished";
import { ListItem, Drawer } from "@material-ui/core";
import { useRouter } from "next/router";
import { FreshChat } from "./freshchat";

export interface SidebarIconProps {
  active: boolean;
  color: string;
  SvgComponent: React.FC<React.SVGProps<SVGSVGElement>>;
}

export interface Route {
  label: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  activeIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  path: string;
}

export interface MainSidebarProps {
  routes: Array<Route>;
  role: string;
}

const ToolbarSeparator = styled.div`
  min-height: ${rem("48px")};
`;

const StyledDrawer = styled(({ visible, ...props }) => (
  <Drawer {...props} classes={{ paper: "paper" }} />
))`
  width: ${rem("56px")};
  flex-shrink: 0;
  box-shadow: ${(props) => props.theme.shadows[1]};

  & .paper {
    position: relative;
    width: ${rem("56px")};
    background-color: ${(props) =>
      props.visible ? "#04273F" : props.theme.colors.B["900"]};
    overflow-x: hidden;
    white-space: nowrap;
    border-right: none;
  }
`;

const StyledListItem = styled(ListItem)`
  height: ${rem("72px")};
  align-items: center;
  justify-content: center;
  padding: 0;
`;

function SidebarIcon({ active, SvgComponent, color }: SidebarIconProps) {
  return (
    <SvgComponent
      style={{ opacity: active ? 1 : 0.7, color: "white" }}
      fill="currentColor"
      width={28}
      height={28}
    />
  );
}

export function MainSidebar({ routes, role }: MainSidebarProps) {
  const router = useRouter();

  const [fcVisible, setFcVisible] = useState(false);

  const toggleWidget = () => {
    // @ts-ignore
    const freshChatWidget = window.fcWidget;

    if (freshChatWidget) {
      const isOpen = freshChatWidget.isOpen();
      freshChatWidget.on("widget:closed", () => {
        setFcVisible(false);
      });

      freshChatWidget.on("widget:opened", () => {
        setFcVisible(true);
      });

      if (isOpen) {
        freshChatWidget.close();
      } else {
        // @ts-ignore
        document.getElementById("fc_frame").style.display = "block";
        freshChatWidget.open();
      }
    }
  };

  return (
    <>
      <StyledDrawer variant="permanent">
        <ToolbarSeparator />
        {routes.map((route) => {
          const { activeIcon, icon, label, path } = route;
          const isActive = path === router.pathname;

          return (
            <Link key={label} href={path}>
              <StyledListItem button title={label}>
                <SidebarIcon
                  color="white"
                  SvgComponent={isActive ? activeIcon : icon}
                  active={isActive}
                />
              </StyledListItem>
            </Link>
          );
        })}
      </StyledDrawer>
      {role === "client" && <FreshChat />}
    </>
  );
}
