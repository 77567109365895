// ModalContext.js
import React, { createContext, useContext, useState } from 'react';

const ModalContextWithoutCredit = createContext(null);

export const ModalWithoutCredit = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderWithoutStock, setOrderWithoutStock] = useState(false);
    const [drugManufacturerName, setDrugManufacturerName] = useState('');
    const [typeModal, setTypeModal] = useState('');

    const openModal = (drugManufacturerName) => {
        setDrugManufacturerName(drugManufacturerName);
        setOrderWithoutStock(true);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <ModalContextWithoutCredit.Provider
            value={{
                isModalOpen,
                openModal,
                closeModal,
                orderWithoutStock,
                drugManufacturerName,
                typeModal,
                setTypeModal,
            }}
        >
            {children}
        </ModalContextWithoutCredit.Provider>
    );
};

export const useModalWithoutCredit = () => {
    return useContext(ModalContextWithoutCredit);
};
