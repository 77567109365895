import React from "react";
import styled, { ThemeContext } from "styled-components";
import Link from "next/link";
import { rem } from "polished";
import { Breadcrumbs as MuiBreadcrumbs, Grid } from "@mui/material";

export interface LabelProps {
  to?: string;
  children: React.ReactNode;
  active?: boolean;
}

export interface BreadcrumbsProps {
  children: React.ReactNode;
  Label?: React.FC<LabelProps>;
  posAlertDisabled?: boolean;
  disablePosList?: boolean;
}

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
  .MuiBreadcrumbs-separator {
    margin: 0 ${rem("8px")};
  }
`;

const StyledSpan = styled.span<{ active: boolean }>`
font-family:"Source Sans Pro";
  color: ${(props) =>
    props.active ? props.theme.colors.B["900"] : props.theme.colors.S["800"]};
  font-size: ${rem("20px")};
  font-weight: ${(props) => (props.active ? 400 : 200)};
`;

const Wrapper = styled(Grid)`
  position: relative;
  font-family:"Source Sans Pro";
  box-shadow: ${(props) => props.theme.shadows["1"]};
  height: ${rem("72px")};
  z-index: 1000;
  padding: 0 ${rem("24px")};
  display: flex;
  align-items: center;
`;

function Label({ to, children, active = false }: LabelProps) {
  if (to) {
    return (
      <Link href={to}>
        <StyledSpan active={active}>{children}</StyledSpan>
      </Link>
    );
  }

  return <StyledSpan active={active}>{children}</StyledSpan>;
}

export function Breadcrumbs({ children, posAlertDisabled, disablePosList }: BreadcrumbsProps) {
  return (
    <Wrapper container>
      <Grid item xs={10}>
        <StyledBreadcrumbs>{children}</StyledBreadcrumbs>
      </Grid>
    </Wrapper>
  );
}

Breadcrumbs.Label = React.memo(Label);
