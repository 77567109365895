import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  cartButton: {
    "&.MuiButton-root": {
      width: 160,
      height: 56,
      fontSize: 16,
      marginLeft: 40,
      marginBottom: 6.5,
      backgroundColor: "#407D7A",
      "&:hover": {
        backgroundColor: "#407D7A",
      },
      "&:active": {
        backgroundColor: "#407D7A",
      },
    },
  },
  dot: {
    width: 20,
    height: 20,
    left: 43,
    top: 12,
    fontSize: 13,
    borderRadius: "50%",
    position: "absolute",
    zIndex: 9,
    backgroundColor: "#FF6300",
  },
});

export default useStyles;
