import React from "react";

export function useSorts(value) {
  const [sorts, setSorts] = React.useState(value);
  const reset = () => setSorts(undefined);
  const getSorts = () => sorts;
  const onSortChange = React.useCallback((value) => setSorts(value), [value]);

  return {
    getSorts,
    onSortChange,
    reset,
  };
}
