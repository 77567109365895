import React from "react";
import { useSafeDispatch } from "./use-safe-dispatch";

export enum Status {
  Idle = "idle",
  Pending = "pending",
  Resolved = "resolved",
  Rejected = "rejected",
}

function asyncReducer(state, action) {
  switch (action.type) {
    case Status.Pending: {
      return { status: Status.Pending, data: null, error: null };
    }
    case Status.Resolved: {
      return { status: Status.Resolved, data: action.data, error: null };
    }
    case Status.Rejected: {
      return { status: Status.Rejected, data: null, error: action.error };
    }
    case "CLEAR": {
      return { status: Status.Idle, data: null, error: null };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function useAsync(initialState) {
  const [state, unsafeDispatch] = React.useReducer(asyncReducer, {
    status: "idle",
    data: null,
    error: null,
    ...initialState,
  });

  const dispatch = useSafeDispatch(unsafeDispatch);

  const { data, error, status } = state;

  const run: any = React.useCallback(
    (promise) => {
      dispatch({ type: Status.Pending });
      return promise.then(
        (data) => {
          dispatch({ type: Status.Resolved, data });
        },
        (error) => {
          dispatch({ type: Status.Rejected, error });
        }
      );
    },
    [dispatch]
  );

  const setData = React.useCallback(
    (data) => dispatch({ type: Status.Resolved, data }),
    [dispatch]
  );

  const setError = React.useCallback(
    (error) => dispatch({ type: Status.Rejected, error }),
    [dispatch]
  );

  const clear = React.useCallback(() => dispatch({ type: "CLEAR" }), [
    dispatch,
  ]);

  return {
    setData,
    setError,
    error,
    status,
    data,
    run,
    clear
  };
}

export const isIdle = (status: Status) => status === Status.Idle;

export const isPending = (status: Status) => status === Status.Pending;

export const isRejected = (status: Status) => status === Status.Rejected;

export const isResolved = (status: Status) => status === Status.Resolved;
