import React from "react";

export const useHubspotChat = () => {
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [activeConversation, setActiveConversation] = React.useState(false);
  const eventRef = React.useRef(null);

  React.useEffect(() => {
    // @ts-ignore
    window.hsConversationsSettings = {
      loadImmediately: true,
      inlineEmbedSelector: '#hubspot-conversations-inline-parent',
      enableWidgetCookieBanner: true,
      disableAttachment: true
    };
    

   // @ts-ignore
   window.hsConversationsOnReady = [() => {
    setHasLoaded(true);
  }];
 
  // @ts-ignore
  let script = document.createElement('script');
  script.src = `//js.hs-scripts.com/22726338.js`;
  script.async = true;
  
  document.body.appendChild(script);
  
  return () => {
    document.body.removeChild(script);
    // @ts-ignore
    window.hsConversationsOnReady = [];
  }
  }, []);

  React.useEffect(() => {
    // @ts-ignore
    eventRef.current = (payload) => {
      setActiveConversation(payload.conversation.conversationId);
    };

  }, [hasLoaded]);

  const openHandler = React.useCallback(() => {
    if (hasLoaded) {
      // @ts-ignore
      window.HubSpotConversations.widget.open();
    }
  }, [hasLoaded]);

  const closeHandler = React.useCallback(() => {
    if (hasLoaded) {
      // @ts-ignore
      window.HubSpotConversations.widget.close();
    }
  }, [hasLoaded]);

  return {
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler,
  };
};