// utils && hooks
import { EErrorCode } from 'utils/enums/comparativeAlerts/comparativeAlerts';

interface ErrorTypes {
    has_wrong_username_password: boolean;
    missing_format: boolean;
    hasSomeError: boolean;
    include_format:boolean;
}

interface ErrorTypesMultiple {
    first_credential: number;
    second_credential: number;
    hasSomeError_first: boolean;
    hasSomeError_second: boolean;
    has_wrong_username_password_first: boolean;
    missing_forma_first: boolean;
    has_wrong_username_passwor_second: boolean;
    missing_forma_second: boolean;
}

export const typesOfErrors = (obj: any): ErrorTypes => {
    const has_wrong_username_password = obj?.credential_types?.some(
        (credential: any) =>
            credential?.errors?.some(
                (error: any) => error?.id === EErrorCode.WRONG_USERNAME_PASSWORD
            )
    );

    const include_format = obj?.credential_types?.some(
        (credential: any) =>
            credential?.format_ticket !== null && obj?.format_status?.id === 2
    );

    const missing_format = obj?.credential_types?.some(
        (credential: any) =>
            credential?.errors?.some(
                (error: any) => error?.id === EErrorCode.MISSING_FORMAT
            )
    );

    return {
        has_wrong_username_password: !!has_wrong_username_password, // hay error de user-pass
        missing_format: !!missing_format, // falta formato
        include_format: !!include_format, // include format
        hasSomeError:
            !!has_wrong_username_password || !!missing_format || !!include_format, // existe algún error
    };
};


export const typesOfErrorsMultiple = (obj: any): ErrorTypesMultiple => {
    // error de credenciales
    const has_wrong_username_password_first: boolean =
        obj?.credential_types?.[0]?.errors?.[0]?.id === EErrorCode.WRONG_USERNAME_PASSWORD;
    // sin formato
    const missing_forma_first = obj?.credential_types?.[0]?.format_ticket !== null && obj?.format_status?.id === 2;
    // error de credenciales
    const has_wrong_username_passwor_second: boolean =
        obj?.credential_types?.[1]?.errors?.[0]?.id === EErrorCode.WRONG_USERNAME_PASSWORD;
    // sin formato
    const missing_forma_second = obj?.credential_types?.[1]?.format_ticket !== null && obj?.format_status?.id === 2;

    return {
        first_credential: obj?.credential_types?.[0]?.id,
        second_credential: obj?.credential_types?.[1]?.id,
        has_wrong_username_password_first, // hay error de user-pass en la primer credencial
        missing_forma_first, // falta formato en la primer credencial
        has_wrong_username_passwor_second, // hay error de user-pass en la primer credencial
        missing_forma_second, // falta formato en la segunda credencial
        hasSomeError_first: !!has_wrong_username_password_first || !!missing_forma_first,
        hasSomeError_second: !!has_wrong_username_passwor_second || !!missing_forma_second,
    };
};
