import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled, { ThemeContext } from "styled-components";
import { rem } from "polished";
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipTrops,
} from "@material-ui/core";

export interface TooltipProps extends Omit<MuiTooltipTrops, "title"> {
  backgroundColor?: string;
  labelColor?: string;
  textAlign?: string;
  label?: string;
  maxWidth?: string;
  minWidth?: string;
  enableArrow?: boolean;
}

const StyledTooltip = styled(
  ({
    backgroundColor,
    labelColor,
    textAlign,
    maxWidth,
    ...props
  }: TooltipProps & Pick<MuiTooltipTrops, "title">) => (
    <MuiTooltip
      {...props}
      classes={{ popper: props.className, tooltip: "tooltip" }}
    />
  )
)`
  cursor: pointer;

  & .tooltip {
    position: relative;
    padding: ${rem("8px")} ${rem("16px")};
    border-radius: ${rem("4px")};
    color: ${(props) => props.labelColor};
    font-size: ${rem("12px")};
    max-width: ${(props) =>
      props?.maxWidth ? rem(props?.maxWidth) : rem("200px")};
    min-width: ${(props) =>
      props?.minWidth ? rem(props?.minWidth) : rem("84px")};
    background-color: ${(props) => props.backgroundColor};
    box-shadow: ${(props) => props.theme.shadows["1"]};
    min-height: ${rem("24px")};
    margin: ${rem("12px")};
    display: flex;
    align-items: center;
    word-break: break-word;
    justify-content: ${(props) => props.textAlign};
  }
`;

const arrowGenerator = (color: string, height?: any, width?: any) => ({
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: "0 !important",
    right: 0,
    marginTop: "-0.95em",
    "&::before": {
      borderWidth: "0 1em 1em 1em",
      borderColor: `transparent transparent ${color} transparent`,
    },
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: "0 !important",
    right: 0,
    marginBottom: "-0.95em",
    "&::before": {
      borderWidth: "1em 1em 0 1em",
      borderColor: `${color} transparent transparent transparent`,
    },
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    top: "initial !important",
    marginLeft: "-0.95em",
    height: height ?? "2em",
    width: width ?? "1em",
    "&::before": {
      borderWidth: "1em 1em 1em 0",
      borderColor: `transparent ${color} transparent transparent`,
    },
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    top: "initial !important",
    marginRight: "-0.95em",
    height: height ?? "2em",
    width: width ?? "1em",
    "&::before": {
      borderWidth: "1em 0 1em 1em",
      borderColor: `transparent transparent transparent ${color}`,
    },
  },
});

const useStylesArrow = (color: string, height?: any, width?: any) =>
  makeStyles(() => ({
    arrow: {
      position: "absolute",
      fontSize: 6,
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
      },
    },
    popper: arrowGenerator(color, height, width),
  }));

export function Tooltip({
  backgroundColor,
  children,
  label,
  enableArrow = true,
  ...props
}: TooltipProps) {
  const theme = useContext(ThemeContext);
  const { arrow, popper } = useStylesArrow(
    backgroundColor ?? theme.colors.B["900"],
    props?.style?.width,
    props?.style?.height
  )();
  const [arrowRef] = React.useState(null);

  return (
    <StyledTooltip
      backgroundColor={backgroundColor ?? theme.colors.B["900"]}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      role="tooltip"
      title={
        <React.Fragment>
          {label}
          {enableArrow && <span className={arrow} /> }
        </React.Fragment>
      }
      className={popper}
      {...props}
    >
      {children}
    </StyledTooltip>
  );
}
