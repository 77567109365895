import { ACTION } from "common/types";

interface PermissionsInfo {
  initialResource: string;
  static: string[];
  dynamic?: string[];
}

type Roles = "client" | "admin" | "owner" | "manager" | "buyer";

const rules: Record<Roles, PermissionsInfo> = {
  client: {
    initialResource: "efficient-purchase",
    static: [
      ":visit",
      "initial-config:visit",
      "efficient-purchase:visit",
      "settings:visit",
    ],
  },
  admin: {
    initialResource: "clients",
    static: [
      ":visit",
      "backoffices:visit",
      "clients:visit",
      "create:visit",
      "new:visit",
      "pos:visit",
      "searchClients:visit",
      "types:visit",
      "drugmanufacturers:visit"
    ],
  },
  owner: {
    initialResource: "efficient-purchase",
    static: [
      ":visit",
      "initial-config:visit",
      "posDetail:visit",
      "tradeAgreements:visit",
      "efficient-purchase:visit",
      "file-load:visit",
      "product-comparison:visit",
      "confirmation:visit",
      "order-sent:visit",
      "settings:visit",
      "my-orders:visit",
      "details:visit",
      "deliveries:visit",
    ],
    dynamic: [ACTION.TRADE_AGREEMENTS_READ, ACTION.TRADE_AGREEMENTS_EDIT],
  },
  manager: {
    initialResource: "efficient-purchase",
    static: [
      ":visit",
      "initial-config:visit",
      "tradeAgreements:visit",
      "posDetail:visit",
      "efficient-purchase:visit",
      "file-load:visit",
      "product-comparison:visit",
      "confirmation:visit",
      "order-sent:visit",
      "settings:visit",
      "my-orders:visit",
      "details:visit",
      "deliveries:visit",
    ],
    dynamic: [ACTION.TRADE_AGREEMENTS_READ, ACTION.TRADE_AGREEMENTS_EDIT],
  },
  buyer: {
    initialResource: "efficient-purchase",
    static: [
      ":visit",
      "initial-config:visit",
      "settings:visit",
      "posDetail:visit",
      "efficient-purchase:visit",
      "file-load:visit",
      "product-comparison:visit",
      "confirmation:visit",
      "order-sent:visit",
      "my-orders:visit",
      "details:visit",
      "deliveries:visit",
    ],
    dynamic: [],
  },
};

export default rules;
