import React, { useState } from 'react';
import style from './CheckboxAlert.module.css';

import { Radio, RadioGroup } from 'rsuite';
import { auxiliaryFunctions, createTypeAlert } from './utils';

const CheckboxAlert = ({
    setAlertType,
    alertType,
    defaultValue,
    setFormChanged,
    setIsFormRequired,
    isFormRequired,
}) => {
    const actions = auxiliaryFunctions();
    return (
        <RadioGroup
            name="notification_type"
            //value={alertType}
            defaultValue={defaultValue}
            onChange={(evt) =>
                actions.handlerSelectCheckbox(evt, setAlertType, setFormChanged, setIsFormRequired, isFormRequired,'notification_type')
            }
        >
            {createTypeAlert.map(({ title, label, icon, value }) => {
                return (
                    <div key={value} className={style['container']}>
                        <div className={style['row']}>
                            <div className={style['big_column']}>
                                <div className={style['display-flex']}>
                                    <Radio
                                        defaultValue={defaultValue}
                                        value={value}
                                        className={style['checkbox-round']}
                                        name="notification_type"
                                    >
                                        <span className={style['title']}>{title}</span>
                                    </Radio>
                                </div>
                                <div className={style['content-text']}>{label}</div>
                            </div>
                            <div></div>
                            <div className={style['column']}>{icon}</div>
                        </div>
                    </div>
                );
            })}
        </RadioGroup>
    );
};

export default CheckboxAlert;
