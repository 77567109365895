// components
import { DrawerAlertTitle } from '../DrawerAlertTitle';
// rsuite
import { Checkbox, CheckboxGroup, Form } from 'rsuite';
// utils
import { auxiliaries, checks } from './utils';
// style
import style from './DrawerUbicationAlert.module.css';

const DrawerUbicationAlert = ({
    setAlertUbication,
    alertUbication,
    defaultValue,
    setFormChanged,
    setIsFormRequired,
    isFormRequired,
}) => {
    // aux
    const actions = auxiliaries();

    return (
        <Form.Group controlId="chebox-group">
            <Form.ControlLabel>
                <DrawerAlertTitle title="Ubicación de alerta" />
            </Form.ControlLabel>
            <CheckboxGroup name="checkbox-group" className="checkbox-admin" defaultValue={defaultValue}>
                {checks.map(({ label, value }) => {
                    return (
                        <Checkbox
                            onChange={(evt, checked) =>
                                actions.handlerSelectUbication(
                                    evt,
                                    setAlertUbication,
                                    alertUbication,
                                    checked,
                                    setFormChanged,
                                    setIsFormRequired,
                                    isFormRequired,
                                    'notification_location'
                                )
                            }
                            value={value}
                            className={style['checkbox']}
                        >
                            {label}
                        </Checkbox>
                    );
                })}
            </CheckboxGroup>
        </Form.Group>
    );
};

export default DrawerUbicationAlert;
