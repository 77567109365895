import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { rem, rgba } from "polished";
const useStyles = makeStyles<Theme, { colors?: any }>(({ palette }) => {
  return {
    posText: {
      color: ({ colors }) => colors.S[800],
      fontSize: rem("14px"),
    },

    tableRow: {
      height: 56,
      paddingTop: 8,
      paddingBottom: 8,
      border: 0,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: ({ colors }) => "#EEEEEE !important",
      },
      "&.MuiTableRow-root.Mui-selected": {
        backgroundColor: ({ colors }) => `#E3E3E3 !important`,
        height: 56,
      },
      "&.MuiTableRow-root.Mui-selected:hover": {
        backgroundColor: ({ colors }) => "#EEEEEE !important",
      },
    },
    tableCell: {
      border: 0,
      padding: 0,
      borderBottom: "none",

      "&.MuiTableCell-root": {
        borderBottom: 0,
        padding: 0,
      },
    },
    chipStyle: {
      padding: 0,
      maxWidth:"260px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      backgroundColor: "transparent",
      color: "#8B8B8B",
      font: "normal normal normal 10px/14px Source Sans Pro;",
      justifyContent: "flex-start",
      cursor: "pointer",

      "& span": {
        padding: 0,
      },
    },
    chipStyleName: {
      padding: 0,
      overflow: "hidden",
      maxWidth:"260px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      backgroundColor: "transparent",
      color: "#333333",
      font: "normal normal normal 16px/22px Source Sans Pro",
      justifyContent: "flex-start",
      cursor: "pointer",
      "& span": {
        padding: 0,
      },
    },
    chipStyleHave: {
      padding: 0,
      width: 300,
      textTransform: "none",
      backgroundColor: "transparent",
      color: "#8B8B8B",
      font: "normal normal normal 10px/14px Source Sans Pro;",
      justifyContent: "flex-start",
      cursor: "pointer",

      "& span": {
        padding: 0,
      },
    },
    divider: {
      borderColor: ({ colors }) => colors.S[400],
      borderTop: 1,
      padding: 0,
      margin: 4,
      cursor: "pointer",
    },
    textColor: {
      color: `${palette.Ra}`,
    },
    paddingItem: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    chipConectar: {
      "&.MuiChip-root": {
        display: "flex",
        marginRight: 16,
        marginLeft: 24,
        alignItems: "center",
        justifyContent: "center",
        height: "20px",
        textTransform: "none",
        backgroundColor: rgba(palette.neutral[800], 0.15),
        color: palette.neutral[800],
        font: "normal normal normal 12px/17px Source Sans Pro",
        cursor: "pointer",
      },
    },
    scrollBar: {
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
    rowContainer_PointOfSale: {
      display: "flex",
      minHeight: 56,
      maxHeight: 76,
      flexDirection: "row",
      padding: "8px 0px 8px 16px",
    },
    container_DropdownPost: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      left: "calc(50% - 170px)",
    },
  };
});
export default useStyles;
