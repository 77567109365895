import { Timestamp, Identity, User, DrugManufacturer } from "common/types";

export enum ApiCredentialStatus {
  Pending = "pending",
  Enabled = "enabled",
}

export interface ApiCredential extends Timestamp, Identity<number> {
  username: string;
  password: string;
  decoded_password: boolean;
  client_identifier: string;
  user: User;
  drug_manufacturer: DrugManufacturer;
  status: ApiCredentialStatus;
}

export default ApiCredential;
