import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const updateQueryString = (
  searchTerms,
  dateTerms,
  listDrugTerms,
  listTagTerms
) => {
  let queryParams = {};
  if (searchTerms !== '') {
    queryParams['filters[search]'] = searchTerms;
  }
  if (dateTerms.from !== '' && dateTerms.to !== '') {
    queryParams['filters[from_date]'] = dateTerms.from;
    queryParams['filters[to_date]'] = dateTerms.to;
  }

  let drugQuery = '';
  let tagQuery = '';

  if (listTagTerms.length > 0) {
    tagQuery = listTagTerms
      .map((tagId) => `filters[point_of_sale_ids][]=${tagId}`)
      .join('&');
  }

  if (listDrugTerms.length > 0) {
    drugQuery = listDrugTerms
      .map((drugId) => `filters[drug_manufacturer_ids][]=${drugId}`)
      .join('&');
  }

  const fullQuery = [tagQuery, drugQuery]
    .filter((query) => query !== '')
    .join('&');
  return (
    new URLSearchParams(queryParams).toString() +
    (fullQuery ? '&' + fullQuery : '')
  );
};

export const parseDate = (originalDate): any => {
  const dateInUTC = zonedTimeToUtc(
    originalDate,
    'America/Argentina/Buenos_Aires'
  );
  const dateParsed = utcToZonedTime(
    dateInUTC,
    'America/Argentina/Buenos_Aires'
  );
  return format(dateParsed, 'yyyy-MM-dd');
};

export const formatDateToValueDatePicker = (initialDate, endDate) => {
  var from = new Date(initialDate);

  var to = new Date(endDate);

  return {
    from,
    to,
  };
  
};
