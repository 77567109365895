import { useRouter } from 'next/router';
// ErrorsAlertContext.js
import { getEnabledDrugManufacturers } from 'api';
import { createContext, useContext, useState, useEffect } from 'react';
import { EErrorCode } from 'utils/enums/comparativeAlerts/comparativeAlerts';
import { useDrugManufacturersByOrderId, useUser } from 'utils/hooks';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';

const CredentialErrorsContext = createContext(null);

export const CredentialErrorProvider = ({ children }) => {
    // next
    const router = useRouter();
    // context
    const { user } = useUser({});
    const EX6017 = user?.EX6017;

    // state
    const [drugmanufacturersEnabled, setDrugmanufacturersEnabled] = useState(null);
    const [isFetchingUpdate, setIsFetchingUpdate] = useState(false);

    // credenciales que se lograron actualizar
    const [updatedCredentials, setUpdatedCredentials] = useState<any | number>([]);

    // Se cierra el drawer y se actualizo
    const [closeDrawerAndReload, setCloseDrawerAndReload] = useState(false);

    // new swr by drugManufacturers
    const { drugManufacturersResponse, mutateDrugManufacturers } = useDrugManufacturersByOrderId(
        router?.query?.orderId,
    );

    const validErrorCodes = new Set([
        EErrorCode.UNKNOWN_ERROR,
        EErrorCode.WRONG_USERNAME_PASSWORD,
        EErrorCode.OPERATION_FORBIDDEN,
        EErrorCode.CREDIT_LIMIT_REACHED,
        EErrorCode.REASON_PROVIDED,
        EErrorCode.EXPIRED_PASSWORD,
        EErrorCode.MISSING_ACCOUNT,
        EErrorCode.MISSING_DISPATCH_ADDRESS,
        EErrorCode.MISSING_FORMAT
    ]);

    // deprecated
    const fetchDrugManufacturers = async (orderId) => {
        if (false) {
            let filterByTypeId: string;
            if (EX6017) {
                filterByTypeId = `${EDrugManufacturerTypeId.offline},${EDrugManufacturerTypeId.online}`;
            }
            try {
                const { data: response } = await getEnabledDrugManufacturers(orderId, filterByTypeId);
                setDrugmanufacturersEnabled(response?.data);
            } catch (ex) {
                console.log(ex.message);
            }
        }
    };

    // new effect
    useEffect(() => {
        if (drugManufacturersResponse?.data) {
            setDrugmanufacturersEnabled(drugManufacturersResponse?.data);
        }
    }, [drugManufacturersResponse]);

    // new effect
    useEffect(() => {
        if (!!isFetchingUpdate) {
            mutateDrugManufacturers();
        }
    }, [isFetchingUpdate]);

    const extractedDataError = drugmanufacturersEnabled
        ?.filter(({ error_type }) => error_type && error_type.length !== 0 && validErrorCodes.has(error_type.id))
        .map((item) => ({ ...item }));

    const extractedUnknownError = drugmanufacturersEnabled
        ?.filter(({ error_type }) => error_type?.length !== 0 && error_type?.id === 1)
        .map((item) => {
            return {
                ...item,
            };
        });

    const extractedCredentialErrors = extractedDataError?.filter((elem) => elem?.error_type?.id === 2);

    const extractedCreditError = extractedDataError?.filter((elem) => elem?.error_type?.id === 4);

    const extractedMissingFormat = extractedDataError?.filter((elem) => elem?.error_type?.id === EErrorCode.MISSING_FORMAT);

    const quantityCredentialsWithErrors = extractedCredentialErrors?.length;

    const quantityCreditWithErrors = extractedCreditError?.length;

    return (
        <CredentialErrorsContext.Provider
            value={{
                drugmanufacturersEnabled,
                extractedCredentialErrors,
                extractedUnknownError,
                quantityCredentialsWithErrors,
                extractedDataError,
                setIsFetchingUpdate,
                isFetchingUpdate,
                fetchDrugManufacturers,
                updatedCredentials,
                setUpdatedCredentials,
                closeDrawerAndReload,
                setCloseDrawerAndReload,
                quantityCreditWithErrors,
                mutateDrugManufacturers,
                extractedMissingFormat
            }}
        >
            {children}
        </CredentialErrorsContext.Provider>
    );
};

export const useCredentialErrors = () => {
    return useContext(CredentialErrorsContext);
};
