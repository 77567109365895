import React, { useState } from 'react';
import Link from 'next/link';
import { ListItem, Drawer } from '@material-ui/core';
import { useRouter } from 'next/router';
import useStyles from './MainSidebar.styles';
import { useUser } from 'utils/hooks';
import { Tooltip, Whisper, Button } from 'rsuite';
import { Ellipse } from '@components/icons';
export interface SidebarIconProps {
    active: boolean;
    color: string;
    SvgComponent: React.FC<React.SVGProps<SVGSVGElement>>;
}

export interface Route {
    label: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    activeIcon: React.FC<React.SVGProps<SVGSVGElement>>;
    path: string;
}

export interface MainSidebarProps {
    routes: Array<Route>;
    role: string;
    openDrawer?: boolean;
    setOpenDrawer?: (state: boolean) => void;
    alerts?: any;
    lastElement?: any;
    setAlertId?: (v: string | number) => void;
}

function SidebarIcon({ active, SvgComponent, color }: SidebarIconProps) {
    return (
        <SvgComponent
            style={{ opacity: active ? 1 : 0.7, color: 'white' }}
            fill="currentColor"
            width={28}
            height={28}
        />
    );
}

function MainSidebar({ routes, role, setOpenDrawer, alerts, lastElement, setAlertId }: MainSidebarProps) {
    const router = useRouter();
    const [fcVisible, setFcVisible] = useState(false);
    const [hover, setHover] = useState('');
    const classes = useStyles({ active: fcVisible });
    const { user } = useUser({});

    const loadHubspotChat = () => {
        // @ts-ignore
        window.HubSpotConversations?.widget.load();

        return () => {
            // @ts-ignore
            window.HubSpotConversations.widget.remove();
            // @ts-ignore
            window.hsConversationsOnReady = [];
        };
    };

    React.useEffect(() => {
        role !== 'admin' && loadHubspotChat();
    }, [role]);

    return (
        <>
            <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }}>
                <div className={classes.toolbarSeparator} />
                {routes?.map((route) => {
                    const { activeIcon, icon, label, path } = route;
                    const isActive = path === router.pathname;

                    return (label === 'Enviar una alerta' && !user?.can_create_notifications) ? (
                        <></>
                    ) : (
                        <Whisper
                            trigger="hover"
                            placement={'right'}
                            controlId={`control-id-right`}
                            speaker={<Tooltip style={{ fontSize: 14 }}>{label}</Tooltip>}
                        >
                            <Link
                                onClick={() => {
                                    // Check if EX4990 is true and label is 'Enviar una alerta'
                                    if (
                                        !!user?.can_create_notifications &&
                                        user?.EX4990 &&
                                        label === 'Enviar una alerta'
                                    ) {
                                        // Call setOpenDrawer and setAlertId functions
                                        if (!!lastElement && !!lastElement?.id) {
                                            setAlertId(lastElement?.id);
                                            setOpenDrawer(true);
                                        } else {
                                            setOpenDrawer(true);
                                        }
                                    }

                                    localStorage.removeItem('clientId');
                                    localStorage.removeItem('typeId');
                                }}
                                key={label}
                                href={path === '/my-orders' ? `/${user?.client?.id}/${path}` : path}
                                style={{ color: 'transparent' }}
                            >
                                <ListItem
                                    className={classes.listItem}
                                    button
                                    onMouseEnter={() => setHover(path)}
                                    onMouseLeave={() => setHover('')}
                                >
                                    {/* Agregar Validacion para el punto rojo */}
                                    {alerts?.length > 0 &&
                                        label === 'Enviar una alerta' &&
                                        !!user?.can_create_notifications &&
                                        user?.EX4990 && <Ellipse />}

                                    <SidebarIcon
                                        color="white"
                                        SvgComponent={isActive || hover === path ? activeIcon : icon}
                                        active={isActive}
                                    />
                                </ListItem>
                            </Link>
                        </Whisper>
                    );
                })}
            </Drawer>
        </>
    );
}

export default MainSidebar;
