import React, { createContext, useContext, useEffect, useState } from 'react';
import { CreateOrderInterface } from './create-order-interface';
import { Status, useAsync, useUser } from 'utils/hooks';
import { cancel, continueToEfficientPurchasePromise, createOrderByCartId, uploadSiafFile } from 'api';
import { usePointOfSale } from 'context/point-of-sale-context';
import { useModalDontHaveCredentials } from 'context/modals/modal-dont-have-credentials';
import { EX4380, EX4744, EX6002 } from 'config/flags';

const CreateOrderContext = createContext<Omit<CreateOrderInterface, 'children'>>(null);

export const CreateOrderProvider = ({ children }) => {
    // states
    const [errorUploader, setErrorUploader] = useState<boolean>(false);
    const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(false);
    const [hideBreadcrum, setHideBreadcrum] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [blockerFormat, setBlockerFormat] = useState(false);
    const [errors, setErrors] = useState(false);
    const [dataWithError, setDataWithError] = useState([]);
    const [formatFile, setFormatFile] = useState('');
    const [continueWithErrors, setContinueWithErrors] = useState(false);
    const [isLoadingFileWithErrors, setIsLoadingFileWithErrors] = useState(false);
    const [orderId, setOrderId] = useState(null);
    // context
    const { data: fileResults, status, error, run, setError } = useAsync({});
    const { pointOfSale } = usePointOfSale();
    const { setDontHaveCredentialsModal } = useModalDontHaveCredentials();
    const { user } = useUser({});

    const resetStore = () => {
        setErrorUploader(false);
        setErrorUploader(false);
        setHideBreadcrum(false);
        setIsFetching(false);
        setBlockerFormat(false);
        setErrors(false);
        setDataWithError([]);
    };
    // block format
    const handlerDrop = (e) => {
        e.preventDefault();

        const files = e.dataTransfer.files;

        for (let i = 0; i < files.length; i++) {
            const format = files[i].name.split('.').pop();

            if (
                format !== 'txt' ||
                format !== 'xls' ||
                format !== 'xlsx' ||
                format !== 'res' ||
                format !== 'fal_' ||
                format !== 'fal'
            ) {
                setBlockerFormat(true);
                setErrors(false);
                setIsFetching(!isFetching);
                return;
            }
            setFormatFile(files[i].name);
            setBlockerFormat(false);

            return;
        }
    };

    const handleValidation = (file: any): boolean => {
        const maxSizeInBytes = 1024 * 1024;

        const format = file.type.split('/')[1];
        const fileType =
            file.name.split('.')[1] === 'res' ||
            file.name.split('.')[1] === 'fal_' ||
            file.name.split('.')[1] === 'fal' ||
            file.name.split('.')[1] === 'csv';

        if (EX6002) {
            if (file.size > maxSizeInBytes) {
                setBlockerFormat(true);
                return false;
            }
            setFormatFile(file.name);
            setBlockerFormat(false);
            return true;
        } else {
            if (
                !!EX4380 &&
                format !== 'plain' &&
                format !== 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
                format !== 'vnd.ms-excel' &&
                !fileType
            ) {
                setBlockerFormat(true);
                return false;
            }
            if (!EX4380 && format !== 'plain' && !fileType) {
                setBlockerFormat(true);
                return false;
            }
            if (file.size > maxSizeInBytes) {
                setBlockerFormat(true);
                return false;
            }
            setFormatFile(file.name);
            setBlockerFormat(false);
            return true;
        }
    };

    // upload txt
    const handleUpload = (file) => {
        setTimeout(() => {
            if (handleEventInitComparation()) {
                if (handleValidation(file)) {
                    setIsFetching(!isFetching);
                    run(uploadSiafFile({ file, point_of_sale_id: pointOfSale?.id }));

                    setBlockerFormat(false);
                    return;
                }
            } else {
                setIsFetching(false);
            }
        }, 1000);
    };

    const handlerUploadWithErrors = (file) => {
        if (continueWithErrors) {
            setIsLoadingFileWithErrors(true);
            run(
                continueToEfficientPurchasePromise({
                    file,
                    point_of_sale_id: pointOfSale?.id,
                    order_id: orderId,
                }),
            );

            return;
        }
        if (!blockerFormat || !errors) {
            setIsFetching(!isFetching);
            run(uploadSiafFile({ file, point_of_sale_id: pointOfSale?.id }));

            setBlockerFormat(false);
            return;
        }
    };

    // validation when run cart and dont have drugmanufacturers
    const handleEventInitComparation = () => {
        if (EX4744) {
            const pdv: any = user?.pointsOfSale.find((pdvs) => pdvs?.id === pointOfSale?.id);
            if (!!pdv?.canDispatchPriceRequest) {
                setDontHaveCredentialsModal(false);
                return true;
            } else {
                setDontHaveCredentialsModal(true);
                return false;
            }
        } else {
            const pdv = user?.pointsOfSale.find((pdvs) => pdvs?.id === pointOfSale?.id);
            if (pdv?.availableDrugManufacturers >= 2) {
                setDontHaveCredentialsModal(false);
                return true;
            } else {
                setDontHaveCredentialsModal(true);
                return false;
            }
        }
    };

    // run create order by cart
    const runCreateOrderByCart = (cart_id) => {
        setIsFetching(!isFetching);
        setTimeout(() => {
            if (handleEventInitComparation()) {
                return run(
                    createOrderByCartId({
                        cart_id: cart_id,
                        point_of_sale_id: pointOfSale?.id,
                    }),
                );
            } else {
                setIsFetching(false);
            }
        }, 1000);
    };

    // listen resolved promise
    useEffect(() => {
        switch (status) {
            case Status.Resolved:
                if (fileResults?.data?.data?.errors && fileResults?.status === 200) {                    
                    setDataWithError(fileResults?.data?.data);
                    setErrors(true);
                    setShowLoadingScreen(false);
                    setErrors(true);
                    setIsFetching(false);
                    setContinueWithErrors(true);
                    setIsLoadingFileWithErrors(false);
                    setOrderId(fileResults?.data?.data?.order?.id);

                    return;
                } else {
                    if (!!fileResults?.data?.data?.order && fileResults?.status === 200) {
                        setIsFetching(!isFetching);
                        setShowLoadingScreen(true);
                    } else {
                        // PDF
                        setIsFetching(!isFetching);
                    }
                }
                break;
            case Status.Rejected:
                setIsFetching(!isFetching);
                // if (false) captureException(error);
                break;
        }
    }, [status]);

    const handleSwitchViewsCancelOrder = () => {
        resetStore();
        setShowLoadingScreen(false);
        if (orderId) {
            cancel(orderId);
        }
    };
    return (
        <CreateOrderContext.Provider
            value={{
                errorUploader,
                setErrorUploader,
                showLoadingScreen,
                setShowLoadingScreen,
                handleUpload,
                hideBreadcrum,
                setHideBreadcrum,
                fileResults,
                status,
                runCreateOrderByCart,
                isFetching,
                setIsFetching,
                handlerDrop,
                blockerFormat,
                setBlockerFormat,
                errors,
                dataWithError,
                setErrors,
                resetStore,
                formatFile,
                handlerUploadWithErrors,
                handleSwitchViewsCancelOrder,
                isLoadingFileWithErrors,
                setDataWithError
            }}
        >
            {children}
        </CreateOrderContext.Provider>
    );
};

export function useCreateOrder() {
    const context = useContext(CreateOrderContext);
    if (context === undefined) {
        throw new Error('usePos must be used within a createOrderProvider');
    }
    return context;
}
