export const auxiliary = () => {
    /**
     * Auxiliary function for handling links.
     *
     * @param {any} evt - Event to update the link.
     * @param {string} key - Key to identify the link to update.
     * @param {function} setLinks - Function to set the links.
     * @param {function} setFormChanged - Function to set form changed state.
     */
    const handlerLinks = (evt, key, setLinks, setFormChanged) => {
        setLinks((prevState) => ({
            ...prevState,
            [key]: evt,
        }));
        setFormChanged(true);
        
    };

    return {
        handlerLinks,
    };
};
