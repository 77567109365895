import { DrugManufacturersId } from 'utils/interfaces';

export const drugManufacturersThatOnlyNeedClientId = [DrugManufacturersId.FarmaciasABC];

export const drugManufacturersThatNeedClientIdAndCatalog = [DrugManufacturersId.AlmacenDeDrogas];

export const drugManufacturersThatNeedClientType = [DrugManufacturersId.Coopidrogas];

export const drugManufacturersThatNeedCommonAndClientId = [
    DrugManufacturersId.Coopidrogas,
    DrugManufacturersId.MedicinasRosario,
    DrugManufacturersId.Nadro,
    DrugManufacturersId.Vicma,
];

export const drugManufacturersWithoutUserAndPass = [
    DrugManufacturersId.AlmacenDeDrogas,
    DrugManufacturersId.FarmaciasABC,
];

export const drugManufacturersWithPaymentMethod = [
    DrugManufacturersId.FarmaceuticosTenorioSAdeCV,
    DrugManufacturersId.PacificPharmaGroup,
];

export const drugManufacturersWithAvailableBranches = [
    DrugManufacturersId.Solfarma,
    DrugManufacturersId.CruzVerde,
    DrugManufacturersId.FarmaMX,
    DrugManufacturersId.Calderon,
    DrugManufacturersId.CofarmenMendoza,
    DrugManufacturersId.CofarmenSanJuan,
];

export const drugManufacturersWithDispatchAddresses = [
    DrugManufacturersId.Farmamigo,
    DrugManufacturersId.MedicinasRosario,
    DrugManufacturersId.DistribucionesAxaSAS,
    DrugManufacturersId.DrogueriaSur,
];

const drugManufacturersWithDeposit = [
    DrugManufacturersId.MedicinasRosario,
    DrugManufacturersId.Vicma,
    DrugManufacturersId.FarmaMX,
];

export const drugManufacturersSplitClientIdentifier = [DrugManufacturersId.FarmaMX, DrugManufacturersId.Calderon];

export const requiresPaymentMethod = (drugManufacturerId: number) =>
    drugManufacturersWithPaymentMethod?.includes(drugManufacturerId);

export const requiresAvailableBranches = (drugManufacturerId: number) =>
    drugManufacturersWithAvailableBranches?.includes(drugManufacturerId);

export const requiresDispatchAddresses = (drugManufacturerId: number) =>
    drugManufacturersWithDispatchAddresses?.includes(drugManufacturerId);

export const requiresUserAndPass = (drugManufacturerId: number) =>
    !drugManufacturersWithoutUserAndPass?.includes(drugManufacturerId);

export const requiresCatalog = (drugManufacturerId: number) =>
    drugManufacturersThatNeedClientIdAndCatalog?.includes(drugManufacturerId);

export const requiresClientType = (drugManufacturerId: number) =>
    drugManufacturersThatNeedClientType?.includes(drugManufacturerId);

export const requiresDeposit = (drugManufacturerId: number) =>
    drugManufacturersWithDeposit?.includes(drugManufacturerId);

export const requiresSplitClientIdentifier = (drugManufacturerId: number) =>
    drugManufacturersSplitClientIdentifier?.includes(drugManufacturerId);
