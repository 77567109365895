import { Theme } from "@mui/material";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles<Theme, { offSet }>(() => ({
  searchBar: {
    "& .MuiOutlinedInput-input": {
      padding: 0,
      fontFamily: "Source Sans Pro"
    },
    "&:focus": {
      outline: "none",
    },
    backgroundColor: "white",
    border: "none",
    borderRadius: 4,
    height: 56,
    fontSize: 16,
    width:"100%",
    padding: "0 10px 0 15px",
    position: "relative",
    display: "flex",
  },
  searchResult: {
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    zIndex: 9999,
    fontSize: 16,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    paddingTop: 8,
    marginTop: "-1px",
    marginLeft: 1.1,
    marginRight: 1.1,
  },
  containerRow: {
    overflow: "hidden",
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
    alignItems: "center",
    padding: 12,
    cursor: "pointer",
    display: "grid !important",
    gridTemplateColumns: "2rem auto 8.2rem",
    "& > div:nth-child(2)" : {
      minWidth: "min-content",
      paddingRight: 55,
      paddingLeft: 12,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "pre-wrap",
      maxHeight: "max-content",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    }
  },
  lastRow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
    alignItems: "center",
    padding: 12,
    paddingLeft: 15,
    cursor: "pointer"
  },
  showMoreResults: {
    textDecoration: "underline",
    color: "#5AB1BC",
    margin: 0,
    cursor: "pointer",
  },
  noResultsFound: {
    width: "100%",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export default useStyles;
