export const auxiliaries = () => {
    /**
     * Handles selection of alert location.
     *
     * @function
     * @param {string} evt - Event triggering the selection.
     * @param {Function} setAlertUbication - Setter function to update the alert location state.
     * @param {Array<string>} alertUbication - Current list of selected alert locations.
     * @param {boolean} checked - Boolean indicating whether the location is checked or unchecked.
     * @param {Function} setFormChanged - Function to set form changed state.
     */
    const handlerSelectUbication = (
        evt,
        setAlertUbication,
        alertUbication,
        checked,
        setFormChanged,
        setIsFormRequired,
        isFormRequired,
        key
    ) => {
        setFormChanged(true);
        if (checked) {
            setAlertUbication([...alertUbication, evt]);
            if (!isFormRequired.includes(key)) {
                setIsFormRequired((prevState) => [...prevState, key]);
            }
        } else {
            setAlertUbication(alertUbication.filter((item) => item !== evt));
        }
    };

    return {
        handlerSelectUbication,
    };
};
