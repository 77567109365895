import { Close } from '@material-ui/icons';
import HintIcon from './HintIcon/HintIcon';
import HintWrapper from './HintWrapper/HintWrapper';
import style from './Hint.module.css';
import { IHintProps } from './interfaces/Hint.interface';
import useHint from './hooks/useHint';
import { useEffect, useState } from 'react';

const Hint = ({ message, type = 'info', actionButton }: IHintProps) => {
    const [showCloseIcon, setShowCloseIcon] = useState(true);
    const { showHint, setShowHint } = useHint();

    useEffect(() => {
        if (type === 'info') {
            setShowCloseIcon(false);
        } else {
            setShowCloseIcon(true);
        }
    }, [showHint, type]);

    return (
        showHint && (
            <HintWrapper type={type}>
                <div className={style['content']}>
                    <HintIcon type={type} />
                    <div className={style['text-container']}>
                        <span className={`${style['text-content']} ${style['description']}`}>{message}</span>
                    </div>
                </div>
                {showCloseIcon && (
                    <div className={style['close-container']}>
                        <Close
                            width={16}
                            height={16}
                            onClick={() => {
                                actionButton && actionButton?.action();
                                setShowHint(!showHint);
                            }}
                        />
                    </div>
                )}
            </HintWrapper>
        )
    );
};

export default Hint;
