import { createContext, useContext, useState } from 'react';
const UrlContext = createContext(null);
export const UrlProvider = ({ children }) => {
  const [urlPrev, setUrlPrev] = useState('');
  const savePrevUrl = (url) => {
    setUrlPrev(url);
  };
  return (
    <UrlContext.Provider value={{ urlPrev, savePrevUrl }}>
      {children}
    </UrlContext.Provider>
  );
};
export const useUrlContext = () => useContext(UrlContext);