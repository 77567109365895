import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import { Box, CircularProgress, Grid, TextField } from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import { EX4529 } from 'config/flags';
import { useProductUpdateQuantity } from 'context/update-quantity-product';
import { useRouter } from 'next/router';
import { rem } from 'polished';
import { FC, useEffect, useState } from 'react';
import { Loader } from 'rsuite';
import { useUser } from 'utils/hooks';
import useStyles from './NewCounterBox.styles';

interface NewCounterBoxProps {
    item: any;
    quantity: number;
    handleOperation: (typeOperation: string, item?: any, quantity?: number, idx?: number) => any;
    disabled: boolean;
    isInCart: boolean;
    loading: boolean;
    setLoading: (e: boolean) => void;
    isLoadingSearch?: boolean;
    styleContainer?: any;
    styleBoxLeft?: any;
    styleBoxRight?: any;
    styleBoxInput?: any;
    widthButton?: string;
    heightButton?: string;
    buttonGray?: boolean;
    idx?: number;
    snackbar?: any;
    index?: number;
    activeRowClick?: number;
    isFetching?: boolean;
    productCant?: any;
    currentQuantity?: number;
}

const NewCounterBox: FC<NewCounterBoxProps> = ({
    item,
    quantity,
    handleOperation,
    disabled,
    isInCart,
    loading,
    setLoading,
    isLoadingSearch,
    styleContainer,
    styleBoxLeft,
    styleBoxRight,
    styleBoxInput,
    widthButton,
    heightButton,
    buttonGray,
    idx,
    snackbar,
    isFetching,
    productCant,
}) => {
    const classes = useStyles({ disabled, buttonGray });
    const router = useRouter();
    const [valueCounter, setValue] = useState<number | string>(quantity);
    const [auxiliarValueCounter, setAuxiliarValueCounter] = useState<number | string>(quantity);
    const [oldQuantity, setOldQuantity] = useState<any>(0);
    const { user } = useUser({});

    const { updateSuccessful, setUpdateSuccessful } = useProductUpdateQuantity();
    const EX4903 = user?.EX4903;

    const checkUrl = (): boolean =>
        router.pathname.split('/')[4] === 'product-comparison' &&
        router.pathname.split('/')[2] === 'efficient-purchase';

    const checkStyle = (): boolean => checkUrl() && quantity === 1;

    useEffect(() => {
        setValue(quantity);
    }, [quantity, snackbar?.state?.visible]);

    useEffect(() => {
        if (!!isInCart) {
            setLoading(false);
        }
    }, [isInCart]);

    const renderG4tagHtml = () => {
        let value = '';
        if (router.pathname.split('/')[3] === 'search-results') {
            value = 'results';
        } else if (router.pathname.split('/')[3] === 'cart') {
            value = 'cart';
        } else if (router.pathname.split('/').at(-1) === 'product-comparison') {
            value = 'product-comparison';
        } else {
            value = 'search-bar';
        }
        return value;
    };

    const allowSubstractionZero = (newQuantity: number): boolean => {
        if (checkUrl() && newQuantity === 0) {
            return false;
        } else {
            return true;
        }
    };

    const handleKeys = (event: any) => {
        const upKey = 38;
        const downKey = 40;
        if (event.keyCode === upKey) {
            handleOperation('update', item, +quantity + 1, idx);
        } else if (event.keyCode === downKey) {
            if (+quantity - 1 === 0 && !!checkUrl()) {
                return;
            } else {
                handleOperation('update', item, +quantity - 1, idx);
            }
        }
    };

    // add class in svg remove G4tag
    const svgPathRemove = document.getElementById(`counter-box__subtract-${idx}-${renderG4tagHtml()}`);
    if (!!svgPathRemove && svgPathRemove.children)
        svgPathRemove.children[0].classList.add(`counter-box__subtract-${renderG4tagHtml()}`);
    // add class in svg add G4tag
    const svgPathAdd = document.getElementById(`counter-box__add-${idx}-${renderG4tagHtml()}`);
    if (!!svgPathAdd && svgPathAdd.children)
        svgPathAdd.children[0].classList.add(`counter-box__add-${renderG4tagHtml()}`);

    const validateValue = () => {
        if (!!productCant?.mincant || !!productCant?.maxcant) {
            let validatedQuantity = valueCounter;
            let quantityIsOk = false;
            const { mincant, maxcant } = productCant;
            if (!!mincant && !!maxcant) {
                if (valueCounter > maxcant) {
                    validatedQuantity = maxcant;
                }
                if (valueCounter < mincant) {
                    validatedQuantity = mincant;
                }
                if (valueCounter >= mincant && valueCounter <= maxcant) {
                    validatedQuantity = valueCounter;
                }
                quantityIsOk = true;
            }
            if (!!mincant && !maxcant) {
                if (valueCounter < mincant) {
                    validatedQuantity = mincant;
                }
                if (valueCounter >= mincant) {
                    validatedQuantity = valueCounter;
                }
                quantityIsOk = true;
            }
            if (!!maxcant && !mincant) {
                if (valueCounter > maxcant) {
                    validatedQuantity = maxcant;
                }
                if (valueCounter <= maxcant) {
                    validatedQuantity = valueCounter;
                }
                quantityIsOk = true;
            }
            if (quantityIsOk) {
                setValue(validatedQuantity);
            }
        } else {
            setValue(valueCounter);
        }
    };
    useEffect(() => {
        if (updateSuccessful) {
            validateValue();
        }
    }, [updateSuccessful]);

    return (
        <Grid
            container
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            wrap={'nowrap'}
            className={classes.container}
            style={styleContainer}
        >
            {!!loading || !isInCart ? (
                <CustomButton
                    variant="contained"
                    color={'primary'}
                    background={'#1DCAD3'}
                    width={!!widthButton ? widthButton : '100%'}
                    minWidth={!!widthButton ? widthButton : '162px'}
                    height={!!heightButton ? heightButton : rem('42px')}
                    startIcon={
                        !loading ? (
                            <ShoppingCartRoundedIcon className={`button__add-product-${renderG4tagHtml()}`} />
                        ) : null
                    }
                    disableRipple
                    disabled={!!isLoadingSearch || disabled ? true : false}
                    onClick={() => {
                        setLoading(true);
                        handleOperation('add', item, idx);
                    }}
                >
                    <span className={`button__add-product-${renderG4tagHtml()}`}>
                        {!!loading ? (
                            <CircularProgress color="inherit" size={16} style={{ display: 'flex' }} />
                        ) : (
                            'Agregar'
                        )}
                    </span>
                </CustomButton>
            ) : (
                <>
                    <Grid
                        item
                        className={[
                            classes.counterBox,
                            classes.backgroundWhite,
                            !!checkStyle() && classes.customDisabled,
                            `counter-box__subtract-${renderG4tagHtml()}`,
                        ].join(' ')}
                        style={styleBoxLeft}
                        onClick={() =>
                            allowSubstractionZero(quantity - 1) && handleOperation('update', item, quantity - 1, idx)
                        }
                    >
                        <Box>
                            <RemoveIcon
                                id={`counter-box__subtract-${idx}-${renderG4tagHtml()}`}
                                className={`counter-box__sallowSubstractionZero(quantity - 1) && handleOperation('update', item, quantity - 1, idx)ubtract-${renderG4tagHtml()}`}
                            />
                        </Box>
                    </Grid>
                    <Grid item className={classes.numberBox} style={styleBoxInput}>
                        <Box>
                            {isFetching ? (
                                <Loader />
                            ) : (
                                <TextField
                                    className={classes.textField}
                                    InputProps={{
                                        classes: {
                                            input: classes.input,
                                        },
                                        disableUnderline: true,
                                    }}
                                    variant="filled"
                                    value={valueCounter}
                                    onKeyDown={(e: any) => {
                                        handleKeys(e);
                                    }}
                                    onFocus={() => setOldQuantity(valueCounter)}
                                    onChange={async (e: any) => {
                                        const regex = new RegExp('^[0-9]*$');
                                        const { value } = e.target;
                                        if (value.length > 0 && regex.test(value) && +value !== 0) {
                                            const number = parseInt(value, 10);

                                            // add flag to identify when the quantity is updated
                                            setUpdateSuccessful(false);

                                            if (EX4529) {
                                                if (number <= 999) {
                                                    setValue(number);
                                                } else {
                                                    return;
                                                }
                                            } else {
                                                setValue(number);
                                            }
                                        } else if (value.length === 0 || +value === 0) {
                                            setValue('');
                                        }
                                    }}
                                    onBlur={async (e: any) => {
                                        const { value } = e.target;
                                        let res = null;
                                        if (value.length === 0 || +value === 0) {
                                            setValue(auxiliarValueCounter);
                                            // el await es para esperar la respuesta del monto de credito, es necesario
                                            res = await handleOperation('update', item, +auxiliarValueCounter, idx);
                                        } else {
                                            res = await handleOperation('update', item, +valueCounter, idx);
                                            setAuxiliarValueCounter(valueCounter);
                                        }

                                        if (!res && !!EX4903) {
                                            setValue(oldQuantity);
                                            handleOperation('update', item, oldQuantity, idx);
                                        }
                                    }}
                                    disabled={disabled}
                                    inputProps={{
                                        maxLength: 6,
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        className={[
                            classes.counterBox,
                            classes.backgroundBlue,
                            `counter-box__add-${renderG4tagHtml()}`,
                        ].join(' ')}
                        onClick={async () => {
                            const res = await handleOperation('update', item, quantity + 1, idx);

                            if (!res && !!EX4903) {
                                setValue(quantity);
                                handleOperation('update', item, quantity, idx);
                            }
                            if (!EX4903) {
                                handleOperation('update', item, quantity + 1, idx);
                            }
                        }}
                        style={styleBoxRight}
                    >
                        <Box>
                            <AddIcon
                                id={`counter-box__add-${idx}-${renderG4tagHtml()}`}
                                className={`counter-box__add-${renderG4tagHtml()}`}
                            />
                        </Box>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default NewCounterBox;
