// ELIMINAR UN PRODUCTO POR ID
export const deteleProductCartById = (data: any[], code: number) => {
    const newArray = data.filter((item: any) => item.code !== code)
    return newArray;
};

// OBTENER UN PRODUCTO POR ID
export const getProductCartById = (data: any[], barcode: number) => {
    const product = data.filter((item: any) => item?.barcode === barcode)
    return product[0];
};

// VALIDAR SI EXISTE UN PRODUCTO POR ID
export const existsProductInCartById = (data: any[], barcode: number) => {
    const product = data.filter((item: any) => item?.barcode === barcode);
    if (!!product[0]) return true
    return false
};

// ACTUALIZAR CANTIDAD DE UN PRODUCTO POR ID
export const updateProductCartById = (data: any[], code: number, quantity: number) => {
    const newArray = data.filter((item: any) => item.code !== code)
    if (quantity <= 0) {
        return newArray;
    } else {
        const newState = data.map((item: any) => {
            if (item.code === code) {
                item.quantity = quantity
                return item
            } else {
                return item
            }
        });
        return newState;
    }
};