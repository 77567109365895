import { Theme } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles<Theme, { onClick?: unknown }>(({ palette }) => ({
  menuItem: {
    width: "100%",
    height: "32px",
    minHeight: "32px",
    padding: "0 16px",
    "&:hover": {
      backgroundColor: ({ onClick }) =>
        onClick ? palette.neutral[400] : undefined,
    },
    pointerEvents: ({ onClick }) => (onClick ? undefined : "none"),
  },
  menuItemSelected: {
    backgroundColor: palette.neutral["400"],
  },
  listItemText: {
    margin: 0,
  },
  listItemTextPrimary: {
    color: palette.neutral["900"],
    fontSize: "14px !important",  
  },
  divider: {
    margin: "8px 0",
    backgroundColor: palette.neutral["400"],
  },
  listItemIcon: {
    minWidth: 0,
    paddingRight: "8px",
    color: palette.neutral["900"],
  },
}));

export default useStyles;
