import React from 'react'
import { Button } from 'rsuite'
import style from './ButtonOutline.module.css'

interface ButtonOnlineInterface {
    icon?: any
    text?: any
    disabled?: boolean
    height: number | string
    width: number | string
    onClick?: any
    margin?: string
    display?: string
    gtagClass?: string
    loading?: boolean
}

const ButtonOutline = ({
    icon,
    text,
    disabled,
    height,
    width,
    margin,
    onClick,
    display,
    gtagClass,
    loading
}: ButtonOnlineInterface) => {
    return (
        <Button
            onClick={onClick}
            className={`${gtagClass ? gtagClass : ''} ${style['button-style']}`}
            disabled={disabled}
            style={{
                width: width,
                height: height,
                margin: margin,
                display: display,
            }}
            appearance="subtle"
            startIcon={icon ? icon : ''}
            loading={loading}
        >
            {text}
        </Button>
    )
}

export default ButtonOutline
