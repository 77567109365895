import { apiPharmaInstance } from "utils/axios";
import { ResponseType, Client } from "common/types";

export const getClients = async (): Promise<ResponseType<Client[]>> => {
  const response = await apiPharmaInstance.get("/api/clients");
  return response.data;
};

//post one Client

export const createClient = async (
  client: Client
): Promise<ResponseType<Client>> => {
  const response = await apiPharmaInstance.post("/api/clients", { ...client });
  return response.data;
};

// get one client

export const getClient = async (id: number): Promise<ResponseType<Client>> => {
  const response = await apiPharmaInstance.get(`/api/clients/${id}`);
  return response.data;
};

// update client

export const updateClient = async (
  client: Client
): Promise<ResponseType<Client>> => {
  const response = await apiPharmaInstance.patch(`/api/clients/${client.id}`, {
    ...client,
  });
  return response.data;
};

// delete client

export const deleteClient = async (
  id: number
): Promise<ResponseType<Client>> => {
  const response = await apiPharmaInstance.delete(`/api/clients/${id}`);
  return response.data;
};
