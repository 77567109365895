import React from "react";
import { ThemeContext } from "styled-components";
import { CustomCircularProgress } from "components";
import useStyles from "./styled";

export function Loading({ text }) {
  const theme = React.useContext(ThemeContext);
  const classes = useStyles({
    color: theme.colors.S,
  });
  return (
    <div className={classes.root}>
      <span>&nbsp;</span>
      <div>
        <CustomCircularProgress />
      </div>
      <span className={classes.text}>{text}</span>
    </div>
  );
}

export default Loading;
