// DrawerContext.js
import React, { createContext, useContext, useState } from 'react';

const DrawerContext = createContext(null);

export const DrawerProvider = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [ isSuccessAndHaveMoreOfTwoCredentials, setIsSuccessAndHaveMoreOfTwoCredentials ] = useState(false);
  


  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <DrawerContext.Provider value={{ isDrawerOpen, openDrawer, closeDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  return useContext(DrawerContext);
};