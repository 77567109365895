import React from "react";
import { Status } from "../interfaces/Button.interface";
import { btnStatus } from "../utils/classes";

type LoadingProps = {
    loading?: boolean;
    loadingText?: string;
}

type Props = {
    status?: Status;
    loading?: LoadingProps;
    children: React.ReactNode;
}

const ButtonText = ({status, loading, children} : Props) => {
    if (status && status !== 'initial' && btnStatus[status]?.icon) {
        return <span>{btnStatus[status]?.icon}</span>;
    }
    if (loading?.loading) {
        return <span>{loading.loadingText}</span>;
    }
    return (
        <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
            {children}
        </div>
    );
}

export default ButtonText;