// style
import style from './DrawerAlertTitle.module.css'
// interface
import { IDrawerAlertTitle } from './DrawerAlertTitle.interface';

const DrawerAlertTitle = ({ title }:IDrawerAlertTitle) => {
    return (
        <div>
            <p className={style['form-data']}>{title}</p>
        </div>
    );
};

export default DrawerAlertTitle;
