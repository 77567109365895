import { createContext, useState, useContext } from 'react';

// Crear el contexto
const RouteContext = createContext(null);

// Crear un proveedor para el contexto
export const RouteProvider = ({ children }) => {
    const [previousRoute, setPreviousRoute] = useState(null);

    return <RouteContext.Provider value={{ previousRoute, setPreviousRoute }}>{children}</RouteContext.Provider>;
};

// Hook para consumir el contexto
export const useRoute = () => useContext(RouteContext);
