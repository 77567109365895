import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import * as SS from "styled-system";

export interface BaseProps extends HTMLAttributes<HTMLDivElement> {}

export interface BoxProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "color">,
    SS.BackgroundProps,
    SS.ColorProps,
    SS.SpaceProps,
    SS.BorderProps,
    SS.DisplayProps,
    SS.SizeProps,
    SS.FlexboxProps,
    SS.PositionProps,
    SS.TypographyProps,
    SS.LayoutProps {}

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  ${SS.background}
  ${SS.color}
  ${SS.space}
  ${SS.border}
  ${SS.display}
  ${SS.size}
  ${SS.flexbox}
  ${SS.position}
  ${SS.typography}
  ${SS.layout}
`;
