import { apiPharmaInstance } from 'utils/axios';

// uso en la home para droguerias sugeridas y conectadas
export const getDrugManufacturersHome = async ({ id, typeId = '', location = 0 }) => {
    let url = `/api/drug-manufacturers?sorts=name:asc&filters[point_of_sale_id]=${id}&includes=credentials&rows=1000`;
    if (typeId) {
        url += `&filters[type_id]=${typeId}`;
    }
    if (location) {
        url += `&filters[with_location]=${location}`;
    }

    try {
        return apiPharmaInstance
            .get(url)
            .then((res) => res.data.data)
            .catch((e) => console.error(e));
    } catch (ex) {
        console.error(ex.message);
    }
};
