import GroupIcon from '@material-ui/icons/Group';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { Notifications } from 'components/icons';
import { OrdersIcon, OrdersSolidIcon } from 'components/icons/my-orders';
import { NewCartIcon, NewCartSolidIcon } from 'components/icons/shopping_cart';

let routes = {};
routes = {
    client: [
        {
            label: 'Compra eficiente',
            icon: NewCartIcon,
            activeIcon: NewCartSolidIcon,
            path: '/efficient-purchase',
        },
        {
            label: 'Mis pedidos',
            icon: OrdersIcon,
            activeIcon: OrdersSolidIcon,
            path: '/my-orders',
        },
    ],
    admin: [
        {
            label: 'Clientes',
            icon: GroupIcon,
            activeIcon: GroupOutlinedIcon,
            path: '/clients',
        },
        {
            label: 'Enviar una alerta',
            icon: Notifications,
            activeIcon: Notifications,
            path: '/clients',
        },
    ],
    owner: [
        {
            label: 'Compra eficiente',
            icon: NewCartIcon,
            activeIcon: NewCartSolidIcon,
            path: '/efficient-purchase',
        },
        {
            label: 'Mis pedidos',
            icon: OrdersIcon,
            activeIcon: OrdersSolidIcon,
            path: '/my-orders',
        },
    ],
    manager: [
        {
            label: 'Compra eficiente',
            icon: NewCartIcon,
            activeIcon: NewCartSolidIcon,
            path: '/efficient-purchase',
        },
        {
            label: 'Mis pedidos',
            icon: OrdersIcon,
            activeIcon: OrdersSolidIcon,
            path: '/my-orders',
        },
    ],
    buyer: [
        {
            label: 'Compra eficiente',
            icon: NewCartIcon,
            activeIcon: NewCartSolidIcon,
            path: '/efficient-purchase',
        },
        {
            label: 'Mis pedidos',
            icon: OrdersIcon,
            activeIcon: OrdersSolidIcon,
            path: '/my-orders',
        },
    ],
};

export default routes;
