import { useToaster } from "rsuite";
import Toast from '../Toast'
import { IToastProps } from "../interfaces/Toast.interface";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

const useToast = () => {
    const toaster = useToaster();
    const router = useRouter();
    const [toasterId, setToasterId] = useState<string | null>(null);
    
    const pushToaster = async (props : IToastProps) => {
        const {placement = 'bottomCenter', duration} = props;
        const message = <Toast {...props} />
        const toastId = await toaster.push(message, {
            placement,
            duration,
        })
        setToasterId(prevToasterId => {
            if (prevToasterId) {
                toaster.remove(prevToasterId)
            };
            return toastId
        });
    }

    const showToaster = (props : IToastProps) => {
        if (toasterId) {
            toaster.remove(toasterId)
            setToasterId(null);
            setTimeout(() => {
                pushToaster(props)
            }, 400)
        } else {
            pushToaster(props)
        }
    }

    const clearToaster = () => {
        toaster.clear();
        setToasterId(null);
    };

    const removeToaster = () => {
        if (toasterId) {
            toaster.remove(toasterId);
            setToasterId(null);
        }
    };

    useEffect(() => {
        router.events.on('hashChangeComplete', (e) => {
            console.log(e);
            toaster.clear();
        });
    }, [router])

    useEffect(() => {
        return () => {
            clearToaster();
        };
    }, []);


    return {showToaster, clearToaster, removeToaster, toaster}
}

export default useToast;