import React from "react";

export function useHover() {
  const [isHovered, setHovered] = React.useState<boolean>(false);
  const toggle = () => setHovered(!isHovered);
  const hoverProps = {
    onMouseEnter: toggle,
    onMouseLeave: toggle,
  };

  return {
    isHovered,
    hoverProps,
  };
}
