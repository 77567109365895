import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    isManualChecked: boolean;
    setIsManualChecked: (v: boolean) => void;
};

const defaultValues: ContextProps = {
    isManualChecked: false,
    setIsManualChecked: (v: boolean) => {},
};

type Props = {
    children: React.ReactNode;
};

const IsManualCheckedContext = createContext<ContextProps>(defaultValues);

export const IsManualCheckedProvider = ({ children }: Props) => {
    const [isManualChecked, setIsManualChecked] = useState<boolean>(defaultValues.isManualChecked);

    const value: ContextProps = useMemo(
        () => ({
            isManualChecked,
            setIsManualChecked,
        }),
        [isManualChecked, setIsManualChecked],
    );

    return <IsManualCheckedContext.Provider value={value}>{children}</IsManualCheckedContext.Provider>;
};

export const useIsManualCheckedContext = () => useContext(IsManualCheckedContext);
