import React from "react";
import {
  Menu as MuiMenu,
  MenuItem,
  MenuItemProps as MuiMenuItemProps,
  MenuProps as MuiMenuProps,
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import useStyles from "./Menu.styles";
import { shadows } from "styles/mixins";

export interface MenuProps extends MuiMenuProps {
  children: React.ReactNode;
  getContentAnchorEl?:any;
}

export interface MenuItemProps extends Omit<MuiMenuItemProps, "onClick"> {
  children?: React.ReactNode;
  onClick?: () => void;
}

function Menu({ children, ...props }: MenuProps) {
  const PaperStyle = {
    boxShadow: shadows[2],
  };

  return (
    <MuiMenu
      variant="menu"
      PaperProps={{
        style: PaperStyle,
      }}
      {...props}
    >
      {children}
    </MuiMenu>
  );
}

const Item = React.forwardRef(
  ({ onClick, children, icon, ...props }: any, ref: any) => {
    const classes = useStyles({ onClick });

    return (
      <MenuItem
        className={classes.menuItem}
        ref={ref}
        onClick={onClick}
        classes={{ selected: classes.menuItemSelected }}
        {...props}
      >
        {icon ? (
          <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText
          className={classes.listItemText}
          classes={{ primary: classes.listItemTextPrimary }}
        >
          {children}
        </ListItemText>
      </MenuItem>
    );
  }
);

function Divider({ ...props }: MuiDividerProps) {
  const classes = useStyles({});

  return <MuiDivider className={classes.divider} {...props} />;
}

Menu.Item = Item;
Menu.Divider = Divider;

export default Menu;
