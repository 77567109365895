import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
  },
  input: {
    backgroundColor: "#ffffff",
    width: "400px",
    height: "40px",
    borderColor: "#fff",
    "&.MuiOutlinedInput-root": {
       "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
    "&.Mui-focused": {
      backgroundColor: "#fff",
      color: "#000000",
    },
    "& fieldset": {
      borderColor: "#fff",
      "& :hover": {
        borderColor: "#fff",
      },
      "& :focused": {
        borderColor: "#fff",
      },
    },
  },
  arrow: {
    cursor:"pointer",
    marginRight: 16
  }
}));

export default useStyles;
