import { apiPharmaInstance } from "utils/axios";

export const getExpiredNotification = async () => {
    return await apiPharmaInstance.get(`/api/notifications`);
};

export const acknowledgeExpiredNotification = async (data: any) => {
    return await apiPharmaInstance.post(`/api/notifications/acknowledge`, data);
};

const DATA_FAKE = [
    {
        "notification_type_id": 1,
        "notification_name": "NEAR_EXPIRED",
        "drug_manufacturers": [
            {
                "id": 3,
                "name": "Disval",
                "url": "",
                "ca_list_id": 456
            }
        ]
    },
    {
        "notification_type_id": 2,
        "notification_name": "EXPIRED",
        "drug_manufacturers": [
            {
                "id": 3,
                "name": "Disval",
                "url": "",
                "ca_list_id": 456
            }
        ]
    }
]