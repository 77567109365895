import { createAlerts, updateAlerts, deleteAlerts, getAlerts } from 'api/alerts/alerts';

export const auxiliaryFunctions = () => {
    

    /**
     * Handles submission of alert data.
     * @async
     * @function
     * @param {Object} data - Data object containing alert information.
     * @returns {Promise<Object>} - Promise resolving to the response from creating the alert.
     */
    const handlerSubmit = async (data) => {
        try {
            const response = await createAlerts(data);
            return response;
        } catch (ex) {
            console.error(ex.message);
        }
    };

    /**
     * Handles updating an existing alert.
     * @async
     * @function
     * @param {Object} data - Data object containing updated alert information.
     * @returns {Promise<Object>} - Promise resolving to the response from updating the alert.
     */
    const handlerUpdate = async (data) => {
        
        try {
            const response = await updateAlerts(data);
            return response;
        } catch (ex) {
            console.error(ex.message);
        }
    };
    /**
     * Handles deletion of an alert.
     * @async
     * @function
     * @param {string} id - ID of the alert to be deleted.
     * @returns {Promise<Object>} - Promise resolving to the response from deleting the alert.
     */
    const handlerDelete = async (id) => {
        try {
            const response = await deleteAlerts(id);
            return response;
        } catch (ex) {
            console.error(ex.message);
            console.log(ex)
        }
    };

    /**
     * Constructs a data object for sending alert form data.
     * @function
     * @param {string} id - Alert id identification.
     * @param {string} country - Country for which the alert is relevant.
     * @param {Array<string>} clients - Array of client names affected by the alert.
     * @param {string} alertType - Type of alert notification.
     * @param {string} alertUbication - Location of the alert notification.
     * @param {Object} description - Object containing title and description of the alert.
     * @param {Object} links - Object containing address and description of related links.
     * @returns {Object} - Data object containing formatted alert information.
     */
    const sendFormData = (id,country, clients, alertType, alertUbication, description, links) => {
        return {
            id:id,
            countries: country,
            clients: clients,
            notification_type: alertType,
            notification_location: alertUbication,
            title: description?.title,
            description: description?.description,
            link_address: links?.address,
            link_text: links.description,
        };
    };

    /**
 * Constructs a data object for sending alert form data.
 * @function
 * @param {string} id - ID of the alert.
 * @param {string} country - Country for which the alert is relevant.
 * @param {Array<string>} clients - Array of client names affected by the alert.
 * @param {string} alertType - Type of alert notification.
 * @param {string} alertUbication - Location of the alert notification.
 * @param {Object} description - Object containing title and description of the alert.
 * @param {Object} links - Object containing address and description of related links.
 * @param {Object} updateItem - Data object containing original alert information.
 * @returns {Object} - Data object containing formatted alert information.
 */
    const updateFormData = (id, country, clients, alertType, alertUbication, description, links, updateItem) => {
        const formData : any = {};
        
        if (updateItem && updateItem.length > 0) {
            const originalData = updateItem[0]?.data;
    
            formData.id = id;
            // Manejar arreglo vacío para countries y clients en la actualización
            formData.countries = country !== undefined && country.length > 0 ? country : (country !== undefined ? [] : updateItem[0]?.countries || []);
            formData.clients = clients !== undefined && clients.length > 0 ? clients : (clients !== undefined ? [] : updateItem[0]?.clients || []);
            formData.notification_type = alertType !== null ? alertType : originalData.notification_type;
            formData.notification_location = alertUbication?.length > 0 ? alertUbication : originalData.notification_location;
            formData.title = description?.title || originalData.title;
            formData.description = description?.description || originalData.description;
            formData.link_address = links?.address || originalData.link_address;
            formData.link_text = links?.description || originalData.link_text;
        } else {
            // Si no hay datos para actualizar, devuelve los datos originales
            formData.id = id;
            formData.countries = country || [];
            formData.clients = clients || [];
            formData.notification_type = alertType;
            formData.notification_location = alertUbication;
            formData.title = description?.title;
            formData.description = description?.description;
            formData.link_address = links?.address;
            formData.link_text = links?.description;
        }
    
        return formData;
    };

    /**
     * Gets the last item matching the given ID from the records set.
     * @function
     * @param {Array<Object>} recordset - Set of alert records.
     * @param {string} id - ID of the last item to retrieve.
     * @returns {Object|null} - Last item matching the ID, or null if not found.
     */
    const getTheLastItem = (recordset, id) => {
        
        return recordset?.filter((elem) => elem.id === id);
    };

    return {
        //handlerFetchAlerts,
        handlerSubmit,
        handlerUpdate,
        handlerDelete,
        sendFormData,
        getTheLastItem,
        updateFormData
    };
};
