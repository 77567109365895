import { apiPharmaInstance } from "utils/axios";

export const recommendedProductsJob = async (point_of_sale_id) => {
    try {
        return await apiPharmaInstance.post(`/api/recommended-products/${point_of_sale_id}`);
    } catch (e: any) {
        console.error(e.message)
    };
};

export const getRecommendedProductsWithPagination = async (
    point_of_sale_id: number | string,
    page: number = 1,
) => {
    try {
        return await apiPharmaInstance.get(`api/recommended-products/${point_of_sale_id}?page=${page}`)
    } catch (e: any) {
        console.error(e.message)
    };
};