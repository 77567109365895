import React, { createContext, useContext, useEffect, useState } from 'react';

export interface Snackbar {
    showSnackbar: boolean;
    setter: () => void;
    isDiscard: boolean;
    handlePress: () => void;
    setterIsDiscard: () => void;
    setShowSnackbar: (v: boolean) => void;
};

const SnackbarContext = createContext<Omit<Snackbar, "children">>(
    null
);

export const Snackbar = ({ children }) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [isDiscard, setIsDiscard] = useState<boolean>(false);

    // SETTER STATE
    const setter = (): void => {
        setShowSnackbar(!showSnackbar)
    };

    // SETTER STATE
    const setterIsDiscard = (): void => setIsDiscard(false);

    // PRESS BUTTON DISCARD SHOW AND HIDDEN
    const handlePress = (): void => {
        // hidden snackbar and reset isDiscard
        setIsDiscard(true);
        setShowSnackbar(false);
    };

    // LISTEN WHEN IS TRUE 5 SECONDS HIDDEN
    useEffect(() => {
        if (showSnackbar) {
            const timeoutId = setTimeout(() => {
                // hidden snackbar
                return setter();
            }, 5000);
            // clear the timer if the component is unmounted or showSnackbar changes before the 5 seconds are up.
            return () => clearTimeout(timeoutId);
        };
    }, [showSnackbar]);

    return (
        <SnackbarContext.Provider
            value={{
                showSnackbar,
                setter,
                isDiscard,
                handlePress,
                setterIsDiscard,
                setShowSnackbar
            }}
        >
            {children}
        </SnackbarContext.Provider>
    );

}

export function NewUseSnackbar() {
    const context = useContext(SnackbarContext);
    if (context === undefined) {
        throw new Error("usePos must be used within a PosProvider");
    }
    return context;
}