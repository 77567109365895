import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    pagination: {
        marginTop: 20,
        display: "flex",
        alignItems: 'center',
        marginBottom: '10px',
        height: '50px'
    },
    paginationColorPDV: {
        "& .Mui-selected": {
            backgroundColor: "#1DCAD3 !important",
            border: 'none',
            color: "white",

        },
        "& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#1DCAD3 !important",
            color: "white",

        },
        "& .MuiPaginationItem-page.Mui-selected:hover": {
            backgroundColor: "#80E1E4 !important",
            color: "white",

        },

        "& .MuiPaginationItem-page.Mui-selected:focused": {
            backgroundColor: "#00B0BC !important",
            color: "white",

        },
        "& .MuiPaginationItem-page:hover": {
            backgroundColor: "#EEEEEE !important",
            color: "#646464",
            borderColor: '#646464'

        },
        "& .MuiPaginationItem-page": {
            color: "#646464",
            borderColor: '#646464'
        },
        "& .MuiPaginationItem-page:focused": {
            color: "#D1D1D1",
            borderColor: '#646464'
        }
    },
    inputStyles: {
        borderColor: "#1DCAD3",
        "& label.Mui-focused": {
            color: "#0171E6"
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#0171E6",
            }
        }

    },
    buttonClonPos: {
        "& .MuiTouchRipple-root span":{
            borderRadius: "0%",
        },
    }
}));
export default useStyles;
