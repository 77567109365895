import { TypeConnection } from 'utils/enums/InitialConfig';

export const validateTypeConnection = (nameType) => {
    let parsedType = 'scraper';

    if (nameType === TypeConnection.WEB_NEW) {
        parsedType = 'scraper_np';
    }

    return parsedType;
};
