import React from 'react';
import { Button } from 'rsuite';
import style from './ButtonSolid.module.css';
import { EX4207 } from 'config/flags';

interface IButtonSolid {
    icon?: any;
    text?: any;
    disabled?: boolean;
    height: number | string;
    width: number | string;
    onClick?: any;
    type?: 'button' | 'reset' | 'submit';
    margin?: string;
    display?: any;
    gtagClass?: string;
    cancelType?: boolean;
    detailType?: boolean;
}
const ButtonSolid = ({
    icon,
    text,
    disabled,
    height,
    width,
    onClick = () => {},
    type,
    margin,
    display,
    gtagClass,
    cancelType,
    detailType,
}: IButtonSolid) => {
    return (
        <Button
            type={type ? type : 'button'}
            onClick={onClick}
            className={`${gtagClass ? gtagClass : ''} ${
                cancelType ? style['button-style-cancel'] : 
                detailType ? style['button-style-detail'] :
                style['button-style']
            }`}
            disabled={disabled}
            style={{
                width: width,
                height: height,
                margin: margin,
                display: display,
            }}
            appearance="subtle"
            startIcon={icon ? icon : ''}
        >
            {text}
        </Button>
    );
};

export default ButtonSolid;
