import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    loadingProduct: boolean;
    setLoadingProduct: (v: boolean) => void;
};

const defaultValues: ContextProps = {
    loadingProduct: false,
    setLoadingProduct: (v: boolean) => {},
};

type Props = {
    children: React.ReactNode;
};

const LoadingProductContext = createContext<ContextProps>(defaultValues);

export const LoadingProductProvider = ({ children }: Props) => {
    const [loadingProduct, setLoadingProduct] = useState<boolean>(defaultValues.loadingProduct);

    const value: ContextProps = useMemo(
        () => ({
            loadingProduct,
            setLoadingProduct,
        }),
        [loadingProduct, setLoadingProduct],
    );

    return <LoadingProductContext.Provider value={value}>{children}</LoadingProductContext.Provider>;
};

export const useLoadingProductContext = () => useContext(LoadingProductContext);
