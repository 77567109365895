import React from "react";
import {
  makeStyles,
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipTrops,
  useTheme,
} from "@mui/material";
import useStyles from "./Tooltip.styles";

export interface TooltipProps extends Omit<MuiTooltipTrops, "title"> {
  backgroundColor?: string;
  labelColor?: string;
  textAlign?: string;
  label?: string | any;
  maxWidth?: string;
  minWidth?: string;
  margin?: string;
  arrow?: boolean;
  top?: string;
  fontSize?: string;
  maxHeight?: string;
  disable?: boolean;
  textCenter?:string;
}

function Tooltip({
  backgroundColor,
  children,
  label,
  labelColor,
  textAlign,
  maxWidth,
  minWidth,
  margin,
  top,
  fontSize,
  maxHeight,
  arrow = true,
  disable = false,
  textCenter,
  ...props
}: TooltipProps) {
  const { palette } = useTheme();

  const classes = useStyles()({
    backgroundColor: backgroundColor || "#04273F",
    labelColor,
    textAlign,
    maxWidth,
    minWidth,
    margin,
    maxHeight,
    top,
    fontSize,
    textCenter
  });

  const [arrowRef] = React.useState(null);


  return (
    <>
      {!disable ? (
        <MuiTooltip
          arrow={arrow}
          classes={classes}
          role="tooltip"
          title={<React.Fragment>{label}</React.Fragment>}
          {...props}
        >
          <div>{children}</div>
        </MuiTooltip>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
}

export default Tooltip;
