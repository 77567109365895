import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme, { colors?: any }>(() => {
  return {
    container_PostList: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "4px",
      opacity: 1,
      top: "60px",
      position: "fixed",
      color: "black",
      paddingTop: 8,
      paddingBottom: 8,
      minWidth: 328,
    },
    container_PostName: {
      marginLeft: 16,
      cursor: "pointer",
      width: "327px",
      height: "40px",
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 10,
      paddingBottom: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    iconStore: {
      width: 24,
    },
    namePos: {
      margin: 0,
      fontSize: 14,
      fontFamily: "Source Sans Pro",
    },
    referencePos: {
      margin: 0,
      fontSize: 12,
      color: "#ACAEC4",
      fontFamily: "Source Sans Pro",
    },
  };
});
export default useStyles;
