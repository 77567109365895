import axios from 'axios';
import Cookies from 'js-cookie';

const STATUS_CODE_REDIRECT_TO_LOGIN = [401];

const EX4797 = () => typeof window !== 'undefined' && JSON.parse(localStorage.getItem('redirectToLogin'));

const redirectToLogin = () => {
    if (EX4797()) {
        // Performs the redirect and replaces the current history entry
        window?.history?.replaceState(null, '', '/login');
        if (typeof window !== 'undefined') localStorage.removeItem('redirectToLogin');
        // Redirect to /login
        window?.location?.replace('/login');
    }
};

export const apiPharmaInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_PHARMA_HOST_URL,
});

apiPharmaInstance.interceptors.request.use(
    function (config) {
        const token = Cookies.get('token');
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

export const apiPharmaFetcher = (url) => apiPharmaInstance.get(url).then((res) => res.data);

export function objectToFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
}

apiPharmaInstance.interceptors.response.use(
    function (response) {
        const statusCode = response.status; //status code
        return response;
    },
    function (error) {
        if (error?.response) {
            const statusCode = error?.response?.status; //status code
            if (location?.pathname !== '/login' && STATUS_CODE_REDIRECT_TO_LOGIN.includes(statusCode) && EX4797()) {
                // redirect to the login
                redirectToLogin();
            }
            return Promise.reject(error);
        }
    },
);
