export const auxiliary = () => {
    /**
     * Transforms an array of client objects into an array of objects with 'label' and 'value' properties.
     * The 'label' property represents the client name, and the 'value' property represents the client ID.
     *
     * @param {Object[]} clients - An array of client objects containing 'name' and 'id' properties.
     * @returns {Object[]} An array of objects with 'label' and 'value' properties.
     */
    const recordsetClients = (clients) => {
        // Map through the array of client objects and create a new array of transformed objects
        return clients?.data
            .map(({ name, id }) => {
                return {
                    label: name, // 'label' property representing the client name
                    value: id, // 'value' property representing the client ID
                };
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label); // Sorting the clients alphabetically by name
            });
    };

    /**
     * Handles the change event for selecting/deselecting clients.
     *
     * @param {Object[]} value - The selected client values.
     * @param {Function} setClients - Function to update the client state.
     * @param {boolean[]} isFormRequired - Array indicating if each form field is required.
     * @param {Function} setIsFormRequired - Function to update the form required state.
     * @param {string} key - The key identifier for the field being modified.
     */
    const handleClientChange = (value, setClients, isFormRequired, setIsFormRequired, key) => {
        setClients(value);
        if (!isFormRequired.includes(key)) {
            setIsFormRequired((prevState) => [...prevState, key]);
        }
    };

    /**
     * Extracts an array of client IDs from a recordset array.
     *
     * @param {Object[]} recordset - An array of objects with 'label' and 'value' properties representing clients.
     * @returns {string[]} An array of client IDs extracted from the recordset.
     */
    const selectAllValues = (recordset) => recordset?.map((item) => item.value);

    /**
     * Handles the change event for selecting/deselecting all clients.
     *
     * @param {Function} setClients - Function to update the client state.
     * @param {Object[]} clients - The current client array.
     * @param {string[]} allValues - The array of all client IDs.
     * @param {boolean[]} isFormRequired - Array indicating if each form field is required.
     * @param {Function} setIsFormRequired - Function to update the form required state.
     * @param {string} key - The key identifier for the field being modified.
     */
    const handlerSelectAllChange = (
        setClients,
        clients,
        allValues,
        isFormRequired,
        setIsFormRequired,
        key
    ) => {
        setClients(clients?.length === allValues?.length ? [] : allValues);
        if (!isFormRequired.includes(key)) {
            setIsFormRequired((prevState) => [...prevState, key]);
        }
    };

    /**
     * Handles the click event for applying changes, such as selecting/deselecting clients.
     *
     * @param {Event} evt - The click event object.
     * @param {RefObject} buttonRef - Reference to the button element.
     * @param {Function} setClients - Function to update the client state.
     * @param {Object[]} clients - The current array of client objects.
     * @param {boolean[]} isFormRequired - Array indicating if each form field is required.
     * @param {Function} setIsFormRequired - Function to update the form required state.
     * @param {string} key - The key identifier for the field being modified.
     * @param {Function} setFormChanged - Function to update the form changed state.
     */
    const handleApplyClick = (
        evt,
        buttonRef,
        setClients,
        clients,
        isFormRequired,
        setIsFormRequired,
        key,
        setFormChanged
    ) => {
        evt.preventDefault();

        // Check if 'clients' is an array before using it
        if (Array.isArray(clients)) {
            // Check if there are any selected clients
            const hasSelectedClients = clients.length > 0;

            // Apply logic as necessary
            if (hasSelectedClients) {
                // Apply the selections
                // For example, here you could filter the selected clients and update the state
                // const selectedClients = clients.filter((client) => client.isSelected);
                // setClients(selectedClients);
            } else {
                // No selected clients, you can show an error message or take another action
                console.error('No selected clients');
            }
        } else {
            console.error("The 'clients' variable is not an array");
        }
        if (!isFormRequired.includes(key)) {
            setIsFormRequired((prevState) => [...prevState, key]);
        }

        setFormChanged(true);
        // Close the Drawer or take other necessary actions
        buttonRef?.current.close();
    };

    /**
     * Handles resetting client values and setting an error state.
     *
     * @param {Function} setClients - Function to update the client state.
     * @param {Function} setHasError - Function to update the error state.
     */
    const handleResetValues = (setClients, setHasError) => {
        setClients([]);
        setHasError(true);
    };

    return {
        recordsetClients,
        handleClientChange,
        selectAllValues,
        handlerSelectAllChange,
        handleApplyClick,
        handleResetValues
    };
};