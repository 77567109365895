import pointOfSale from 'components/point-of-sale';
import { apiPharmaInstance } from 'utils/axios';
import { ResponseType } from './types';
//create pos
export const createPos = async (clientId: any, data: any): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.post(`/api/clients/${clientId}/points-of-sale`, data);
};

// update pos
export const updatePos = async (clientId: any, posId: any, data: any): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.patch(`/api/clients/${clientId}/points-of-sale/${posId}`, data);
};

export const getERP = async (clientId: any): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.get(`api/clients/${clientId}/erp-management-systems`);
};
export const getPosPriorities = async (posId: any): Promise<ResponseType<any>> => {
    try {
        return await apiPharmaInstance.get(`api/points-of-sale/${posId}/drug-manufacturer-priorities`);
    } catch (e) {
        if (e.response.status === 404) {
            return undefined;
        }
    }
};
export const deletePosPriority = async (pointOfSaleId) => {
    try {
        return await apiPharmaInstance.delete(`/api/points-of-sale/${pointOfSaleId}/drug-manufacturer-priorities`);
    } catch (e) {
        if (e.response.status === 404) {
            alert('Error');
        }
    }
};
export const postPosPriorities = async (posId: any, data: any): Promise<ResponseType<any>> => {
    try {
        return await apiPharmaInstance.post(`api/points-of-sale/${posId}/drug-manufacturer-priorities`, data);
    } catch (e) {
        if (e.response.status === 404) {
            return undefined;
        }
    }
};
export const getPos = async (clientId: any, posId: any): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.get(`/api/clients/${clientId}/points-of-sale/${posId}`);
};
export const clone = async (clientId: number, pointOfSaleId: number, reference: string): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.post(`/api/clients/${clientId}/points-of-sale/${pointOfSaleId}/clone`, {
        reference: reference,
    });
};

export const duplicate = async (clientId: number, pointOfSaleId: number, name: string): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.post(`/api/clients/${clientId}/points-of-sale/${pointOfSaleId}/duplicate`, {
        name: name,
    });
};

export const getPoinstofsale = async (rows?: number, page?: number, value?: string): Promise<ResponseType<any>> => {
    let queryParams = '';
    if (!!rows) {
        queryParams += `rows=${rows}`;
    }
    if (!!page) {
        queryParams += `&page=${page}`;
    }
    if (!!value) {
        queryParams += `&filters[search]=${value}`;
    }
    return await apiPharmaInstance.get(`/api/points-of-sale?${queryParams}`);
};

// pos percentage of tolerance

export const postPercentageOfTolerance = async (pointOfSaleId?: number, data?: any): Promise<ResponseType<any>> => {
    try {
        return await apiPharmaInstance.post(`/api/points-of-sale/${pointOfSaleId}/distribution-percentages`, data);
    } catch (e) {
        if (e.response.status === 404) {
            return undefined;
        }
    }
};

export const getPercentageOfTolerance = async (pointOfSaleId?: number): Promise<ResponseType<any>> => {
    try {
        return await apiPharmaInstance.get(`/api/points-of-sale/${pointOfSaleId}/distribution-percentages`);
    } catch (e) {
        if (e.response.status === 404) {
            return undefined;
        }
    }
};

export const deletePercentageOfTolerance = async (pointOfSaleId?: number): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.delete(`/api/points-of-sale/${pointOfSaleId}/distribution-percentages`);
};

export const allPointsOfSale = async (search, page, rows) => {
    try {
        return await apiPharmaInstance.get(
            `api/points-of-sale/all?filters[search]=${search}&page=${page}&rows=${rows}`,
        );
    } catch (ex) {
        console.log(ex.message);
    }
};

export const allPointsOfSaleWithCredentials = async (id, typeId = '') => {
    try {
        let url = `/api/drug-manufacturers?sorts=name:asc&filters[point_of_sale_id]=${id}&includes=credentials&rows=1000`;
        if (typeId) {
            url += `&filters[typeId]=${typeId}`;
        }
        return await apiPharmaInstance.get(url);
    } catch (ex) {
        console.log(ex.message);
    }
};
