import React, { FC } from 'react'
import { Modal } from "components";
import { Grid, Typography } from '@material-ui/core';
import useStyles from "./ModalErrorPlatform.styles";
import { ModalErrorPlatformProps } from './ModalErrorPlatform.interface';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import Image from 'next/image';

const ModalErrorPlatform: FC<ModalErrorPlatformProps> = ({
    open,
    setOpen,
}) => {
    const classes = useStyles();
    return (
        <Modal
            className={classes.container}
            Width={'615px'}
            open={open}
            onClose={setOpen}
        >
            {open &&
                <Grid
                    container
                    direction='column'
                    alignItems='center'
                    justify='center'
                    className={classes.content}
                >
                    <Grid item>
                        <div className={classes.warningMessage}>
                            <Typography>
                                <WarningAmberRoundedIcon
                                    htmlColor='#0B2033'
                                    style={{
                                        width: 16,
                                        height: 16
                                    }}
                                /> Actualización
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item className={classes.firstSection}>
                        <img
                            src={'/assets/images/safety.jpg'}
                            alt="safety image"
                            // width={552}
                            // height={200}
                            style={{
                                width: 552,
                                height: 165
                            }}
                            // priority                       
                        />
                    </Grid>
                    <Grid item className={classes.secondSection}>
                        <div className={classes.textMessage}>
                            <Typography>
                                La seguridad de tus datos es nuestra prioridad.
                            </Typography>
                            <Typography>
                                En Extendeal, utilizamos la tecnología de Amazon Web Services (AWS), la nube más confiable del mundo, para garantizar que tu información esté siempre protegida.
                            </Typography>
                            <Typography>
                                AWS emplea sistemas avanzados de encriptación y protocolos de seguridad para resguardar tu información de amenazas cibernéticas. De esta forma podés usar Extendeal con total tranquilidad, sabiendo que tus datos están seguros.
                            </Typography>
                        </div>
                    </Grid>
                    {/* <Grid item className={classes.signature}>
                        <Typography>
                            <strong>Gracias por seguir confiando en Extendeal</strong>
                        </Typography>
                        <Image
                            src={'/assets/images/signature_hood.png'}
                            alt="my gif"
                            width={100}
                            height={65}
                        />
                    </Grid> */}
                    <Grid item className={classes.buttonSection}>
                        <button onClick={setOpen}>
                            <span>
                                Entendido
                            </span>
                        </button>
                    </Grid>
                </Grid>
            }
        </Modal>
    )
}
export default ModalErrorPlatform;