// react
import { useState, useEffect } from 'react';
// components
import { ButtonSolid } from '@commons/components/Button';
// rsuite   
import { Form } from 'rsuite';
// style
import style from './DrawerAlertFooter.module.css';
// icons
import { Delete } from '../Icons';
import { useToast } from "@commons/EXComponentsLibrary";

const DrawerAlertFooter = ({
    handlerSubmit,
    handlerDelete,
    data,
    formChanged,
    handlerUpdate,
    setOpenDrawer,
    setFetch,
    isFormRequired,
}) => {
    const toaster = useToast()

    // state
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (data && data.length > 0) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    }, [data]);

    const disabledButton = (isEdit && !formChanged) || (!isEdit && isFormRequired.length !== 6);

    const displayToaster = (message: string) => {
        toaster.showToaster({
            message: {title: message},
            duration: 2000,
            type: 'success'
        })
    }

    return (
        <Form.Group className={isEdit ? style['delete-footer'] : style['footer']}>
            {isEdit && (
                <button
                    className={style['delete-alert']}
                    onClick={() => {
                        handlerDelete();
                        setTimeout(() => {
                            setOpenDrawer(false), setFetch(true);
                        }, 1500);
                        displayToaster("Alerta desactivada con éxito")
                    }}
                >
                    <Delete />
                    <span style={{ marginLeft: 8 }}>Desactivar alerta</span>
                </button>
            )}
            <ButtonSolid
                type="submit"
                onClick={() => {
                    if (!!formChanged && data.length > 0) {
                        setTimeout(() => {
                            setIsEdit(!isEdit);
                            handlerUpdate(), setOpenDrawer(false), setFetch(true);
                        }, 1500);
                        displayToaster("Cambios guardados con éxito")
                        return;
                    }

                    if (!isEdit && isFormRequired.length === 6) {
                        setTimeout(() => {
                            handlerSubmit(), setOpenDrawer(false), setFetch(true);
                        }, 1000);
                        displayToaster("Cambios guardados con éxito")
                        return;
                    }
                }}
                text={isEdit ? 'Guardar cambios' : 'Activar alerta'}
                width={isEdit ? 166 : 140}
                height={40}
                disabled={disabledButton}
            />
        </Form.Group>
    );
};

export default DrawerAlertFooter;
