import { useState } from 'react';

const useIsLoading = () => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => {
    setIsLoading(true);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  return {isLoading, startLoading, stopLoading};
};
export default useIsLoading;