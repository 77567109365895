import { ComputerDanger, ComputerWarning } from '../../../Icons';

export const createTypeAlert = [
    {
        title: 'Crítica',
        label: 'Errores de la plataforma que resultan bloqueantes para que el usuario pueda comprar.',
        icon: <ComputerDanger />,
        value: '1',
    },
    {
        title: 'Advertencia',
        label: 'Errores del sistema que pueden ser: intermitencia de conexión a droguerías u otros.',
        icon: <ComputerWarning />,
        value: '2',
    },
];


