// interface
import { IDrawerAlertSelect } from './DrawerAlertSelect.interface';
// style
import style from './DrawerAlertSelect.module.css';
// rsuite
import { Form } from 'rsuite';

const DrawerAlertSelect = ({ children, label }: IDrawerAlertSelect) => {
    return (
        <Form.Group controlId={label} className={style['input-container']}>
            <Form.ControlLabel>
                <span className={style['placeholder']}> {label}</span>
                <span className={style['required']}>*</span>
            </Form.ControlLabel>
            {children}
        </Form.Group>
    );
};

export default DrawerAlertSelect;
