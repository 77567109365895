import { OutlinedInput } from '@material-ui/core';
import React from 'react';
import useStyles from './AdminSearchBar.styles';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useRouter } from 'next/router';
import { useClients } from 'utils/hooks/use-clients';
import { useClientsUsers } from 'utils/hooks/use-clients-users';
import { usePointsOfSale } from 'utils/hooks';

const AdminSearchBar = ({ query }) => {
  const classes = useStyles();
  const router = useRouter();
  const [search, setSearch] = React.useState(query ? query : '');

  const hasCharacters = () => search.length > 0;

  

  const goToSearchResults = () => {
    if (hasCharacters()) {
      router.push(`/clients/searchClients?query=${search}`);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      goToSearchResults();
    }
  };

  const handleClose = () => {
    setSearch('');
  };

  return (
    <div className={classes.root}>
      <OutlinedInput
        placeholder='Buscar en clientes, usuarios y puntos de venta'
        fullWidth
        className={classes.input}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={handleKeyPress}
        startAdornment={
          <>
            {hasCharacters() && <ArrowBackIcon className={classes.arrow} />}
          </>
        }
        endAdornment={
          <>
            {hasCharacters() ? (
              <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
            ) : (
              <SearchIcon />
            )}
          </>
        }
      />
    </div>
  );
};

export default AdminSearchBar;
