import React from "react";
import styled, { css } from "styled-components";
import { rem, lighten, darken } from "polished";
import { ButtonBase, ButtonBaseProps } from "@material-ui/core";

export interface IconButtonProps extends ButtonBaseProps {
  active?: boolean;
  color: string;
  iconColor?: string;
  children: React.ReactNode;
  hoverColor: string;
}

export const StyledButton = styled(
  React.forwardRef(
    (
      { active, color, iconColor, hoverColor, ...props }: IconButtonProps,
      ref: any
    ) => <ButtonBase ref={ref} {...props} classes={{ disabled: "disabled" }} />
  )
)`
  ${(props) => css`
    background-color: ${props.color};
    width: ${rem("36px")};
    height: ${rem("36px")};
    border-radius: ${rem("4px")};
    color: ${props.iconColor};

    &:hover {
      background-color: ${props.hoverColor
        ? props.hoverColor
        : lighten(0.02, props.color)};
    }

    &:active {
      background-color: ${props.hoverColor
        ? props.hoverColor
        : darken(0.04, props.color)};
    }

    ${props.active &&
    css`
      background-color: ${props.hoverColor
        ? props.theme.colors.S["400"]
        : lighten(0.04, props.color)};
    `}

    &.disabled {
      background-color: ${props.theme.colors.S["400"]};
      color: ${props.theme.colors.S["500"]};
    }
  `}
`;

export const IconButton = React.memo(
  React.forwardRef(({ children, ...props }: IconButtonProps, ref) => (
    <StyledButton ref={ref} {...props}>
      {children}
    </StyledButton>
  ))
);
