import { apiPharmaInstance } from 'utils/axios';

// get
export const getDrugManufacturersPending = async (pointOfSaleId: string) => {
    try {
        const response = await apiPharmaInstance.get(`/api/points-of-sale/${pointOfSaleId}/drug-manufacturers-pending`);
        return response.data;
    } catch (ex) {
        console.error(ex.message);
    }
};
