// rsuite
import { Button } from 'rsuite';
// style
import style from './DrawerAlertHeader.module.css'
// mui
import CloseIcon from '@mui/icons-material/Close';
// interface
import { IDrawerAlertHeader } from './DrawerAlertHeader.interface';

const DrawerAlertHeader = ({ setOpenDrawer } : IDrawerAlertHeader) => {
    return (
        <div className={style['container-header']}>
            <span className={style['header-title']}>Configurar alerta</span>
            <Button onClick={() => setOpenDrawer(false)} className={style['container-close-btn']}>
                <CloseIcon className={style['icon']} />
            </Button>
        </div>
    );
};

export default DrawerAlertHeader;
