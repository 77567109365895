import useSWR from 'swr';
import { ApiCredential, ResponseType } from 'common/types';
import { objectToQueryParams } from 'utils/string';
import { isBase64 } from 'utils/base64';
import { apiPharmaInstance } from 'utils/axios';

/* OLD CODE +4 YEARS */
export function useApiCredentials(query?) {
    const queryParams = objectToQueryParams(query);
    const { data, mutate: mutateApiCredentials, error } = useSWR<ResponseType<ApiCredential[]>>(
        `/api/api-credential?${queryParams}`,
    );

    if (data) {
        data.data.forEach((credential) => {
            if (!credential.decoded_password) {
                credential.password = isBase64(credential.password)
                    ? window.atob(credential.password)
                    : credential.password;
                credential.decoded_password = true;
            }
        });
    }

    return {
        apiCredentials: data,
        isLoading: !error && !data,
        isEmpty: Array.isArray(data?.data) && data?.data.length === 0,
        error,
        mutateApiCredentials,
    };
}

/* OLD CODE +4 YEARS */

const fetcher = async (url) => {
    const response = await apiPharmaInstance.get(url);
    return response.data.data;
};

export const useApiCredential = (apiCredentialId, options = {}) => {
    const { data, error, mutate } = useSWR(apiCredentialId ? `/api/api-credential/${apiCredentialId}` : null, fetcher, {
        dedupingInterval: 60000, // Retener en caché durante 60 segundos
        revalidateOnFocus: false, // No revalidar automáticamente cuando se vuelve a enfocar la página
        ...options, // Permitir sobreescribir opciones desde afuera
    });

    return {
        apiCredentialData: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
};
