import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    loading: boolean;
    setLoading: (v: boolean) => void;
};

const defaultValues: ContextProps = {
    loading: false,
    setLoading: (v: boolean) => {},
};

type Props = {
    children: React.ReactNode;
};

const FetchingContext = createContext<ContextProps>(defaultValues);

export const FetchingProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState<boolean>(defaultValues.loading);

    const value: ContextProps = useMemo(
        () => ({
            loading,
            setLoading,
        }),
        [loading, setLoading],
    );

    return <FetchingContext.Provider value={value}>{children}</FetchingContext.Provider>;
};

export const useFetchingContext = () => useContext(FetchingContext);
