import React from 'react';

export interface ReloadProductsProps {
 
  reload: boolean;
  setReload: (reload: boolean) => void;
  change: boolean;
  setChange: (reload: boolean) => void;
  arrayGroups:any;
  num:any;
  setNumber:any;
 
}

const ModalContext = React.createContext<Omit<ReloadProductsProps, "children">>(
  null
);


export const ReloadProducts = ({ children }) => {
    const [reload, setReload] = React.useState<boolean>(false);
    const [change, setChange] = React.useState<boolean>(false);
    const [num, setNumber] =React.useState(1);
    let arrayGroups = []
   return (
    <ModalContext.Provider
      value={{
        reload, setReload, change, setChange, arrayGroups, num, setNumber
      }}
    >
      {children}
    </ModalContext.Provider>
  );
 
}

export function useReloadProducts() {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error("usePos must be used within a PosProvider");
  }
  return context;
}


