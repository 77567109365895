import { EX4402 } from 'config/flags';
import { isEmptyValue } from 'utils/string';

export const formatObjectUpdate = (object, quantity) => {
    return {
        barcode: object.barcode,
        description: object.description,
        quantity: quantity,
    };
};

export const formatObjectCreate = (object) => {
    return {
        barcode: isEmptyValue(object._source?.ean[0]) ? ' ' : String(object._source.ean[0]),
        description: `${isEmptyValue(object._source?.description) ? '' : object._source.description} ${
            isEmptyValue(object._source?.laboratory) ? ' ' : object._source.laboratory
        }`,
        quantity:
            EX4402 && object._source?.suggested_quantity && !!object._source?.suggested_quantity
                ? object._source.suggested_quantity
                : 1,
        is_recommended: !!object?._source?.is_recommended,
        is_included: !!object?._source?.is_included ? object?._source?.is_included : null,
    };
};
