import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#fff",
  },
  mainContent: {
    flex: 1,
    overflow: "hidden",
  },
  headerSeparator: {
    height: "48px",
  },
}));

export default useStyles;
