import React from 'react';
import { IDotProps } from './interfaces/Dot.interface';
import styles from './Dot.module.css';

const Dot = ({ color = '#FFC700' }: IDotProps) => {
    return (
        <div className={styles.position}>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4" fill={color} />
            </svg>
        </div>
    );
};

export default Dot;
