import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// utils
import { useDrugManufacturersByOrderId } from 'utils/hooks';

const useDrugManufacturersData = () => {
    // hooks
    const { query } = useRouter();
    const { drugManufacturersResponse, mutateDrugManufacturers } = useDrugManufacturersByOrderId(query?.orderId);
    // states
    const [drugManufacturersData, setDrugManufacturersData] = useState<any>([]);
    const [oneDisabledDrugManufacturerData, setOneDisabledDrugManufacturerData] = useState<boolean>(false);

    useEffect(() => {
        if (drugManufacturersResponse?.data) {
            const oneDisabledDrugManufacturer = drugManufacturersResponse?.data?.some((item) => {
                return item.enabled === false;
            });
            if (oneDisabledDrugManufacturer) setOneDisabledDrugManufacturerData(true);

            setDrugManufacturersData(drugManufacturersResponse?.data);
        }
    }, [query?.orderId, drugManufacturersResponse]);

    return {
        drugManufacturersData,
        oneDisabledDrugManufacturerData,
        mutateDrugManufacturers,
    };
};

export default useDrugManufacturersData;
