import styled from "styled-components";
import { rem, animation } from "polished";

export const ErrorAlert = styled.div`
  width: 100%;
  font-size: ${rem("12px")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${(props) => props.theme.colors.Ma[400]};
  height: ${rem("48px")};
  border-radius: ${rem("4px")};
  ${animation(["fadeIn", ".7s", "ease-out"])}
  margin: ${rem("8px")} 0;
`;
