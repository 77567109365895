import React from "react";

export interface CloseModalProps {
  loadingModal: boolean;
  setLoadingModal: (loadingModal: boolean) => void;
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  control: boolean;
  setControl: (control: boolean) => void;
  controlModal: boolean;
  setControlModal: (control: boolean) => void;
  setCurrentSelected?: any;
  currentSelected?: any;
  setDontHaveCredentialsModal?: (dontHaveCredentialsModal: boolean) => void;
  dontHaveCredentialsModal?: boolean;
}

const ModalContext = React.createContext<Omit<CloseModalProps, "children">>(
  null
);

export const CloseModal = ({ children }) => {
  const [loadingModal, setLoadingModal] = React.useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(true);
  const [control, setControl] = React.useState<boolean>(false);
  const [controlModal, setControlModal] = React.useState<boolean>(false);
  const [currentSelected, setCurrentSelected] = React.useState();
  const [
    dontHaveCredentialsModal,
    setDontHaveCredentialsModal,
  ] = React.useState<boolean>(false);
  return (
    <ModalContext.Provider
      value={{
        loadingModal,
        setLoadingModal,
        isOpenModal,
        setIsOpenModal,
        control,
        setControl,
        controlModal,
        setControlModal,
        currentSelected,
        setCurrentSelected,
        dontHaveCredentialsModal,
        setDontHaveCredentialsModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export function useCloseModal() {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error("usePos must be used within a PosProvider");
  }
  return context;
}
