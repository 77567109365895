import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    showAllGenerics: boolean;
    setShowAllGenerics: (v: boolean) => void;
};

const defaultValues: ContextProps = {
    showAllGenerics: false,
    setShowAllGenerics: (v: boolean) => {},
};

type Props = {
    children: React.ReactNode;
};

const GenericsContext = createContext<ContextProps>(defaultValues);

export const GenericsProvider = ({ children }: Props) => {
    const [showAllGenerics, setShowAllGenerics] = useState<boolean>(defaultValues.showAllGenerics);

    const value: ContextProps = useMemo(
        () => ({
            showAllGenerics,
            setShowAllGenerics,
        }),
        [showAllGenerics, setShowAllGenerics],
    );

    return <GenericsContext.Provider value={value}>{children}</GenericsContext.Provider>;
};

export const useGenericsContext = () => useContext(GenericsContext);
