import styled, { css, CSSProperties } from "styled-components";
import { rem } from "polished";

interface TextProps {
  color?: string;
  fontWeight?: CSSProperties["fontWeight"];
  fontSize: "20px" | "16px" | "14px" | "12px";
  disabled?: boolean;
  backgroundColor?: string;
  maxWidth?: string;
}

export const Text = styled.span<TextProps>`
  font-size: ${(props) => rem(props.fontSize)};
  font-family: Source Sans Pro;
  max-width: ${(props) => (props.maxWidth ? (props.maxWidth) : "100%")};
    ${(props) =>
      props?.color &&
      css`
        color: ${props?.color};
      `};

  ${(props) =>
    props?.fontWeight &&
    css`
      font-weight: ${props?.fontWeight};
    `};

  ${(props) =>
    props?.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
      border-radius: ${rem("20px")};
      padding: ${rem("2px")} ${rem("12px")};
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.S[500]};
      background-color: ${(props) =>
        //@ts-ignore
        props.backgroundColor ? props.theme.colors.S[300] : "white"};
    `}
`;

export default Text;
