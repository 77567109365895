export const auxiliary = () => {
    /**
     * Transforms an array of country objects into an array of objects with 'label' and 'value' properties.
     * The 'label' property represents the country name, and the 'value' property represents the country code.
     * Additionally, adds an option for "Todos" at the beginning of the list.
     *
     * @param {Object[]} countries - An array of country objects containing 'alfa_2' and 'name' properties.
     * @returns {Object[]} An array of objects with 'label' and 'value' properties.
     */
    const recordsetCountries = (countries) => {
        // Map through the array of country objects and create a new array of transformed objects
        return countries?.data
            .map(({ alfa_2, name }) => {
                return {
                    label: name, // 'label' property representing the country name
                    value: alfa_2, // 'value' property representing the country code
                };
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label); // Sorting the countries alphabetically by name
            });
    };

    /**
     * Handles the selection of a country and updates the selected country state.
     * Also updates the form state if necessary.
     *
     * @param {Object} selectedCountry - The selected country object.
     * @param {Function} setCountry - The function to set the selected country.
     * @param {boolean[]} isFormRequired - Array indicating if each form field is required.
     * @param {Function} setIsFormRequired - Function to update the form required state.
     * @param {string} key - The key identifier for the field being modified.
     * @param {boolean} isEditForm - Indicates whether the form is in edit mode.
     * @param {Function} setFormChanged - Function to update the form changed state.
     * @returns {void}
     */
    const handleSelectCountry = (
        selectedCountry,
        setCountry,
        isFormRequired,
        setIsFormRequired,
        key,
        isEditForm,
        setFormChanged
    ) => {
        setCountry(selectedCountry);
        if (!isFormRequired.includes(key)) {
            setIsFormRequired((prevState) => [...prevState, key]);
        }

        if(!!isEditForm) {
            setFormChanged(true);
        }
    };

    /**
     * Extracts an array of client IDs from a recordset array.
     *
     * @param {Object[]} recordset - An array of objects with 'label' and 'value' properties representing clients.
     * @returns {string[]} An array of client IDs extracted from the recordset.
     */
    const selectAllValues = (recordset) => recordset?.map((item) => item.value);

    /**
     * Handles the change event for selecting/deselecting all countries.
     *
     * @param {Function} setCountry - Function to update the country state.
     * @param {Object[]} country - The current country array.
     * @param {string[]} allValues - The array of all country codes.
     * @param {boolean[]} isFormRequired - Array indicating if each form field is required.
     * @param {Function} setIsFormRequired - Function to update the form required state.
     * @param {string} key - The key identifier for the field being modified.
     * @param {boolean} isEditForm - Indicates whether the form is in edit mode.
     * @param {Function} setFormChanged - Function to update the form changed state.
     * @returns {void}
     */
    const handlerSelectAllChange = (
        setCountry,
        country,
        allValues,
        isFormRequired,
        setIsFormRequired,
        key,
        isEditForm,
        setFormChanged
    ) => {
        setCountry(country?.length === allValues?.length ? [] : allValues);
        if (!isFormRequired.includes(key)) {
            setIsFormRequired((prevState) => [...prevState, key]);
        }
        if(!!isEditForm) {
            setFormChanged(true);
        }
    };

    /**
     * Handles the click event for applying changes, such as selecting/deselecting countries.
     *
     * @param {Event} evt - The click event object.
     * @param {RefObject} buttonRef - Reference to the button element.
     * @param {Function} setCountry - Function to update the country state.
     * @param {Object[]} country - The current array of country objects.
     * @param {boolean[]} isFormRequired - Array indicating if each form field is required.
     * @param {Function} setIsFormRequired - Function to update the form required state.
     * @param {string} key - The key identifier for the field being modified.
     * @param {Function} setFormChanged - Function to update the form changed state.
     * @param {boolean} isEditForm - Indicates whether the form is in edit mode.
     * @returns {void}
     */
    const handleApplyClick = (
        evt,
        buttonRef,
        setCountry,
        country,
        isFormRequired,
        setIsFormRequired,
        key,
        setFormChanged,
        isEditForm
    ) => {
        evt.preventDefault();

        // Check if 'country' is an array before using it
        if (Array.isArray(country)) {
            // Check if there are any selected countries
            const hasSelectedCountries = country.length > 0;

            // Apply logic as necessary
            if (hasSelectedCountries) {
                // Apply the selections
                // For example, here you could filter the selected countries and update the state
                // const selectedCountries = country.filter((c) => c.isSelected);
                // setCountry(selectedCountries);
            } else {
                // No selected countries, you can show an error message or take another action
                // console.error('No selected countries');
            }
        } else {
            console.error("The 'country' variable is not an array");
        }
        if (!isFormRequired.includes(key)) {
            setIsFormRequired((prevState) => [...prevState, key]);
        }

        if(!!isEditForm) {
            setFormChanged(true);
        }
        // Close the Drawer or take other necessary actions
        buttonRef?.current.close();
    };

    /**
     * Handles resetting country values and setting an error state.
     *
     * @param {Function} setCountry - Function to update the country state.
     * @param {Function} setHasError - Function to update the error state.
     * @param {Object[]} country - The current array of country objects.
     * @returns {void}
     */
    const handleResetValues = (setCountry, setHasError, country) => {
        setCountry([]);
        setHasError(true);
    };

    return {
        recordsetCountries,
        handleSelectCountry,
        selectAllValues,
        handlerSelectAllChange,
        handleApplyClick,
        handleResetValues
    };
};