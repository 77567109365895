import React from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import { EmptyPlaceholder, EmptyPlaceholderProps } from "./empty-placeholder";
import {
  Table as MuiTable,
  TableProps as MuiTableProps,
  TableBody,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
  TableHead as MuiTableHead,
  TableHeadProps as MuiTableHeadProps,
  TableSortLabel,
  TableFooter,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Skeleton from "@mui/material/Skeleton";

const noResultsPlaceholder = "/assets/images/product_not_found.svg";

export type OrderDirection = "asc" | "desc" | undefined;

export interface TableCellHeadProps extends MuiTableHeadProps {
  columns: Array<Column>;
}

export interface Column extends TableCellProps {
  key: string;
  name?: string;
  Component?: React.ReactNode;
  hidden?: boolean;
  backgroundColor?: string;
  sorteable?: boolean;
  sortKey?: string;
  width?: number | string;
  backgroundTitle?: string;
  isDisabled?: boolean;
  eventColumn?: (params: any) => any;
  enabledOnOff?: boolean;
}

export interface TableCellProps extends MuiTableCellProps {
  contentType?: "number" | "genericTotal";
  loading?: boolean;
}

export interface TableRowProps extends MuiTableRowProps {
  disabled?: boolean;
  href?: string;
}

export interface TableProps extends MuiTableProps {
  columns: any;
  data: any;
  renderRow(item: any, idx?: number): any;
  loading: boolean;
  LoadingComponent?: React.ReactNode;
  emptyPlaceholderProps?: EmptyPlaceholderProps;
  FooterComponent?: React.ReactNode;
  TableEmptyComponent?: React.ReactNode;
  ToolbarComponent?: React.ReactNode;
  NoResultsComponent?: React.ReactNode;
  showToolbar?: boolean;
  search?: string;
  noResultsText?: string;
  onColumnSort?: (
    event: React.MouseEvent<unknown>,
    orderBy: string,
    orderDirection: OrderDirection
  ) => void;
  sorts?: string;
  children?: React.ReactNode;
  ariaLabel?: string;
}

const StyledTable = styled(MuiTable)<{ columns: Array<Column> }>`
  ${(props) =>
    props.columns?.map((column, index) => {
      return css`
        tr > *:nth-child(${index + 1}) {
          ${column.hidden && "display: none;"}
          ${column.backgroundColor &&
          `background-color: ${column.backgroundColor};`}
          ${column.width && `width: ${column.width};`}
          ${column?.color && `color: ${column?.color}`}
          ${column?.style?.visibility &&
          css`
            visibility: ${column?.style?.visibility};
          `}
          ${column?.style?.boxShadow &&
          css`
            box-shadow: ${column?.style?.boxShadow};
          `}
        }
      `;
    })}
`;

const StyledTableHead = styled(
  ({ children, ...restProps }: TableCellHeadProps) => {
    return <MuiTableHead {...restProps}>{children}</MuiTableHead>;
  }
)<{ columns?: Array<Column> }>`
  text-transform: uppercase;

  & .MuiTableCell-stickyHeader {
    background-color: white;
  }

  & .MuiTableCell-head {
    border-radius: ${rem("4px")} ${rem("4px")} 0 0;

    ${(props) =>
      props?.columns?.map((column, index) => {
        return css`
          &:nth-child(${index > 3 && index <= props?.columns?.length
                ? index
                : null}) {
            ${props?.columns[index - 1]?.enabledOnOff
              ? css`
                  cursor: pointer;
                  border-top: 4px solid
                    ${props?.columns[index - 1]?.isDisabled
                      ? "#d1d1d1"
                      : "#1dcad3"};

                  &:hover {
                    background-color: #ddf7f8;
                  }
                `
              : css``}
          }

          &:nth-last-child(${props?.columns.length - index}) {
            ${column?.backgroundTitle &&
            css`
              background-color: ${column?.backgroundTitle};
              color: white;
              box-shadow: none;
            `}

            ${column?.key?.match(/idle/) &&
            css`
              padding-left: ${rem("7px")};
              padding-right: ${rem("7px")};
              background-color: transparent;
              visibility: hidden;
            `}
          }
        `;
      })}
  }
`;

export function LoadingSkeleton({ width }) {
  return (
    <Skeleton width={width}>
      <Typography>.</Typography>
    </Skeleton>
  );
}

export const TableCell = styled(
  ({ children, contentType, loading, ...restProps }: TableCellProps) => (
    <MuiTableCell classes={{ head: "head" }} {...restProps}>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center">
          <LoadingSkeleton width="80%" />
        </Grid>
      ) : (
        children
      )}
    </MuiTableCell>
  )
)`
  font-size: ${rem("12px")};
  color: ${(props) => props.theme.colors.S["800"]};
  height: ${rem("56px")};

  ${(props) =>
    props.contentType === "number" &&
    css`
      color: ${props.theme.colors.B["800"]};
      font-weight: 600;
    `}

  ${(props) =>
    props.contentType === "genericTotal" &&
    css`
      font-weight: 600;
      font-size: ${rem("14px")};
    `}

  &.head {
    font-weight: 600;
    padding-bottom: ${rem("12px")};
    height: inherit;
  }

  & .text {
    cursor: text;
  }
`;

export const TableRow = styled(({ children, ...restProps }: TableRowProps) => (
  <MuiTableRow classes={{ hover: "hover" }} {...restProps}>
    {children}
  </MuiTableRow>
))`
  &.hover {
    cursor: pointer;
  }
`;

const EmptyListContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const NoResultsContainer = styled(EmptyListContainer)`
  height: auto;
`;

const ToolbarWrapper = styled.div<{ visible: boolean }>`
  position: sticky;
  visibility: ${(props) => (props.visible ? "initial" : "hidden")};
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export function Table({
  columns,
  data,
  renderRow,
  loading,
  LoadingComponent,
  FooterComponent,
  TableEmptyComponent,
  ToolbarComponent,
  NoResultsComponent,
  showToolbar = false,
  search,
  emptyPlaceholderProps,
  noResultsText,
  onColumnSort,
  sorts,
  children,
  ariaLabel,
  ...restProps
}: TableProps) {
  const emptyData = data === undefined || data.length === 0;
  const orderBy =
    sorts && sorts.includes(":") ? sorts.split(":")[0] : undefined;
  const orderDirection =
    sorts && sorts.includes(":")
      ? (sorts.split(":")[1] as OrderDirection)
      : undefined;

  if (loading && LoadingComponent) {
    return <EmptyListContainer>{LoadingComponent}</EmptyListContainer>;
  }

  if (emptyData && !search) {
    return (
      <EmptyListContainer>
        {emptyPlaceholderProps !== undefined ? (
          <EmptyPlaceholder {...emptyPlaceholderProps} />
        ) : (
          TableEmptyComponent
        )}
      </EmptyListContainer>
    );
  }

  const handleRequestSort = (
    orderBy: string,
    orderDirection: OrderDirection
  ) => (event: React.MouseEvent<unknown>) => {
    if (onColumnSort) {
      onColumnSort(event, orderBy, orderDirection === "asc" ? "desc" : "asc");
    }
  };

  return (
    <>
      <ToolbarWrapper visible={showToolbar}>{ToolbarComponent}</ToolbarWrapper>
      <StyledTable
        columns={columns}
        {...restProps}
        role="table"
        aria-label={ariaLabel || ""}
      >
        <StyledTableHead columns={columns}>
          <MuiTableRow hidden={!showToolbar}>
            {columns?.map(
              (
                {
                  name,
                  Component,
                  key,
                  sorteable,
                  sortKey,
                  width,
                  eventColumn,
                  ...restColumnCellProps
                },
                index
              ) =>
                (name !== undefined || Component) && (
                  <TableCell
                    style={{ fontFamily: "Source Sans Pro" }}
                    onClick={eventColumn}
                    key={index}
                    sortDirection={
                      sorteable && orderBy !== undefined && orderBy === sortKey
                        ? orderDirection
                        : false
                    }
                    {...restColumnCellProps}
                  >
                    {sorteable ? (
                      <TableSortLabel
                        active={
                          orderBy !== undefined ? orderBy === sortKey : true
                        }
                        direction={
                          orderBy !== undefined && orderBy === sortKey
                            ? orderDirection
                            : "desc"
                        }
                        IconComponent={ExpandMoreIcon}
                        onClick={handleRequestSort(
                          sortKey || "",
                          orderDirection
                        )}
                      >
                        {name !== undefined ? name : Component}
                      </TableSortLabel>
                    ) : name !== undefined ? (
                      name
                    ) : (
                      Component
                    )}
                  </TableCell>
                )
            )}
          </MuiTableRow>
        </StyledTableHead>
        <TableBody>
          {data?.map((item: any, idx: number) => (
            <React.Fragment key={idx}>{renderRow(item, idx)}</React.Fragment>
          ))}
          {children}
        </TableBody>
        <TableFooter>{FooterComponent}</TableFooter>
      </StyledTable>
      {emptyData && search && (
        <NoResultsContainer>
          {noResultsText !== undefined ? (
            <EmptyPlaceholder
              image={noResultsPlaceholder}
              title={noResultsText}
            />
          ) : (
            NoResultsComponent
          )}
        </NoResultsContainer>
      )}
    </>
  );
}
