import { Menu } from 'components/menu';

import style from './PointsOfSaleModal.module.css';
import { useEffect } from 'react';

const PointsOfSaleModal = ({
  open,
  anchorEl,
  style,
  mappingName,
}) => {
  

  return (
    <Menu
      anchorEl={anchorEl}
      className={style['menu-content']}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={style}
    >
      {mappingName.map(({ name, id }) => {
        return (
          <p
            key={id}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              minWidth: 180,
              height:'auto'
            }}
          >
            { name }
          </p>
        );
      })}
    </Menu>
  );
};

export default PointsOfSaleModal;
