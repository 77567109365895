import { apiPharmaInstance } from "utils/axios";
import { ResponseType } from "common/types";

// GET Cart
export const getCartInProgress = async (
    pointOfSaleId: number
): Promise<ResponseType<any>> => {
    try {
        const response = await apiPharmaInstance.get(
            `/api/points-of-sale/${pointOfSaleId}/cart-open`
        );
        return response.data.data;
    } catch (e) {
        if (e.response.status === 404) {
            return {
                data: []
            };
        }
    }
};

// CREATE Cart Crear un item al carrito (in progress) actual
export const createCartItem = async (
    pointOfSaleId: number,
    data: any
): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.post(
        `/api/points-of-sale/${pointOfSaleId}/cart-items`,
        data
    );
};

// UPDATE Cart Actualizar un item del carrito actual
export const updateCartItem = async (
    pointOfSaleId: number,
    cartItemId: number,
    data: any
): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.patch(
        `/api/points-of-sale/${pointOfSaleId}/cart-items/${cartItemId}`,
        data
    );
};

// EMPTY CART Vaciar el carrito de compra - Respuesta OK (Code 204)
export const emptyCartByPointOfSaleId = async (
    pointOfSaleId: number
): Promise<ResponseType<any>> => {
    const response = await apiPharmaInstance.delete(
        `/api/points-of-sale/${pointOfSaleId}/empty-cart`
    );
    
    if(response.status === 204) {
        return {
            data:[]
        }
    }
    return response;
};

// DELETE CART-ITEM Eliminar un item del carrito actual - Respuesta OK (Code 204)
export const deleteCartItemById = async (
    pointOfSaleId: number,
    cartItemId: number
): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.delete(
        `/api/points-of-sale/${pointOfSaleId}/cart-items/${cartItemId}`
    );
};