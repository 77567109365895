import { useMemo } from 'react';
import { IHintProps } from '../interfaces/Hint.interface';
import style from './HintIcon.module.css';
import { CheckCircle, ErrorRounded, WarningRounded, InfoRounded } from '@material-ui/icons';

type Props = {
    type: IHintProps['type'];
};

const HintIcon = ({ type }: Props) => {
    const IconComponent = useMemo(() => {
        // move this out to a different component
        switch (type) {
            case 'success':
                return <CheckCircle className={`icon ${style[`icon-${type}`]}`} />;
            case 'info':
                return <InfoRounded className={`icon ${style[`icon-${type}`]}`} />;
            case 'warning':
                return <WarningRounded className={`icon ${style[`icon-${type}`]}`} />;
            case 'error':
                return <ErrorRounded className={`icon ${style[`icon-${type}`]}`} />;
            default:
                return null;
        }
    }, [type]);

    return <div className={style['icon-container']}>{IconComponent}</div>;
};

export default HintIcon;
