import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    expandedFooter: boolean;
    setExpandedFooter: (v) => void;
};

const defaultValues: ContextProps = {
    expandedFooter: false,
    setExpandedFooter: (v: boolean) => {},
};

type Props = {
    children: React.ReactNode;
};

const ExpandedFooterContext = createContext<ContextProps>(defaultValues);

export const ExpandedFooterProvider = ({ children }: Props) => {
    const [expandedFooter, setExpandedFooter] = useState<boolean>(defaultValues.expandedFooter);

    const value: ContextProps = useMemo(
        () => ({
            expandedFooter,
            setExpandedFooter,
        }),
        [expandedFooter],
    );

    return <ExpandedFooterContext.Provider value={value}>{children}</ExpandedFooterContext.Provider>;
};

export const useExpandedFooterContext = () => useContext(ExpandedFooterContext);
