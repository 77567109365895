import { createContext, useContext, useState } from 'react';

type ContextProps = {
    footerValues: any;
    setFooterValues: (v: any) => void;
    youAreSavings: string;
    setYouAreSavings: (v: string) => void;
};

const defaultValues: ContextProps = {
    footerValues: {},
    setFooterValues: (obj: any) => {},
    youAreSavings: '0',
    setYouAreSavings: (v: string) => {},
};

type Props = {
    children: React.ReactNode;
};

const FooterContext = createContext<ContextProps>(defaultValues);

export const FooterProvider = ({ children }: Props) => {
    const [footerValues, setFooterValues] = useState<any>(defaultValues.footerValues);
    const [youAreSavings, setYouAreSavings] = useState<any>(defaultValues.youAreSavings);

    const value: ContextProps = {
        footerValues,
        setFooterValues,
        youAreSavings,
        setYouAreSavings,
    };

    return <FooterContext.Provider value={value}>{children}</FooterContext.Provider>;
};

export const useFooterContext = () => useContext(FooterContext);
