import styled from "styled-components";
import { rem } from "polished";
import { Toolbar as MuiToolbar } from "@material-ui/core";

export const Toolbar = styled(MuiToolbar)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: ${rem("4px")};
  min-height: ${rem("53px")};
  background-color: white;
  margin-left: ${rem("42px")};
`;

export const RowCounter = styled.span`
  color: ${(props) => props.theme.colors.S[900]};
  font-size: ${rem("16px")};
  margin: 0 ${rem("24px")};
`;
