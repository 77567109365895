import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    showRecommendations: boolean;
    setShowRecommendations: (v) => void;
};

const defaultValues: ContextProps = {
    showRecommendations: true,
    setShowRecommendations: (v: boolean) => {},
};

type Props = {
    children: React.ReactNode;
};

const RecommendationsContext = createContext<ContextProps>(defaultValues);

export const RecommendationsProvider = ({ children }: Props) => {
    const [showRecommendations, setShowRecommendations] = useState<boolean>(defaultValues.showRecommendations);
    // ACA FALTAN LOS OTROS STATES DE RECOMENDADOS PERO EN V3

    const value: ContextProps = useMemo(
        () => ({
            showRecommendations,
            setShowRecommendations,
        }),
        [showRecommendations],
    );

    return <RecommendationsContext.Provider value={value}>{children}</RecommendationsContext.Provider>;
};

export const useRecommendationsContext = () => useContext(RecommendationsContext);
