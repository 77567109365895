import { makeStyles } from "@material-ui/core/styles";
const useStyle = makeStyles((theme) => ({
    container: {
        "& div div.MuiPaper-rounded": {
            borderRadius: 8,
            overflowY: 'auto'
        },
    },
    content: {
        display: "flex",
        padding: "40px 32px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: 16,
        width: '100%'
    },
    warningMessage: {
        borderRadius: 100,
        background: "#FFC700",
        padding: "2px 8px",
        width: "max-content",
        "& > p": {
            display: 'flex',
            alingItems: 'center',
            gap: 8,
            color: "#0B2033",
            fontWeight: 600,
            fontSize: 12,
        }
    },
    firstSection: {
        width: '100%',
    },
    secondSection: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        gap: 8,
        "& p": {
            margin: 0,
        }
    },
    textMessage: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        "& p strong": {
            fontWeight: 700,
            color: '#0B2033'
        },
        "& p": {
            fontWeight: 400,
            color: '#0B2033'
        },
        "& > p:first-child": {
            fontWeight: 600,
            fontSize: 20,
            color: '#0171E6'
        },
    },
    signature: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        // height: 64
    },
    buttonSection: {
        width: '100%',
        marginTop: 18,
        "& > button": {
            display: "flex",
            height: 48,
            padding: "10px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            width: "100%",
            backgroundColor: "#0171E6",
            borderRadius: 8,
            "& > span": {
                color: "#fff",
                fontSize: 16,
                fontWeight: 600
            }
        }
    }
}));
export default useStyle;