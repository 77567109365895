import { createContext, useState, useContext, ReactNode } from 'react';

interface ToggleSwitchContextType {
    isToggleOn: boolean;
    setIsToggleOn?: (isToggleOn: boolean) => void;
    isLoadingFile: boolean;
    setIsLoadingFile: (isLoadingFile: boolean) => void;
    toggleSwitch: () => void;
}

const ToggleSwitchContext = createContext<ToggleSwitchContextType | undefined>(undefined);

export const ToggleSwitchProvider = ({ children }: { children: ReactNode }) => {
    const [isToggleOn, setIsToggleOn] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

    const toggleSwitch = () => {
        setIsToggleOn(!isToggleOn);
    };

    return (
        <ToggleSwitchContext.Provider
            value={{ isToggleOn, setIsToggleOn, isLoadingFile, setIsLoadingFile, toggleSwitch }}
        >
            {children}
        </ToggleSwitchContext.Provider>
    );
};

export const useToggleSwitch = (): ToggleSwitchContextType => {
    const context = useContext(ToggleSwitchContext);
    if (!context) {
        throw new Error('useToggle must be used within a ToggleProvider');
    }
    return context;
};
