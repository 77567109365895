import { Color, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { variant } from "styled-system";

const useStyles = makeStyles<
  Theme,
  {
    color?: Partial<Color>;
    variant: "text" | "outlined" | "contained";
    colorIdx?: any;
  }
>(({ palette }) => ({
  root: {
    boxShadow: "none",
    fontWeight: 600,
    letterSpacing: "0",
    whiteSpace: "nowrap",
    fontFamily: "Source Sans Pro",
    padding: "8px 16px",
    borderRadius: 4,
    height: 42,
    textTransform: "none",
  },
  contained: {
    "&.MuiButton-root": {
      color: "white",
      boxShadow: "none",
      borderRadius: 8,
      padding: "10px 24px",
      height: 40,
      fontWeight: 600,
      fontSize: 16,
      fontFamily: "Source Sans Pro",
      backgroundColor: "#0171E6",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#0062C8",
        padding: "10px 24px",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "#0056B0",
        padding: "10px 24px",
        boxShadow: "none",
      },
    },
  },
  text: {
    "&.MuiButton-text": {
      fontFamily: "Source Sans Pro",
      fontWeight: 600,
      justifyContent: "center",
      alingItems: "center",
      padding: "10px 24px",
      color: "#0171E6 !important",
      fontSize: 16,
      borderRadius: 8,
      display: "flex",
      textTransform: "none",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#DCEDFF",
      },
      "&:active": {
        backgroundColor: "#BADBFF",
      },
    },
  },
  outlined: {
    "&.MuiButton-outlined": {
      fontWeight: 600,
      fontFamily: "Source Sans Pro",
      color: "#0171E6 !important",
      backgroundColor: "white",
      fontSize: 16,
      borderRadius: 8,
      padding: "10px 24px",
      textTransform: "none",
      height: 40,
      border: "1px solid #0171E6 !important",
      "&:hover": {
        backgroundColor: "#DCEDFF",
        color: "#0171E6",

      },
      "&:active": {
        border: "2.5px solid #0171E6",
        backgroundColor: "white",
        color: "#0171E6",

      },
    },
  },
  disabled: {
    "&.MuiButton-root": {
      fontWeight: 600,
      fontFamily: "Source Sans Pro",
      padding: "10px 24px",
      textTransform: "none",
      color: "#ACAEC4 !important",
      backgroundColor: ({ variant }) =>
        variant === "contained" ? "#E0E1E9" : "white",
      borderColor: ({ variant }) =>
        variant === "contained" || variant === "outlined"
          ? `#ACAEC4 !important`
          : "transparent",
    },
  },
}));

export default useStyles;
