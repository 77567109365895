import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    units: any[];
    setUnits: (v: any) => void;
};

const defaultValues: ContextProps = {
    units: [],
    setUnits: (v: any) => {},
};

type Props = {
    children: React.ReactNode;
};

const UnitsContext = createContext<ContextProps>(defaultValues);

export const UnitsProvider = ({ children }: Props) => {
    const [units, setUnits] = useState<any>(defaultValues.units);

    const value: ContextProps = useMemo(
        () => ({
            units,
            setUnits,
        }),
        [units, setUnits],
    );

    return <UnitsContext.Provider value={value}>{children}</UnitsContext.Provider>;
};

export const useUnitsContext = () => useContext(UnitsContext);
