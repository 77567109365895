import React from "react";
import { ThemeContext } from "styled-components";
import { rgba } from "polished";
import {
  ErrorOutline,
  CheckCircleOutline,
  InfoOutlined,
} from "@mui/icons-material";
import { Theme } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles<Theme, { variant: Variant; color: any, textColor: string }>(() => ({
  root: {
    maxWidth: "100%",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    padding: "16px",
    fontSize: "14px",
    transition: "transform 0.3s eease-out",
    transform: "scaleY(1)",
    transformOrigin: "top",
    marginBottom: "16px",
    color: ({ variant, color, textColor }) => (variant == "solid" ? textColor ? textColor : "white:" : color[400]),
    backgroundColor: ({ variant, color }) =>
      variant == "solid" ? color[400] : rgba(color[400], 0.15),
  },
}));

type Variant = "solid" | "light";

type AlertType = "error" | "warning" | "success" | "info";

interface AlertProps {
  variant: Variant;
  type: AlertType;
  hidden?: boolean;
  textColor?: string;
  children: React.ReactNode;
}

export function AlertBanner({ variant, type, textColor, children }: AlertProps) {
  const theme = React.useContext(ThemeContext);
  const types = {
    error: {
      color: theme.colors.Ma,
      IconComponent: ErrorOutline,
    },
    warning: {
      color: {
        400: "#E09600",
      },
      IconComponent: InfoOutlined,
    },
    success: {
      color: theme.colors.Lu,
      IconComponent: CheckCircleOutline,
    },
    info: {
      color: {
        400: "#DDF7F8"
      },
      IconComponent: InfoOutlined
    }
  };

  const { IconComponent, color } = types[type];
  const classes = useStyles({ variant, color, textColor });
  return (
    <div className={classes.root}>
      <IconComponent style={{ marginRight: 16 }} />
      {children}
    </div>
  );
}

export default AlertBanner;
