import React from "react";
import styled, { css, keyframes } from "styled-components";
import { rem } from "polished";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

export interface SpinnerProps {
  label: string;
  placement: "vertical" | "horizontal";
  mode?: "light" | "dark";
  size?: number;
}


const spinnerAnimation=keyframes`
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
`;

 export const CustomCircularProgress=styled.div`
  color: ${props=>props.theme.colors.Ra[400]};
  font-size: 4px; 
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: ${spinnerAnimation} 1.3s infinite linear;
  animation: ${spinnerAnimation} 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

`;



const Container = styled.div<{ placement: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.placement === "vertical" &&
    css`
      flex-direction: column;
    `}
`;

const StyledCircularProgress = styled(({ ...props }) => (
  <CircularProgress {...props} classes={{ colorPrimary: "colorPrimary" }} />
))`
  &.colorPrimary {
    color: ${(props) => props.theme.colors.G["900"]};
  }
`;

const StaticCircularProgress = styled(({ ...props }) => (
  <CircularProgress {...props} variant="static" />
))`
  position: absolute;
  color: ${(props) =>
    props.mode === "dark" ? props.theme.colors.S["400"] : "white"};
  z-index: -1;
`;

const Label = styled(Typography)<{ placement: string; mode: string }>`
  color: ${(props) =>
    props.mode === "dark" ? props.theme.colors.S["700"] : "white"};

  ${(props) =>
    props.mode === "dark" &&
    css`
      font-weight: 300;
    `}
  ${(props) =>
    props.placement === "vertical"
      ? css`
          margin-top: ${rem("5px")};
          font-size: ${rem("12px")};
        `
      : css`
          margin-left: ${rem("10px")};
          font-size: ${rem("11px")};
        `}
`;

export function Spinner({
  label,
  placement,
  mode = "dark",
  size,
}: SpinnerProps) {
  return (
    <Container placement={placement} aria-label="loading">
      <div>
        <StaticCircularProgress
          size={size ?? placement === "horizontal" ? 32 : 50}
          value={100}
          {...{ mode }}
        />
        <StyledCircularProgress
          color="primary"
          size={size ?? placement === "horizontal" ? 32 : 50}
        />
      </div>
      {label !== undefined && <Label {...{ placement, mode }}>{label}</Label>}
    </Container>
  );
}

Spinner.defaultProps = {
  label: undefined,
  placement: "vertical",
};
