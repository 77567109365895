import React from 'react';
import { IToastProps } from '../interfaces/Toast.interface'
import style from './ToastWrapper.module.css'

type Props = {
    type: IToastProps["type"];
    message: IToastProps["message"];
    children: React.ReactNode
}

const ToastWrapper = ({type, message, children}: Props) => {
    return (
    <div className={`${style['container']} ${style[`container-${type}`]} ${!message.description && style['single-line']}`}>
        {children}
    </div>
    )
}

export default ToastWrapper;