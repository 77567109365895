// internal component
import { AdminTableHead, AdminTableBody } from '.';
import { AdminTableProps } from './AdminTable.interface';

// mui v5
import { Table, TableContainer, Paper } from '@mui/material';

// style
import style from './AdminTable.module.css';

const AdminTable = ({
  sorts,
  recordset,
  columns,
  tag,
  disablePaper,
  showSkeleton,
  onClickRow,
  sortData,
  setSelectedClonePos,
  modal,
}: AdminTableProps) => {
  return (
    <TableContainer
      component={!disablePaper && Paper}
      className={style['table-container']}
    >
      <Table>
        <AdminTableHead
          sorts={sorts}
          columns={columns}
          showSkeleton={showSkeleton}
          sortData={sortData}
        />
        <AdminTableBody
          recordset={recordset}
          tag={tag}
          showSkeleton={showSkeleton}
          onClickRow={onClickRow}
          setSelectedClonePos={setSelectedClonePos}
          modal={modal}
        />
      </Table>
    </TableContainer>
  );
};

export default AdminTable;
