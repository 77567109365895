import styled from "styled-components";
import { rem } from "polished";
import Link from "next/link";
export const Wrapper = styled.div`
  display: flex;
  font-size: ${rem("14px")};
  color: white;
  align-items: center;

  a {
    color: white;
  }
`;

export const Divider = styled.span`
  padding: 0 10px;
`;

export function TermsAndPrivacy() {
  
  return (
    <Wrapper>
      <Link
        href={{
          pathname: "/login/terms",
          query: { page: 0 },
        }}
      >
        Términos y Condiciones
      </Link>
      <Divider>|</Divider>
      <Link
        href={{
          pathname: "/login/terms",
          query: { page: 1 },
        }}
      >
        Políticas de Privacidad
      </Link>
    </Wrapper>
  );
}
