import useSWR from 'swr';

export const useDrugManufacturersByOrderId = (orderId) => {
    const { data: drugManufacturersResponse, mutate: mutateDrugManufacturers } = useSWR(
        orderId !== undefined ? `api/orders/${orderId}/drugManufacturers` : null,
        {
            revalidateOnFocus: false, // No revalidar al recuperar el foco
            dedupingInterval: 300000, // 5 minutos para evitar solicitudes duplicadas
            shouldRetryOnError: false, // No reintentar en caso de error
        },
    );
    return { drugManufacturersResponse, mutateDrugManufacturers };
};
