import React from "react";
import { getApiCredentialInvalid } from "../../api";

export const useApiInvalidCredential = (pointOfSaleId) => {
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [reFetch, setReFetch] = React.useState(false);

  const fetchData = async () => {
    try {
      if (pointOfSaleId) {
        const responseApiCredentialInvalid = await getApiCredentialInvalid(pointOfSaleId);
        setData(responseApiCredentialInvalid?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setData(null);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [pointOfSaleId]);

  React.useEffect(() => {
    if (reFetch) {
      fetchData();
      setReFetch(false);
    }
  }, [reFetch]);

  return {
    invalidApiCredentials: data,
    isLoading: isLoading,
    isEmpty: Array.isArray(data?.data) && data?.data.length === 0,
    reFetch: () => setReFetch(true),
  };
};
