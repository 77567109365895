// components
import { DrawerAlertTitle } from '../DrawerAlertTitle';
import { CheckboxAlert } from '../CheckboxAlert';
// rsuite
import { Form } from 'rsuite';

const DrawerAlertTypeAlert = ({
    setAlertType,
    alertType,
    defaultValue,
    setFormChanged,
    setIsFormRequired,
    isFormRequired,
}) => {
    return (
        <Form.Group controlId="alert-type">
            <Form.ControlLabel>
                <DrawerAlertTitle title="Tipo de alerta" />
            </Form.ControlLabel>
            <CheckboxAlert
                setAlertType={setAlertType}
                alertType={alertType}
                defaultValue={defaultValue}
                setFormChanged={setFormChanged}
                setIsFormRequired =  { setIsFormRequired }
                isFormRequired = { isFormRequired }
            />
        </Form.Group>
    );
};

export default DrawerAlertTypeAlert;
