import { Color, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { rem } from "polished";
const useStyles = makeStyles<Theme, { color?: Partial<Color> }>((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  text: {
    marginTop: 10,
    fontSize: `${rem("20px")}`,
    color: ({ color }) => color[900],
    margin: `${rem("40px")} 0 ${rem("32px")} 0`,
    textAlign: "center",
    fontWeight: 600,
  },
}));

export default useStyles;
