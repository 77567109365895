// ModalContext.js
import React, { createContext, useContext, useState } from 'react';

const ModalContextCdO = createContext(null);

export const ModalProviderCdO = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalContextCdO.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </ModalContextCdO.Provider>
  );
};

export const useModalCdO = () => {
  return useContext(ModalContextCdO);
};