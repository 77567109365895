import React from 'react';
import { Form, Input } from 'rsuite';
import style from './DescriptionAlert.module.css';
import { auxiliary } from './utils';

const DescriptionAlert = ({
    setDescription,
    description,
    descriptionDefaultValue,
    titleDefaultValue,
    setFormChanged,
    setIsFormRequired,
    isFormRequired,
}) => {
    const actions = auxiliary();

    return (
        <Form.Group>
            <span className={style['form-data']}>Mensaje</span>
            <Form.ControlLabel style={{ marginTop: 16 }}>
                <span className={style['placeholder']}>Título</span>
                <span className={style['required']}>*</span>
            </Form.ControlLabel>
            <Input
                onChange={(evt) =>
                    actions.handlerDescriptions(
                        evt,
                        'title',
                        setDescription,
                        setFormChanged,
                        setIsFormRequired,
                        isFormRequired
                    )
                }
                className={description?.title?.length === 50 ? style['text-error-input'] : style['text-input']}
                placeholder="Ingresá un titulo"
                defaultValue={titleDefaultValue}
                name="title"
                maxLength={50}
            />
            <p className={style['counter-characters']}>{description?.title?.length}/50</p>

            <Form.ControlLabel style={{ marginTop: 16 }}>
                <span className={style['placeholder']}>Descripción</span>
                <span className={style['required']}>*</span>
            </Form.ControlLabel>
            <Input
                as="textarea"
                rows={3}
                className={description?.description?.length === 150 ? style['text-error-input'] : style['text-input']}
                placeholder="Ingresá en neutro, una descripción corta y clara."
                onChange={(evt) =>
                    actions.handlerDescriptions(
                        evt,
                        'description',
                        setDescription,
                        setFormChanged,
                        setIsFormRequired,
                        isFormRequired
                    )
                }
                defaultValue={descriptionDefaultValue}
                name="description"
                maxLength={150}
            />
            <p className={style['counter-characters']}>{description?.description?.length}/150</p>
        </Form.Group>
    );
};

export default DescriptionAlert;
