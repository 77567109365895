// modules
import { useEffect, useRef, useState } from 'react';
// component
import Tooltip from 'components/Tooltip';
import { PointsOfSaleModal, StatusIcon, StatusUser } from '.';
// mui v5
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid, Skeleton, TableCell } from '@mui/material';
// role type
import { Role } from 'api/types';

// date-fns
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
// style
import useStyles from '../../../../commons/modules/backoffice/clients/clientSummary/tabPanel/clientDetails.styles';
// utils
import {
    ariaLabelClient,
    ariaLabelClientSearch,
    ariaLabelPOS,
    ariaLabelPosSearch,
    ariaLabelUser,
    ariaLabelUserSearch,
    ariaNewLabelPOS,
    ariaNewLabelClient,
    ariaNewLabelClientSearch,
} from './utils';
import { useUser } from 'utils/hooks';
import { AvatarGroup, Avatar, Button, Popover, Whisper } from 'rsuite';
import stringAvatar from 'utils/avatar-name';
import style from './AdminTableBodyCell.module.css';
import { Status } from './StatusIcon/StatusIcon.interface';
import { EX5057, EX5221 } from 'config/flags';
import { COUNTRY } from 'utils/country';
// enum

const AdminTableBodyCell = ({ items, rest, tag, visibilityWithHover, showSkeleton, setSelectedClonePos, modal }) => {
    const classes = useStyles();
    const { user, userLocaleCountryCode } = useUser({});
    const EX5796 = user?.EX5796;
    const [isHovered, setIsHovered] = useState(false);
    const [spanReference, setSpanReference] = useState(null);
    const spanRef = useRef(null);
    useEffect(() => {
        setSpanReference(spanRef);
    }, [spanReference]);

    const filterKeysBasedOnInput = (inputKeys, availableKeys) => {
        return availableKeys.filter((item) => inputKeys.includes(item));
    };

    const reorderArray = (array) => {
        const desiredOrder = [
            'id',
            'name',
            'email',
            'client_type_name',
            'country',
            'points_of_sale_qty',
            'created_at',
            'fake_2',
            'fake_3',
            'fake_4',
        ];
        return desiredOrder.filter((key) => array.includes(key));
    };

    let elementsToRetrieve;
    switch (tag) {
        case 'clients': {
            const item = filterKeysBasedOnInput(!!EX5796 ? ariaNewLabelClient : ariaLabelClient, items);
            const reorderedItem = reorderArray(item);
            const mapping = !!EX5796 ? reorderedItem : item;
            return (elementsToRetrieve = mapping.map((key) => {
                let cellContent;

                switch (key) {
                    case 'id':
                        cellContent = `# ${rest[key]}`;
                        break;
                    case 'created_at':
                        cellContent = format(new Date(rest[key]), 'dd MMMM yyyy', {
                            locale: es,
                        });
                        break;
                    default:
                        cellContent = rest[key];
                        break;
                }

                return (
                    <TableCell key={`cell_${key}`} aria-label={`cell_${key}`} align="left">
                        {cellContent}
                    </TableCell>
                );
            }));
        }
        case 'pos': {
            const item = filterKeysBasedOnInput(!!EX5221 ? ariaNewLabelPOS : ariaLabelPOS, items);
            const cells = item.map((key) => {
                let cellContent;
                switch (key) {
                    case 'id':
                        cellContent = `# ${rest[key]}`;
                        break;
                    case 'name':
                        cellContent = !!rest?.clone?.reference ? (
                            <div>
                                <p style={{ color: '#ACACAC' }}>{rest.name}</p>
                                <span>{rest?.clone?.reference}</span>
                            </div>
                        ) : (
                            <p>{rest.name}</p>
                        );
                        break;
                    case 'created_at':
                        cellContent = format(new Date(rest[key]), 'dd MMMM yyyy', {
                            locale: es,
                        });
                        break;
                    case 'drugManufacturers': {
                        const max = window.matchMedia('(max-width: 950px)').matches ? 2 : 4;

                        cellContent = (
                            <AvatarGroup stack spacing={6}>
                                {Array.isArray(rest[key]) && rest[key].length > 0 ? (
                                    <>
                                        {rest[key]
                                            ?.filter((drugManufacturer, i) => i < max)
                                            .map(({ id, name, format_status }) => {
                                                return (
                                                    <div className={style['tooltip-container']}>
                                                        <Avatar circle className={style['avatars']}>
                                                            <span className={style['text-avatar']}>
                                                                {stringAvatar(name, false, null)}
                                                            </span>
                                                        </Avatar>
                                                        <span className={style['tooltip-text']}>{name}</span>
                                                    </div>
                                                );
                                            })}

                                        {Array.isArray(rest[key]) && rest[key]?.length > max && (
                                            <Whisper
                                                placement="bottom"
                                                speaker={
                                                    <Popover>
                                                        {rest[key]
                                                            .filter((drugManufacturer, i) => i >= max)
                                                            .map((drugManufacturer, index) => {
                                                                return (
                                                                    <>
                                                                        <div className={style['container-n-avatars']}>
                                                                            <Avatar circle className={style['avatars']}>
                                                                                <span className={style['text-avatar']}>
                                                                                    {stringAvatar(
                                                                                        drugManufacturer?.name,
                                                                                        false,
                                                                                        null,
                                                                                    )}
                                                                                </span>
                                                                            </Avatar>
                                                                            <p
                                                                                className={
                                                                                    style['text-avatar-n-droguerias']
                                                                                }
                                                                            >
                                                                                {drugManufacturer.name}
                                                                            </p>
                                                                        </div>
                                                                        <hr className={style['line-divider']} />
                                                                    </>
                                                                );
                                                            })}
                                                    </Popover>
                                                }
                                                //open={true}
                                            >
                                                <Avatar circle size="md" className={style['avatar-component-all']}>
                                                    {`+${rest[key].slice(max).length} ${
                                                        rest[key].slice(max).length === 1 ? 'droguería' : 'droguerías'
                                                    }`}
                                                </Avatar>
                                            </Whisper>
                                        )}
                                    </>
                                ) : (
                                    <div className={style['container']}>
                                        <Button
                                            className={style['container-button']}
                                            appearance="ghost"
                                            startIcon={<img className={style['icon']} src="/assets/images/power.svg" />}
                                        >
                                            <span>Conectar droguerías</span>
                                        </Button>
                                    </div>
                                )}
                            </AvatarGroup>
                        );
                        break;
                    }
                    case 'clone': {
                        const isPosHasClone = () => {
                            return rest?.hasClone === true && rest?.clone === null;
                        };
                        const isPosClone = () => {
                            return rest?.hasClone === false && rest?.clone !== null;
                        };
                        const handleClickOpenCloneModal = (event) => {
                            event.stopPropagation();
                            modal.toggle();
                            setSelectedClonePos({ id: rest.id, name: rest.name });
                        };
                        const handleClonePosIcon = () => {
                            return <img alt="contentCopy" src="/assets/images/contentCopy.svg" />;
                        };

                        cellContent = (
                            <div>
                                <Tooltip
                                    top="-10px"
                                    placement="bottom-end"
                                    label={<span style={{ fontSize: 14 }}>{'Duplicar punto de venta'}</span>}
                                    arrow={false}
                                    backgroundColor="#04273F"
                                >
                                    <IconButton
                                        className={classes.buttonClonPos}
                                        style={{
                                            borderRadius: '0%',
                                        }}
                                        onClick={handleClickOpenCloneModal}
                                    >
                                        {handleClonePosIcon()}
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                        break;
                    }
                    default:
                        cellContent = Array.isArray(rest[key]) ? <StatusIcon value={rest[key]} /> : rest[key];
                        break;
                }

                return (
                    <TableCell key={`cell_${key}`} aria-label={`cell_${key}`} align="left">
                        {cellContent}
                    </TableCell>
                );
            });

            return cells;
        }
        case 'user': {
            const item = filterKeysBasedOnInput(ariaLabelUser, items);

            const sortedKeys = item.slice().sort((a, b) => {
                return ariaLabelUser.indexOf(a) - ariaLabelUser.indexOf(b);
            });

            return (elementsToRetrieve = sortedKeys.map((key) => {
                let content;
                switch (key) {
                    case 'role_id': {
                        content = Role[rest[key]];
                        break;
                    }
                    case 'first_name': {
                        const fullName = `${rest.first_name || ''} ${rest.last_name || ''}`.trim();
                        content = fullName;
                        break;
                    }
                    case 'pointsOfSale': {
                        const pointsOfSaleCountByBuyer = rest[key].length;
                        const pointsOfSaleCountByManager = rest?.client?.points_of_sale_qty;

                        content = (
                            <span
                                ref={spanRef}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                {rest?.role_id === Role.Buyer ? pointsOfSaleCountByBuyer : pointsOfSaleCountByManager}
                                {isHovered && pointsOfSaleCountByBuyer > 0 && (
                                    <PointsOfSaleModal
                                        mappingName={rest[key]}
                                        open={isHovered}
                                        anchorEl={spanRef?.current}
                                        style={{ top: 45, overflowY: 'scroll', height: 'auto' }}
                                    />
                                )}
                            </span>
                        );
                        break;
                    }

                    default:
                        if (typeof rest[key] === 'string' || typeof rest[key] === 'number') {
                            content = key === 'id' ? `# ${rest[key]}` : rest[key];
                        } else if (typeof rest[key] === 'object' && rest[key] !== null) {
                            const { id } = rest[key];
                            if (id !== undefined) {
                                content = (
                                    <StatusUser
                                        statusId={id}
                                        userId={rest.id}
                                        visibilityWithHover={visibilityWithHover}
                                    />
                                );
                            }
                        } else if (Array.isArray(rest[key])) {
                            const filteredNames = rest[key]
                                .filter((item) => item && item.name !== undefined)
                                .map((item) => item.name)
                                .join(', ');
                            content = filteredNames || '';
                        }
                        break;
                }

                return (
                    <>
                        <TableCell key={`cell_${key}`} aria-label={`cell_${key}`} align="left">
                            {content}
                        </TableCell>
                    </>
                );
            }));
        }
        case 'userSearch': {
            const item = filterKeysBasedOnInput(ariaLabelUserSearch, items);

            const sortedKeys = item.slice().sort((a, b) => {
                return ariaLabelUserSearch.indexOf(a) - ariaLabelUserSearch.indexOf(b);
            });

            return (elementsToRetrieve = sortedKeys.map((key, index) => {
                return (
                    <>
                        <TableCell key={`cell_${key}_${index}`} aria-label={`cell_${key}_${index}`} align="left">
                            {showSkeleton ? (
                                <Skeleton variant="text" width={200} />
                            ) : key === 'status' && rest[key] !== null ? (
                                <StatusUser
                                    statusId={rest[key].id}
                                    userId={rest.id}
                                    visibilityWithHover={visibilityWithHover}
                                />
                            ) : key === 'client' && rest[key] !== null ? (
                                rest[key].name
                            ) : key === 'id' && rest[key] !== null ? (
                                `# ${rest[key]}`
                            ) : key === 'role_id' && rest[key] !== null ? (
                                Role[rest[key]]
                            ) : (
                                rest[key]
                            )}
                        </TableCell>
                        {sortedKeys?.length - 1 === index && (
                            <TableCell align="right">
                                {visibilityWithHover && <ArrowForwardIosIcon style={{ height: 15, width: 15 }} />}
                            </TableCell>
                        )}
                    </>
                );
            }));
        }
        case 'posSearch': {
            const item = filterKeysBasedOnInput(ariaLabelPosSearch, items);
            const sortedKeys = item.slice().sort((a, b) => {
                return ariaLabelPosSearch.indexOf(a) - ariaLabelPosSearch.indexOf(b);
            });

            return (elementsToRetrieve = sortedKeys.map((key, index) => {
                return (
                    <>
                        <TableCell key={`cell_${key}_${index}`} aria-label={`cell_${key}_${index}`} align="left">
                            {showSkeleton && key === 'created_at' ? (
                                <>
                                    <Grid container direction="row" alignItems="center">
                                        <Skeleton
                                            variant="circular"
                                            width={20}
                                            height={20}
                                            style={{ marginRight: 10 }}
                                        />
                                        <Skeleton variant="text" width={50} />
                                    </Grid>
                                </>
                            ) : showSkeleton && key !== 'created_at' ? (
                                <Skeleton variant="text" width={200} />
                            ) : !showSkeleton && key === 'created_at' ? (
                                format(new Date(rest[key]), 'dd MMMM yyyy', {
                                    locale: es,
                                })
                            ) : key === 'client' && rest[key] !== null ? (
                                rest[key].name
                            ) : key === 'id' && rest[key] !== null ? (
                                `# ${rest[key]}`
                            ) : (
                                rest[key]
                            )}
                        </TableCell>
                        {sortedKeys?.length - 1 === index && (
                            <TableCell align="right">
                                {visibilityWithHover && <ArrowForwardIosIcon style={{ height: 15, width: 15 }} />}
                            </TableCell>
                        )}
                    </>
                );
            }));
        }
        case 'clientSearch': {
            const item = filterKeysBasedOnInput(!!EX5796 ? ariaNewLabelClientSearch : ariaLabelClientSearch, items);
            const sortedKeys = item.slice().sort((a, b) => {
                return ariaLabelClientSearch.indexOf(a) - ariaLabelClientSearch.indexOf(b);
            });
            const reorderedItem = reorderArray(sortedKeys);

            const mapping = !!EX5796 ? reorderedItem : sortedKeys;

            return (elementsToRetrieve = mapping.map((key, index) => {
                return (
                    <>
                        <TableCell key={`cell_${key}_${index}`} aria-label={`cell_${key}_${index}`} align="left">
                            {showSkeleton && key === 'created_at' ? (
                                <>
                                    <Grid container direction="row" alignItems="center">
                                        <Skeleton
                                            variant="circular"
                                            width={20}
                                            height={20}
                                            style={{ marginRight: 10 }}
                                        />
                                        <Skeleton variant="text" width={50} />
                                    </Grid>
                                </>
                            ) : showSkeleton && key !== 'created_at' ? (
                                <Skeleton variant="text" width={200} />
                            ) : !showSkeleton && key === 'created_at' ? (
                                format(new Date(rest[key]), 'dd MMMM yyyy', {
                                    locale: es,
                                })
                            ) : key === 'id' && rest[key] !== null ? (
                                `# ${rest[key]}`
                            ) : (
                                rest[key]
                            )}
                        </TableCell>
                        {sortedKeys?.length - 1 === index && (
                            <TableCell
                                align="right"
                                key={`cell_${key}_${index}_arrow`}
                                aria-label={`cell_${key}_${index}_arrow`}
                            >
                                {visibilityWithHover && <ArrowForwardIosIcon style={{ height: 15, width: 15 }} />}
                            </TableCell>
                        )}
                    </>
                );
            }));
        }
        default:
            null;
            break;
    }

    return <>{elementsToRetrieve}</>;
};

export default AdminTableBodyCell;
