import { useState } from 'react';
// component

// style
import style from './StatusUser.module.css';
// mui v5
import { CheckCircleOutline, DeleteOutlineOutlined } from '@mui/icons-material';
// enum
import { UserStatus } from './StatusUser.interface';
// cookies
import Cookies from 'js-cookie';
// api & context
import { resendWelcomeEmail } from 'api';
import { useSnackbar } from 'context';
// material
import { IconButton } from '@material-ui/core';
import Grid from '@mui/material/Unstable_Grid2';
import { Tooltip, Typography } from '@mui/material';

const StatusUser = ({ statusId, userId, visibilityWithHover }) => {
  const snackbar = useSnackbar();
  
  const [errorBackend, setErrorBackend] = useState({});

  const checkDisabled = () => {
    let ids = Cookies.get('resendsId');
    let isDisabled = false;
    if (!!ids) {
      JSON.parse(ids).map((item) => {
        if (Number(item.userId) === Number(userId)) {
          isDisabled = true;
        }
      });
    }
    return isDisabled;
  };

  const resendEmail = async (userId) => {
    try {
      const resp = await resendWelcomeEmail(userId);
      if (resp?.status === 204) {
        snackbar.show({
          message: 'Se reenvió la invitación.',
        });
        const idInCookies = Cookies.get('resendsId');
        let newValues = [];
        if (!!idInCookies) {
          newValues = [...JSON.parse(idInCookies), { userId }];
        } else {
          newValues = [{ userId }];
        }
        Cookies.set('resendsId', JSON.stringify(newValues));
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        let errorValidate = error.response.data.data.validation;
        let errorBackendTmp = {};
        Object.keys(errorValidate).map((key) =>
          Object.assign(errorBackendTmp, {
            [key]: {
              status: true,
              message: errorValidate[key][0],
            },
          })
        );

        setErrorBackend(errorBackendTmp);
      } else {
        snackbar.show({
          message: 'Ocurrió un error en el sistema. Intente más tarde',
          error,
        });
      }
    }
  };

  const orderCheckDisabled = checkDisabled();

  return (
    <div className={style['container']}>
      <div className={style['wrapper-container']}>
        {statusId === UserStatus.PENDING_ACTIVATION ? (
          <Grid
            container
            justifyContent='space-around'
            rowSpacing={1}
            alignItems='center'
            spacing={5}
          >
            <Grid xs={7} display='flex'>
              <div
                className={style['wrapper-icon']}
                onClick={() => resendEmail(userId)}
              >
                <img
                  style={{ paddingLeft: 8 }}
                  src={
                    orderCheckDisabled
                      ? '/assets/images/redoDisabled.svg '
                      : '/assets/images/redo.svg'
                  }
                />

                <span
                  className={style['text-icon']}
                  style={{ color: orderCheckDisabled ? '#afafaf' : '' }}
                >
                  Reenviar
                </span>
              </div>
            </Grid>

            <Grid xs={4} display='flex'>
              {!!visibilityWithHover && visibilityWithHover === userId && (
                <Tooltip
                  title={
                    <Typography
                      style={{
                        color: '#FFFFFF',
                        fontSize: '14px',
                      }}
                    >
                      Eliminar
                    </Typography>
                  }
                >
                  <IconButton className={style['trash-icon-button']}>
                    <DeleteOutlineOutlined />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            justifyContent='space-around'
            rowSpacing={1}
            alignItems='center'
            spacing={5}
          >
            <Grid xs={7}>
              <div className={style['wrapper-icon']}>
                <CheckCircleOutline style={{ color: '#07ad4b', marginRight: 4 }} />
                <span className={style['text-icon']}>Activo</span>
              </div>
            </Grid>
            <Grid xs={4}></Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default StatusUser;
