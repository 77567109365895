import React, { useState } from "react";
import * as gtag from "utils/gtag";
import styled, { css } from "styled-components";
import { rem } from "polished";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

export interface CounterBoxProps {
  onChange(value: number): void;
  initialNumber: number;
  disabled?: boolean;
  disabledBtnLeft?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTextField = styled(({ ...props }) => (
  <TextField {...props} InputProps={{ classes: { input: "input" } }} />
))`
  & .input {
    width: ${rem("34px")};
    height: ${rem("24px")};
    padding: ${rem("1px")} ${rem("2px")};
    text-align: center;
    color: ${(props) => props.theme.colors.S["800"]};
    font-size: ${rem("14px")};
  }

  & .MuiInputBase-formControl {
    background-color: white;
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: ${rem("4px")};
  margin: ${rem("2px")};
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

const StyledIcon = css`
  color: ${(props) => props.theme.colors.B["900"]};
  font-size: ${rem("18px")};
`;

const StyledAddCircle = styled(AddCircle)`
  ${StyledIcon}
`;

const StyledRemoveCircle = styled(RemoveCircle)`
  ${StyledIcon}
`;

export function CounterBox({
  onChange,
  initialNumber,
  disabled,
  disabledBtnLeft,
}: CounterBoxProps) {
  const [counter, setCounter] = useState<number>(initialNumber);

  const setValue = (value: number) => {
    setCounter(value);
    onChange(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = new RegExp("^[0-9]*$");
    const { value } = event.target;
    if (value.length > 0 && regex.test(value)) {
      const number = parseInt(value, 10);
      setValue(number);
    } else if (value.length === 0) {
      setValue(0);
    }
  };

  const decreaseCounter = () => {
    if (counter > 0) {
      setValue(counter - 1);
      gtag.event({
        action: "click",
        category: "efficient-purchase__comparison",
        label: "counter-box__remove-product",
        value: 0,
      });
    }
  };

  const increaseCounter = () => {
    gtag.event({
      action: "click",
      category: "efficient-purchase__comparison",
      label: "counter-box__add-product",
      value: 0,
    });
    setValue(counter + 1);
  };

  const handleKeys = (event: KeyboardEvent) => {
    const upKey = 38;
    const downKey = 40;
    if (event.keyCode === upKey) {
      increaseCounter();
    } else if (event.keyCode === downKey) {
      decreaseCounter();
    }
  };

  return (
    <Container>
      <StyledIconButton
        onClick={decreaseCounter}
        disabled={counter === 0 || disabledBtnLeft || disabled}
        
      >
        <span className="counter-box__remove-product-comparison"><StyledRemoveCircle fontSize="inherit" color="inherit" /></span>
      </StyledIconButton>
      <StyledTextField
        variant="outlined"
        value={counter}
        onChange={handleChange}
        onKeyDown={handleKeys}
        inputProps={{
          maxLength: 6,
        }}
        disabled={disabled}
      />
      <StyledIconButton
        onClick={increaseCounter}
        disabled={disabled}
        
      >
        <span  className="counter-box__add-product-comparison"><StyledAddCircle fontSize="inherit" color="inherit" /></span>
      </StyledIconButton>
    </Container>
  );
}
