import { rem } from "polished";
import { TextField as MuiTextField } from "@material-ui/core";
import styled from "styled-components";

export const UnderlineTextError = styled.label`
  background-color: red;
  border-radius: ${rem("8px")};
  padding: ${rem("4px")};
  font-size: ${rem("12px")};
  color:white;
`;

export const UnderlineTextField = styled(MuiTextField)`
  input {
    font-size: ${rem("14px")};
    color: white;
    padding: ${rem("12px")} 0;

    ::placeholder {
      font-size: ${rem("14px")};
      color: white;
    }
  }

  .MuiInputBase-input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #04273f inset;
    -webkit-text-fill-color: #fff;
    font-size: ${rem("14px")};
  }

  .MuiInputLabel-root {
    color: white;
    font-size: ${rem("14px")};
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #f7fafc !important;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid white !important;
  }

  width: ${rem("320px")};
`;
