// ModalContext.js
import React, { createContext, useContext, useState } from 'react';

const ModalContextWithoutStock = createContext(null);

export const ModalProviderWithoutStock = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderWithoutStock, setOrderWithoutStock] = useState(false);

    const openModal = () => {
        setOrderWithoutStock(true);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <ModalContextWithoutStock.Provider value={{ isModalOpen, openModal, closeModal, orderWithoutStock }}>
            {children}
        </ModalContextWithoutStock.Provider>
    );
};

export const useModalWithoutStock = () => {
    return useContext(ModalContextWithoutStock);
};
