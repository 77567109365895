import React from "react";

import { existsProductInCartById, updateProductCartById } from "utils/cart";

import { filterSearch } from "utils/filterFunction";
import { apiPharmaInstance } from "utils/axios";

interface Product {
  id: number;
  code: number;
  description: string;
  quantity: number;
}

export interface SearchProductProviderProps {
  children: React.ReactNode;
  productsInCart: any[];
  currentSearch: string;
  setCurrentSearch: any;
  lastSearch: any[];
  setLastSearch: any;
  fetchProducts: any[];
  setFetchProducts: any;
  isLoading: boolean;
  setIsLoading: any;
  listedProducts: any;
  addProductInCart: (product: Product) => void;
  updateProductQuantity: (code: number, quantity: number) => void;
  existsProductInCart: (code: number) => boolean;
  searchDataLength: () => number;
  fetchData: (
    currentValue: string,
    page: number,
    size: number,
    isReturnResult: boolean
  ) => void;
  cleanSearchData: () => void;
  pagination: any;
  searchData: any;
  results: any;
  setResults: (value: any) => void;
  isUpdateResult: boolean;
  setIsUpdateResult: (value: any) => void;
}

const SearchProductContext = React.createContext<
  Omit<SearchProductProviderProps, "children">
>(null);

export function SearchProductProvider({ children, ...props }) {
  const [productsInCart, setProductsInCart] = React.useState([]);
  const [lastSearch, setLastSearch] = React.useState([]);
  const [fetchProducts, setFetchProducts] = React.useState([]);
  const [listedProducts, setListedProducts] = React.useState([]);
  const [currentSearch, setCurrentSearch] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchData, setSearchData] = React.useState([]);
  const [pagination, setPagination] = React.useState<any | null>(null);
  const [results, setResults] = React.useState([]);
  const [isUpdateResult, setIsUpdateResult] = React.useState(false);
  const fetchData = async (
    currentValue,
    page = 1,
    size = 20,
    isReturnResult = false
  ) => {
    setIsLoading(true);

    try {

      const posId = JSON.parse(localStorage.getItem('pdvSelected')).id;

      const {
        data: response,
      } = await apiPharmaInstance.post(
        `/api/points-of-sale/${posId}/search-products`,
        { search: currentValue, size, from: page }
      );
      
      setSearchData(response.data.hits);
      setPagination(response.meta.pagination);
      if (!!isReturnResult) setResults(response.data.hits);

      setIsUpdateResult(false);
      setIsLoading(false);
    } catch (error) {
      const statusCode = [500, 400, 404];
      if (statusCode.includes(error.response.status)) {
        setIsLoading(false);
        setSearchData([]);
        setPagination([]);
      }
    }
  };

  const searchDataLength = () => {
    return searchData?.length;
  };

  const cleanSearchData = () => {
    setSearchData([]);
    setListedProducts([]);
  };

  const addProductInCart = (product: Product) => {
    setProductsInCart([...productsInCart, product]);
  };

  const updateProductQuantity = (code: number, quantity: number) => {
    const newArray: any[] = updateProductCartById(
      productsInCart,
      code,
      quantity
    );
    setProductsInCart(newArray);
  };

  const updateLastSearchFromLocalStorage = () => {
    const lastSearchFromLocalStorage = JSON.parse(
      localStorage.getItem("lastSearch")
    );
    if (!lastSearchFromLocalStorage) {
      localStorage.setItem("lastSearch", JSON.stringify([]));
    }
    setLastSearch(lastSearchFromLocalStorage);
  };

  // VALIDAR SI EXISTE UN PRODUCTO POR ID
  const existsProductInCart = (code: number) => {
    const existProductInCart = existsProductInCartById(productsInCart, code);
    return existProductInCart;
  };
  
  React.useEffect(() => {
    updateLastSearchFromLocalStorage();
  }, []);

  React.useEffect(() => {
    if (currentSearch.trim() !== "") {
      fetchData(currentSearch);
    }
  }, [currentSearch]);

  React.useEffect(() => {
    const filterSearchData = filterSearch(searchData, currentSearch);
    setListedProducts(filterSearchData);
  }, [searchData]);

  

  return (
    <SearchProductContext.Provider
      value={{
        productsInCart,
        currentSearch,
        setCurrentSearch,
        lastSearch,
        setLastSearch,
        fetchProducts,
        setFetchProducts,
        addProductInCart,
        updateProductQuantity,
        existsProductInCart,
        isLoading,
        setIsLoading,
        fetchData,
        searchDataLength,
        cleanSearchData,
        listedProducts,
        pagination,
        searchData,
        results,
        setResults,
        isUpdateResult,
        setIsUpdateResult,
        ...props,
      }}
    >
      {children}
    </SearchProductContext.Provider>
  );
}

export function useSearchProduct() {
  const context = React.useContext(SearchProductContext);

  if (context === undefined) {
    throw new Error(
      "useSearchProductContext must be used within a ConfirmationProvider"
    );
  }

  return context;
}
