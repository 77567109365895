import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    selectAllState: number | null;
    setSelectAllState: (v) => void;
};

const defaultValues: ContextProps = {
    selectAllState: null,
    setSelectAllState: (v: number | null) => {},
};

type Props = {
    children: React.ReactNode;
};

const SelectAllStateContext = createContext<ContextProps>(defaultValues);

export const SelectAllStateProvider = ({ children }: Props) => {
    const [selectAllState, setSelectAllState] = useState(defaultValues.selectAllState);

    const value: ContextProps = useMemo(
        () => ({
            selectAllState,
            setSelectAllState,
        }),
        [selectAllState, setSelectAllState],
    );

    return <SelectAllStateContext.Provider value={value}>{children}</SelectAllStateContext.Provider>;
};

export const useSelectAllStateContext = () => useContext(SelectAllStateContext);
