import { Role } from "common/types";

export function objectToQueryParams(object: any, prefix?: any) {
  if (object) {
    let str = [];
    let p;
    for (p in object) {
      if (object.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = object[p];
        str.push(
          v !== null && typeof v === "object"
            ? objectToQueryParams(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  }
  return "";
}

export function getEnumKeyByEnumValue(myEnum, enumValue) {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
}

export function getRoleById(id) {
  return getEnumKeyByEnumValue(Role, id)?.toLowerCase();
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function escapePercentage(string) {
  return string.replace("%", "");
}

export function isEmptyValue(value) {
  if(
    value === null ||
    value === undefined ||
    value === "NULL" ||
    value.trim() === ""
  ) {
    return true;
  }else {
    return false;
  }
};