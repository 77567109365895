import i18next from '../../config/i18n';
import { useEffect } from 'react';

const useChangeLanguage = (countryCode) => {
    useEffect(() => {
        const changeLanguage = async () => {
            try {
                switch (countryCode) {
                    case 'AR': {
                        await i18next.changeLanguage('es-AR');
                        break;
                    }
                    case 'MX': {
                        await i18next.changeLanguage('mx');
                        break;
                    }
                    case 'CO': {
                        await i18next.changeLanguage('co');
                        break;
                    }
                    case 'ES': {
                        await i18next.changeLanguage('es-ES');
                        break;
                    }
                    case 'BR': {
                        await i18next.changeLanguage('pt-BR');
                        break;
                    }
                    case 'US': {
                        await i18next.changeLanguage('en-US');
                        break;
                    }
                    // by default use code 'es' because it's the ES-NEUTRO example
                    default: {
                        await i18next.changeLanguage('es');
                        break;
                    }
                }
            } catch (ex) {
                console.error('Error al cambiar de idioma', ex.message);
            }
        };

        changeLanguage();
    }, [countryCode]);
};

export default useChangeLanguage;
