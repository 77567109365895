import { apiPharmaInstance } from "utils/axios";

export const FindUserByToken = async (token) => {

  const tokenUserValue = token ? `/api/users/find/${token}` : ""

  try {
    const res = await apiPharmaInstance.get(tokenUserValue);
    return res?.data?.data
  } catch (error) {
    if (error.response) {
      return error.response.status;
    }
  }
};

export const ClientSideCreatePassword = async (
  username,
  email,
  password ,
  token ,
  id
) => {
  const body = {
    username:username,
    email:email,
    password: password,
    token: token,
  };
  try{
    return await apiPharmaInstance.patch(`/api/users/${id}/password/create`, body);

  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};
