export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
export const GA4_TRACKING_ID = process.env.NEXT_PUBLIC_GA4_TRACKING_ID;
export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

declare global {
  interface Window {
    gtag: any;
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
  if (typeof window === "undefined" || !window?.gtag) return;

  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });

  window.gtag("config", GA4_TRACKING_ID, {
    page_path: url,
  });

  window.gtag("config", GOOGLE_TAG_MANAGER_ID, {
    page_path: url,
  });
};

type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
  if (typeof window === "undefined" || !window?.gtag) return;
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

