import React from 'react';

export interface SkeletonModalProps {
 
  loadingSkeleton: boolean;
  setLoadingSkeleton: (loadingSkeleton: boolean) => void;

}

const SkeletonContext = React.createContext<Omit<SkeletonModalProps, "children">>(
  null
);


export const SkeletonLoaderModal = ({ children }) => {
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(false);

   return (
    <SkeletonContext.Provider
      value={{
        setLoadingSkeleton, loadingSkeleton
      }}
    >
      {children}
    </SkeletonContext.Provider>
  );
 
}

export function useSkeletonModal() {
  const context = React.useContext(SkeletonContext);
  if (context === undefined) {
    throw new Error("usePos must be used within a PosProvider");
  }
  return context;
}

