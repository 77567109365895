// react
import { createContext, useContext, useState } from 'react';
// date fns
import { subDays } from 'date-fns';
// utils
import { parseDate } from '@commons/modules/Orders/Orders.helper';

const FiltersContext = createContext(null);

export const FiltersProvider = ({ children }) => {
  // initial state dateTerms
  const initialDateTerms = {
    from: parseDate(subDays(new Date(), 6)),
    to: parseDate(new Date()),
  };
  const [listTagTerms, setListTagTerms] = useState([]);
  const [listDrugTerms, setListDrugTerms] = useState([]);
  const [searchTerms, setSearchTerms] = useState('');
  const [dateTerms, setDateTerms] = useState(initialDateTerms);

  return (
    <FiltersContext.Provider
      value={{
        listTagTerms,
        setListTagTerms,
        listDrugTerms,
        setListDrugTerms,
        searchTerms,
        setSearchTerms,
        dateTerms,
        setDateTerms
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = () => useContext(FiltersContext);
