import { allPointsOfSale } from "api";
import React, { useState, useEffect } from "react";

interface PointsOfSaleProps {
  rows?: number;
  page?: number;
  search?: any;
}

export const usePointsOfSale = ({ rows, page, search }: PointsOfSaleProps) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [reFetch, setReFetch] = React.useState(false);

  const fetchData = async () => {
    try {
      const response = await allPointsOfSale(search, page, rows);
      const newArr = response.data.data.map((item) => ({
        fake_1: "",
        fake_2: "",
        ...item,
      }));
      setData({ data: newArr });
      setIsLoading(false);
    } catch (error) {
      setData(null);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, [search]);

  useEffect(() => {
    if (reFetch) {
      fetchData();
      setReFetch(false);
    }
  }, [reFetch]);

  return {
    posData: data,
    isLoading: isLoading,
    reFetch: () => setReFetch(true),
  };
};
