import React from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core";

export interface TextFieldProps extends Omit<MuiTextFieldProps, "variant"> {
  dirty?: "true" | "false";
  arrowHidden?: boolean;
  colors?: any;
  cursor?: "pointer" | "";
}

export const TextField = styled((props: TextFieldProps) => (
  <MuiTextField fullWidth variant="outlined" size="small" {...props} />
))`
  ${(props) =>
    props?.dirty === "true" &&
    css`
      .MuiInputBase-root::before {
        content: "";
        position: absolute;
        background-color: ${props.theme.colors.G[600]};
        width: ${rem("6px")};
        height: ${rem("6px")};
        border-radius: ${rem("6px")};
        left: -10px;
      }
    `}

  input {
    background:white;
    color: ${(props) =>
      props?.colors ? props.colors : props.theme.colors.S["800"]};
    cursor: ${(props) => props?.cursor};
    ::placeholder {
      color: ${(props) => props.theme.colors.S["600"]};
      opacity: 1;
    };
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .makeStyles-inputAdornment-7 {
    margin: 0 16px;
  }
  .MuiFormLabel-root.Mui-disabled{
    opacity: 1;
    color: #D1D1D1; 
  }
  .MuiInputBase-input.Mui-disabled {
    opacity: 1;
    color: #D1D1D1;
  }
  .MuiInputBase-root.Mui-disabled{
    opacity: 1;
    color: #D1D1D1;
  }

  .MuiFormLabel-root {
    color: ${(props) => props.theme.colors.S["600"]};

  .MuiFormLabel-root {
    color: ${(props) => props.theme.colors.S["600"]};

    &.Mui-focused {
      color: ${(props) => props.theme.colors.Mi["400"]};
    }

    &.Mui-error {
      color: ${(props) => props.theme.colors.Ma["400"]};
    }
  }

  .MuiFormHelperText-root {
    font-size: ${rem("10px")};
    color: ${(props) => props.theme.colors.S["600"]};

    &.Mui-error {
      color: ${(props) => props.theme.colors.Ma["400"]};
    }
  }

  & .MuiOutlinedInput-root {
    ${(props) =>
      props.arrowHidden &&
      css`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `}

    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.colors.S["600"]};
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.colors.Mi["400"]};
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.colors.Ma["400"]};
        border-width: 2px;
      }
    }

    &:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline {
        border: 2px ${(props) => props.theme.colors.S["400"]} solid;
      }
    }
  }
`;
export default TextField;
