import { apiPharmaInstance } from 'utils/axios';

// get
export const getAlerts = async () => {
    try {
        const response = await apiPharmaInstance.get('/api/alerts');
        
        return response.data;
    } catch (ex) {
        console.error(ex.message);
    }
};

// post
export const createAlerts = async (alert) => {
    try {
        const response = await apiPharmaInstance.post('/api/alerts', { ...alert });
        return response.data;
    } catch (ex) {
        console.error(ex.message);
    }
};

// patch
export const updateAlerts = async (alert) => {
    try {
        const response = await apiPharmaInstance.patch(`/api/alerts/${alert.id}`, {
            ...alert,
        });
        return response.data;
    } catch (ex) {
        console.error(ex.message);
    }
};

// delete
export const deleteAlerts = async (id: number) => {
    try {
        const response = await apiPharmaInstance.delete(`/api/alerts/${id}`);
        return response.data;
    } catch (ex) {
        console.error(ex.message);
    }
};
