import React from "react";
import { PointOfSale } from "common/types";
import Cookies from "js-cookie";
import { useUser } from "utils/hooks";

export interface PointOfSaleProps {
  pointOfSale: PointOfSale;
  setPointOfSale: (pointOfSale: PointOfSale) => void;
  posDisabled: boolean;
  setPosDisabled: (posDisabled: boolean) => void;
  isLoadingPos: boolean;
  
}

const PosContext = React.createContext<Omit<PointOfSaleProps, "children">>(
  null
);

export function PointOfSaleProvider({ children, ...props }) {
  const { user } = useUser({});
  const [posKey, setPosKey] = React.useState(null);
  const [isLoadingPos, setIsLoadingPos] = React.useState(true);

  const getPointOfSaleFromCookie = (): PointOfSale | null => {
    if (!posKey) {
      return null;
    }
    const posCookie = Cookies.get(posKey);
    if (posCookie) {
      return JSON.parse(posCookie);
    } else if (user?.pointsOfSale.length === 1){
      return user?.pointsOfSale[0]
    }
    return null;
  };

  const [pointOfSale, setPointOfSale] = React.useState<PointOfSale>(
    getPointOfSaleFromCookie()
  );
  const [posDisabled, setPosDisabled] = React.useState<boolean>(false);

  const savePointOfSaleInCookie = (pointOfSale: PointOfSale) => {
    if (!posKey) {
      return null;
    }
    // set localstorage by bug when discarding purchase
    localStorage.setItem("pdvSelected", JSON.stringify(pointOfSale));
    
    Cookies.set(posKey, JSON.stringify(pointOfSale), { expires: 60 });
  };

  React.useEffect(() => {
    savePointOfSaleInCookie(pointOfSale);
    setIsLoadingPos(false);
  }, [pointOfSale]);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    setPosKey(`pos_${user.id}`);
  }, [user]);

  //clean
  React.useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      setPosKey(null);
      setPointOfSale(null);
    }
  }, [user]);

  //update pos
  React.useEffect(() => {
    setPointOfSale(getPointOfSaleFromCookie());
  }, [posKey]);

  return (
    <PosContext.Provider
      value={{
        pointOfSale,
        setPointOfSale,
        posDisabled,
        setPosDisabled,
        isLoadingPos,
        ...props,
      }}
    >
      {children}
    </PosContext.Provider>
  );
}

export function usePointOfSale() {
  const context = React.useContext(PosContext);
  if (context === undefined) {
    throw new Error("usePos must be used within a PosProvider");
  }
  return context;
}
