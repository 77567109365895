// Type: Enum DrugManufacturersId. Old version will be deprecated soon, instead use drugManufacturer.ts
export enum DrugManufacturersId {
    DrogueriasDelSud = 1,
    SuizoArgentina = 2,
    Disval = 3,
    Asoprofarma = 4,
    MonroeAmericana = 5,
    Kellerhoff = 6,
    Acofar = 7,
    VeinteDeJunio = 8,
    Cofarsur = 9,
    DrogueriaSur = 10,
    Nadro = 11,
    Fanasa = 12,
    KellerhoffCordoba = 13,
    KellerhoffRioCuarto = 14,
    KellerhoffVillaMaria = 15,
    KellerhoffSantaFe = 16,
    KellerhoffSanNicolas = 17,
    Cofaloza = 18,
    Marzam = 19,
    Levic = 20,
    CofaralTucuman = 21,
    KellerhoffConcordia = 22,
    KellerhoffConcepcionDelUruguay = 23,
    MedicinasRosario = 24,
    CofaralSalta = 25,
    CofaralChaco = 26,
    CofarmenSanJuan = 27,
    CofarmenMendoza = 28,
    SuizaTucuman = 29,
    CoopFarmaceuticadelLitoralLtda = 30,
    Jufec = 31,
    PacificPharmaGroup = 32,
    Mepiel = 33,
    Farmamigo = 34,
    CofaresCooperativa = 35,
    Hefame = 36,
    Bidafarma = 37,
    AlmacenDeDrogas = 38,
    Sevi = 39,
    Rase = 40,
    Vicma = 41,
    Difarmer = 42,
    CentralDeOfertas = 43,
    FarmaciasABC = 44,
    FarmuFjmInversionesSas = 45,
    DistribucionesAxaSAS = 46,
    FarmaceuticosTenorioSAdeCV = 47,
    Coopidrogas = 48,
    EquilibrioFarmaceutico = 49,
    Farmatizate = 50,
    Roma = 51,
    Unidrogas = 52,
    ANBFarma = 53,
    Dimed = 54,
    Quepharma = 55,
    Bilobeauty = 56,
    Cardinal = 57,
    Egam = 58,
    Profarma = 59,
}

export default DrugManufacturersId;
