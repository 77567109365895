import { makeStyles } from "@material-ui/core/styles";
import { rem } from "polished";
import { alignItems } from "styled-system";
import { zIndex, shadows } from "styles/mixins";

const useStyles = makeStyles(({ palette }) => ({
  appBar: {
    backgroundColor: palette.B.A100,
    zIndex: zIndex.sidebar + 1,
    height: "56px",
    boxShadow: shadows[1],
  },
  toolbar: {
    paddingLeft: "16px",
    height: "56px",
    minHeight: "56px",
    paddingRight: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  toolbarButtonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin:'0px 16px'
  },
  logo: {
    height: rem("24px"),
    cursor: "pointer",
  },
  helpIcon: {
    cursor: "pointer",
  },
  textHelper:{
    cursor: "pointer",
    fontSize:14
  },
  containerAvatarText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginLeft: 5,
  },
  roleText:{
    textTransform:'capitalize'
  },
  chevronIcon:{
    paddingLeft:24,
    display:'flex',
    alignItems:'center'
  },
  separatorLine:{
    transform:'rotate(180deg)',
    height:'16px',
    backgroundColor:'#fff'
  },
  containerHelp:{
    marginRight:24,
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  }
}));

export default useStyles;
