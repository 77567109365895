import useSWR from "swr";
import { ResponseType } from "common/types";

export function useEnabledDrugManufacturers(orderId) {
  const { data, mutate: mutateEnabledDrugManufacturers, error } = useSWR<
    ResponseType<any>
  >(`api/orders/${orderId}/drugManufacturers`);

  return {
    data,
    isLoading: !error && !data,
    error,
    mutateEnabledDrugManufacturers,
  };
}
