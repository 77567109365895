import { useMemo } from "react"
import { IToastProps } from "../interfaces/Toast.interface"
import style from './ToastIcon.module.css'
import { CheckCircle, ErrorRounded, WarningRounded, InfoRounded } from "@material-ui/icons"

type Props = {
    type: IToastProps["type"]
}

const ToastIcon = ({type} : Props) => {

    const IconComponent = useMemo(() => {
        // move this out to a different component
        switch(type) {
            case 'success':
                return <CheckCircle className={`icon ${style[`icon-${type}`]}`} />
            case 'info': 
                return <InfoRounded className={`icon ${style[`icon-${type}`]}`} />
            case 'warning':
                return <WarningRounded className={`icon ${style[`icon-${type}`]}`} />
            case 'error':
                return <ErrorRounded className={`icon ${style[`icon-${type}`]}`} />
            default:
                return null;
        }
    }, [type])


    return (<div className={style['icon-container']}>
        {IconComponent}
    </div>)
}

export default ToastIcon;