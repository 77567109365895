import React, { useContext, createContext } from "react";
import styled, { css, ThemeContext } from "styled-components";
import { rem } from "polished";
import {
  TextField,
  IconButton,
  TextFieldProps,
  InputAdornment,
} from "@material-ui/core";
import { Search, Close, ExpandMore } from "@material-ui/icons";
import { Icon } from "../icon";
import useOnClickOutside from "../click-outside";
import { PosList } from "../point-of-sale";
import useStyles from "./search-box-pdv.styles";
import StorefrontIcon from "@material-ui/icons/Storefront";

type SearchBoxVariant = "standard" | "outlined";

export interface SearchBoxProps {
  variant?: SearchBoxVariant;
  value: string;
  placeholder: string;
  onClear(): void;
  onSearch?(val: string | undefined): void;
  border?: boolean;
  pointOfSaleName?: any;
  show?: any;
  hidden?: any;
  data?: any;
  onSelectSelect?: any;
  currentSelected?: any;
  search?: any;
  setLoadingModal?: any;
  onClose?: any;
  setPointOfSale?: any;
  isEfficientPurchase?: any;
  hide?: boolean;
  pointOfSaleReference?: string;
}

const StyledTextField = styled(({ inputProps, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        classes: { input: "input" },
        ...inputProps,
      }}
    />
  );
})`
  & .input {
    font-size: ${rem("15px")};
    color: ${(props) => props.theme.colors.S["800"]};
    padding: ${rem("8px")} ${rem("8px")};
  }

  & input::-webkit-input-placeholder {
    opacity:1;
    color: ${(props) => props.theme.colors.S["600"]};
  }
}

  & .MuiOutlinedInput-root {
    height: ${rem("40px")};

    & .MuiOutlinedInput-input {
      padding: 0 ${rem("16px")};
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.colors.S["400"]};
    }

    &:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error)
      .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.colors.S["400"]};
    }
  }

  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }

  & .MuiOutlinedInput-adornedStart {
    padding-left: 0;
  }

  & .Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.border ? "transparent !important" : props.theme.colors.Mi["400"]};
    }
  }

  & .MuiInput-underline {
    &::before {
      border-bottom: ${rem("1px")} solid
        ${(props) => props.theme.colors.S["600"]};
    }

    &:hover::before {
      border-bottom-color: ${(props) => props.theme.colors.S["600"]};
    }

    &::after {
      border-bottom-color: ${(props) => props.theme.colors.S["800"]};
    }
  }
`;

const StyledIconButton = styled(IconButton)<{ transparent?: "true" | null }>`
  ${(props) =>
    props?.transparent &&
    css`
      &:hover {
        background-color: transparent;
      }
    `}
  border-radius: initial;
  border-top-right-radius: ${rem("4px")};
  border-bottom-right-radius: ${rem("4px")};
  padding: ${rem("9px")};

  &:hover {
    background-color: ${(props) => props.theme.colors.Mi[100]};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.Mi[200]};
  }

  ${(props) =>
    props?.transparent &&
    css`
      &:hover {
        background-color: transparent;
      }

      &:active {
        background-color: transparent;
      }
    `}
`;

type SearchContextProps = {
  variant: SearchBoxVariant;
  value: string;
  onSearch(val: string | undefined): void;
  onClear(): void;
  hide?: boolean;
};

const SearchBoxContext = createContext<Partial<SearchContextProps>>({
  value: "",
});

function SearchIconButton() {
  const theme = useContext(ThemeContext);
  const { onSearch, value } = useContext(SearchBoxContext);
  const onClickSearch = (val: string | undefined) => (_e: any) => {
    if (onSearch) {
      onSearch(val);
    }
  };

  return (
    <StyledIconButton
      disableRipple
      disabled={value?.length === 0}
      onClick={onClickSearch(value)}
    >
      <Icon Component={Search} color={theme.colors.Mi[400]} />
    </StyledIconButton>
  );
}

function StartAdornment() {
  const { variant } = useContext(SearchBoxContext);
  if (variant === undefined || variant === "standard") {
    return (
      <InputAdornment position="start">
        <Icon Component={Search} />
      </InputAdornment>
    );
  }
  return null;
}

function EndAdornment({ hide = true }) {
  const { variant, onClear, value } = useContext(SearchBoxContext);

  return (
    <>
      {value.length > 0 && hide && (
        <InputAdornment position="end">
          <StyledIconButton
            transparent={"true"}
            disableRipple
            aria-label="clear"
            onClick={onClear}
          >
            <Icon Component={Close} />
          </StyledIconButton>
        </InputAdornment>
      )}
      {variant === "outlined" && hide ? <SearchIconButton /> : null}
    </>
  );
}

export const SearchBox = React.memo(function SearchBox({
  value,
  onClear,
  variant,
  onSearch,
  pointOfSaleName,
  show,
  hidden,
  data,
  onSelectSelect,
  currentSelected,
  search,
  setLoadingModal,
  onClose,
  setPointOfSale,
  isEfficientPurchase,
  hide,
  pointOfSaleReference,
  ...props
}: SearchBoxProps & TextFieldProps) {
  const ref = React.useRef();
  const classes = useStyles({});
  const [display, setDisplay] = React.useState(false);
  useOnClickOutside(ref, () => setDisplay(false));
  const [containerWidth, setContainerWidth] = React.useState("medium");
  const EX3467 = process.env.NEXT_PUBLIC_EX3467 === "true";

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      if (onSearch) {
        onSearch(value);
      }
    }
  };

  React.useEffect(() => {
    let large = data.find((item) => item.name.length >= 24);
    if (large) {
      setContainerWidth("large");
    }
  }, [data]);

  const handleMarginPosTable = () => {
    switch (containerWidth) {
      case "medium":
        return "40px";
      default:
        return 0;
    }
  };

  return (
    <SearchBoxContext.Provider value={{ variant, value, onSearch, onClear }}>
      <div
        ref={ref}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {EX3467 ? (
          <StorefrontIcon className={classes.iconStore} />
        ) : (
          <img
            src="/assets/images/pdv-full.svg"
            width={25}
            height={32}
            color="white"
          />
        )}
        {display ? (
          <>
            <StyledTextField
              {...props}
              variant={variant}
              fullWidth
              value={value}
              inputProps={{
                startAdornment: <StartAdornment />,
                endAdornment: <EndAdornment hide={hide} />,
              }}
              onKeyPress={variant === "outlined" ? handleKeyPress : undefined}
            />
            <div
              className={classes.container_PostList}
              style={{
                marginLeft: handleMarginPosTable(),
              }}
            >
              <PosList
                setDisplay={setDisplay}
                setLoadingModal={setLoadingModal}
                onClose={onClose}
                setPointOfSale={setPointOfSale}
                isEfficientPurchase={isEfficientPurchase}
                data={data}
                onSelect={onSelectSelect}
                currentSelected={currentSelected}
                search={search}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              onClick={() => setDisplay(!display)}
              className={classes.container_PostName}
            >
              <div>
                <p
                  className={
                    pointOfSaleReference
                      ? classes.referencePos
                      : classes.namePos
                  }
                >
                  {" "}
                  {pointOfSaleName}
                </p>
                <p className={classes.namePos}>{pointOfSaleReference}</p>
              </div>
              <Icon Component={ExpandMore} color="white" />
            </div>
          </div>
        )}
      </div>
    </SearchBoxContext.Provider>
  );
});
