import React from 'react';
import { Avatar } from 'rsuite';
import styleOwnerCss from './ExAvatarConnections.module.css';
import { IExAvatarConnections } from './ExAvatarConnections.interface';

const ExAvatarConnections = ({ status, initialsName, style }: IExAvatarConnections) => {
  let styles;

  switch (status) {
    case 'extendeal':
      styles = styleOwnerCss['extendeal-style'];
      break;
    case 'connected':
      styles = styleOwnerCss['connected-style'];
      break;
    case 'error':
      styles = styleOwnerCss['error-style'];
      break;
    case 'disconnected':
      styles = styleOwnerCss['disconnected-style'];
      break;
    default:
      styles = ''; // O un estilo por defecto si es necesario
      break;
  }

  return (
    <Avatar circle className={`${styleOwnerCss['base-avatar-style']} ${styles}`} style={style}>
      {initialsName}
    </Avatar>
  );
};

export default ExAvatarConnections;